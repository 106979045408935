import {AndroidPermissions} from '@ionic-native/android-permissions';
import {Capacitor} from '@capacitor/core';
import {LocationAccuracy} from '@ionic-native/location-accuracy';

export const locationService = {

    checkLocationPermissions(): Promise<boolean> {
        return new Promise((resolve) => {
            if (Capacitor.isNative) {
                AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(res => {
                    if (res.hasPermission) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, err => {
                    alert("Error checking permissions: " + err);
                });
            } else {
                resolve(true);
            }
        });
    },

    requestLocationPermissions(): Promise<boolean> {
        return new Promise((resolve) => {
            LocationAccuracy.canRequest().then((canRequest: boolean) => {
                if (canRequest) {
                    resolve(true);
                } else {
                    AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(res => {
                        if (res.hasPermission) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    }, err => {
                        alert("Error requesting permissions: " + err);
                    });
                }
            });
        });
    },

    turnOnLocations(): Promise<boolean> {
        return new Promise((resolve) => {
            LocationAccuracy.canRequest().then((canRequest: boolean) => {
                if (canRequest) {
                    LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(() => {
                        resolve(true);
                    }, () => {
                        resolve(false);
                    });
                } else {
                    resolve(false);
                }
            })
        })
    }
};
