export class File {
    uid: string;
    fileName: string;
    location: string;
    page: string;
    ready: boolean;
    queryResponse: string;
    queryDate: string;

    constructor(uid: string,
                fileName: string,
                location: string,
                page: string,
                ready: boolean,
                queryResponse: string,
                queryDate: string) {
        this.uid = uid;
        this.fileName = fileName;
        this.location = location;
        this.page = page;
        this.ready = ready;
        this.queryResponse = queryResponse;
        this.queryDate = queryDate;
    }
}
