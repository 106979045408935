import {CountyData} from './CountyData';
import { DashboardItem } from './DashboardItem';

export class DashboardData {
    weeklyCrashes: DashboardItem;
    clearanceTime: DashboardItem;
    freewayCounts: DashboardItem;
    // pti: number;
    countyCrashes: CountyData[];
    dailyCrashCount: number[];
    dailyCrashDay: string[];
    incidentTypeCount: number[];
    incidentTypeName: string[];
    trafficCount: number[];
    lastUpdated: DashboardItem;

    constructor()
    constructor(weeklyCrashes: DashboardItem,
                clearanceTime: DashboardItem,
                freewayCounts: DashboardItem,
                countyCrashes: CountyData[],
                dailyCrashCount: number[],
                dailyCrashDay: string[],
                incidentTypeCount: number[],
                incidentTypeName: string[],
                trafficCount: number[],
                lastUpdated: DashboardItem)
    constructor(weeklyCrashes?: DashboardItem,
                clearanceTime?: DashboardItem,
                freewayCounts?: DashboardItem,
                countyCrashes?: CountyData[],
                dailyCrashCount?: number[],
                dailyCrashDay?: string[],
                incidentTypeCount?: number[],
                incidentTypeName?: string[],
                trafficCount?: number[],
                lastUpdated?: DashboardItem){
        this.weeklyCrashes = weeklyCrashes ? weeklyCrashes : DashboardItem.onError('Error retrieving weekly crashes');
        this.clearanceTime = clearanceTime ? clearanceTime : DashboardItem.onError('Error retrieving clearance Time');
        this.freewayCounts = freewayCounts ? freewayCounts : DashboardItem.onError('Error retrieving freeway counts');
        this.countyCrashes = countyCrashes ? countyCrashes : [{name: 'Error retrieving counties', crashes: 0}];
        this.dailyCrashCount = dailyCrashCount ? dailyCrashCount : [0];
        this.dailyCrashDay = dailyCrashDay ? dailyCrashDay : [''];
        this.incidentTypeCount = incidentTypeCount ? incidentTypeCount : [0];
        this.incidentTypeName = incidentTypeName ? incidentTypeName : [''];
        this.trafficCount = trafficCount ? trafficCount : [0];
        this.lastUpdated = lastUpdated ? lastUpdated : DashboardItem.onError('Error retrieving last upated date');
    }
}
