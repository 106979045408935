import * as React from 'react';
import {useState} from 'react';

import {
    IonButton,
    IonCard,
    IonCardContent, IonCardHeader,
    IonContent, IonFooter,
    IonHeader, IonIcon, IonImg, IonInput,
    IonItem, IonLabel, IonMenuButton,
    IonPage, IonRouterLink,
    IonToolbar, useIonViewWillLeave
} from '@ionic/react';
import { fingerPrintOutline,
    fingerPrintSharp,
    personAddOutline,
    personAddSharp,
    bodyOutline,
    bodySharp,
    mailOutline,
    mailSharp,
    lockClosedOutline,
    lockClosedSharp,
    businessOutline,
    businessSharp } from 'ionicons/icons';

import './Registration.css';
import {createUser, registerUser, watchUser} from '../../firebaseConfig';
import {User} from '../../interfaces/User';
import {useHistory} from 'react-router';
import RIDSIR from '../../assets/icon/favicon.png';

const Registration: React.FC = () => {
    /* Set status of mobile based on window size */
    const [mobile, setMobile] = useState(window.innerWidth < 768);
    window.addEventListener('resize', resize);
    function resize(event: UIEvent) {
        const window = event.currentTarget as Window;
        setMobile(window.innerWidth < 768);
    }

    /* Registration stuff */
    const [name, setName] = useState('');
    const [agency, setAgency] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    /* Set up routing */
    const history = useHistory();

    function handleKeyPress(event: { key: string; }) {
        if(event.key === 'Enter'){
            register();
        }
    }

    /* Used for rerouting after email verification */
    const redirect = window.location.href.replace('/Registration', '/Dashboard');

    function register() {
        if(name === '') {
            return setErrorMessage('Please enter a name');
        }
        if(agency === '') {
            return setErrorMessage('Please enter an agency');
        }
        if(password !== checkPassword) {
            return setErrorMessage('Passwords do not match');
        }
        if(!username.match("^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))" +
            "@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$")) {
            return setErrorMessage('Please enter a valid email address');
        }
        if(!password.match("^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\\d\\W])|(?=.*\\W)(?=.*\\d))|(?=.*\\W)(?=.*[A-Z])(?=.*\\d)).{8,}$")) {
            return setErrorMessage('Password must contain at least one upper case character, one lowercase character, one number or special character, and be longer than eight characters');
        }
        registerUser(username, password).then((res) => {
            if (res.user != null) {
                const newUser: User = new User(
                    res.user.uid,
                    false,
                    false,
                    false,
                    [],
                    res.user.email,
                    name,
                    agency,
                    null,
                    null,
                    true,
                    []);
                createUser(newUser).then(() => {
                    watchUser().onAuthStateChanged((user) => {
                        if (user) {
                            user.sendEmailVerification({
                                url: redirect,
                            }).then(() => {
                                history.push('/EmailVerification');
                            }).catch((err) => {
                                setErrorMessage(err.message);
                            })
                        }
                        
                    });
                }).catch((err) => {
                    setErrorMessage(err.message);
                });
            }
        }).catch((err) => {
            setErrorMessage(err.message);
        });
    }

    useIonViewWillLeave(() => {
        setName('');
        setAgency('');
        setUsername('');
        setPassword('');
        setCheckPassword('');
        setErrorMessage('');
    });

    return (
        <IonPage>
            <IonContent color="dark" className="content">

                <IonHeader className="ion-no-border">
                    <IonToolbar color="dark" className="header">
                        <IonItem color="dark" slot="start" lines="none"><IonImg className="ridsi-icon" src={RIDSIR} />
                            Registration Page
                        </IonItem>
                        {!mobile && <IonItem button={true} color="dark" slot="end" lines="none" routerLink="/Login">
                            <IonIcon ios={fingerPrintOutline} md={fingerPrintSharp} />
                            &nbsp;
                            Login
                        </IonItem>}
                        {!mobile && <IonItem button={true} color="dark" slot="end" lines="none" routerLink="/Registration">
                            <IonIcon ios={personAddOutline} md={personAddSharp} />
                            &nbsp;
                            Register
                        </IonItem>}
                        {mobile && <IonItem color="dark" slot="end" lines="none">
                            <IonMenuButton color="light" menu="login" />
                        </IonItem>}
                    </IonToolbar>
                </IonHeader>

                <IonToolbar className="login-container" color="dark">


                    {/* Registration Page */}
                    <div className="card-container-registration">
                        <IonCard className="card-registration">
                            <IonCardHeader>Register</IonCardHeader>
                            <IonCardContent>
                                <div className="form">
                                    <IonItem className="formItem">
                                        <IonLabel position="floating">First Name</IonLabel>
                                        {/* Object needs to be of type any here, since the alternative is a custom made Ionic type*/}
                                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                        <IonInput value={name} onIonChange={(e: any) => setName(e.target.value)} onKeyPress={handleKeyPress}/>
                                        <IonIcon ios={bodyOutline} md={bodySharp} slot="end" />
                                    </IonItem>
                                    <IonItem className="formItem">
                                        <IonLabel position="floating">Agency</IonLabel>
                                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                        <IonInput value={agency} onIonChange={(e: any) => setAgency(e.target.value)} onKeyPress={handleKeyPress}/>
                                        <IonIcon ios={businessOutline} md={businessSharp} slot="end" />
                                    </IonItem>
                                    <IonItem className="formItem">
                                        <IonLabel position="floating">Email</IonLabel>
                                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                        <IonInput value={username} onIonChange={(e: any) => setUsername(e.target.value)} onKeyPress={handleKeyPress}/>
                                        <IonIcon ios={mailOutline} md={mailSharp} slot="end" />
                                    </IonItem>
                                    <IonItem className="formItem">
                                        <IonLabel position="floating">Password</IonLabel>
                                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                        <IonInput value={password} type="password" onIonChange={(e: any) => setPassword(e.target.value)} onKeyPress={handleKeyPress}/>
                                        <IonIcon ios={lockClosedOutline} md={lockClosedSharp} slot="end" />
                                    </IonItem>
                                    <IonItem className="formItem">
                                        <IonLabel position="floating">Confirm Password</IonLabel>
                                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                        <IonInput value={checkPassword} type="password" onIonChange={(e: any) => setCheckPassword(e.target.value)} onKeyPress={handleKeyPress}/>
                                        <IonIcon ios={lockClosedOutline} md={lockClosedSharp} slot="end" />
                                    </IonItem>
                                    <br />
                                    <div className="links">
                                        <IonRouterLink className="link" routerLink="/Login"><IonLabel>Already have an account?</IonLabel></IonRouterLink>
                                    </div>
                                    <br />
                                    <div className="error-message">
                                        { errorMessage }
                                    </div>
                                    <br />
                                    <IonItem button={false} className="ion-text-center" lines="none">
                                        <IonLabel>
                                            <IonButton color="pink" onClick={register}>Get Started</IonButton>
                                        </IonLabel>
                                    </IonItem>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </div>



                </IonToolbar>
            </IonContent>



            <IonFooter className="ion-no-border">
                <IonToolbar color="dark">
                    <IonLabel slot="end">@TITAN 2021 All rights reserved.</IonLabel>
                </IonToolbar>
            </IonFooter>



        </IonPage>
    );
};

export default Registration;
