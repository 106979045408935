import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect, RouteProps} from "react-router-dom";
import {getCurrentUser, getFireUser} from '../../firebaseConfig';

interface PrivateRouteProps extends RouteProps {
    children: React.ReactNode
}

const RouteGuard: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
    return (
        <Route {...rest} >
            {getFireUser() && getCurrentUser() ? (
                ((getFireUser()?.emailVerified || getFireUser()?.providerData[0]?.providerId !== 'password')) && getCurrentUser()?.verified ? (
                    children
                ) : (
                    <Redirect to='/EmailVerification' />
                )
            ) : (
                <Redirect to='/login' />
            )}
        </Route>
    );
};

RouteGuard.propTypes = {
    children: PropTypes.node.isRequired
};

export default RouteGuard;
