import React, {useState, useEffect} from 'react';
import './Maps.css';
import { Missouri } from './Missouri';
import { MapData } from '../../interfaces/MapData';
import {
    IonIcon,
    IonButton,
    IonImg,
} from "@ionic/react";
import {help, pin, pinSharp, snow, videocam} from "ionicons/icons";
import {
    clearDay,
    cloudy,
    mostlyClearDay,
    partlyCloudyDay,
    mostlyCloudy,
    fog,
    fogLight,
    poweredByTomorrow,
    lightWind,
    wind,
    heavyWind,
    drizzle,
    rain,
    rainLight,
    rainHeavy,
    flurries,
    snowLight,
    snowHeavy,
    freezingDrizzle,
    freezingRain,
    freezingRainLight,
    freezingRainHeavy,
    icePellets,
    icePelletsHeavy,
    icePelletsLight,
    tstorm,
} from '../../assets/weather-icons/availableWeatherIcons';

import ReactMapGl, {
    Source,
    Layer,
    NavigationControl,
    FlyToInterpolator,
    LayerProps,
    Marker
} from 'react-map-gl';
import {Marker as MarkerData} from '../../interfaces/Marker';
import mapboxgl from 'mapbox-gl';
import {InteractiveMapProps} from 'react-map-gl/dist/esm/components/interactive-map';

import './Maps.css';
import { locationService } from '../../services/nativeService';
import {Capacitor} from '@capacitor/core';
import {Geolocation} from '@capacitor/geolocation';
import {getUser} from '../../firebaseConfig';
import firebaseService from '../../services/firebaseService';
import {User} from '../../interfaces/User';
import {WeatherEvent} from '../../interfaces/WeatherEvent';
import {GeoJSON} from 'geojson';
import {WazeIncident} from '../../interfaces/WazeIncident';
import {WazeJam} from '../../interfaces/WazeJam';
import { TranscoreIncident } from '../../interfaces/TranscoreIncident';
import {
    accident,
    animal,
    block,
    closed,
    exitClosed,
    hazard,
    jam,
    noShoulder,
    other,
    pothole,
    roadwork,
    stalled
} from '../../assets/traffic-icons/availableTrafficIcons';
import {Device} from '@capacitor/device';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const geojson: GeoJSON.Feature = Missouri;

const trafficLayer: LayerProps = {
    id: 'traffic-layer',
    source: 'traffic',
    'source-layer': 'traffic',
    type: 'line',
    minzoom: 5,
    paint: {
        'line-width': 1.5,
        'line-color': [
            "case",
                [
                    "==",
                    "low",
                    [
                        "get",
                        "congestion"
                    ]
                ],
                "#4caf50",
                [
                    "==",
                    "moderate",
                    [
                        "get",
                        "congestion"
                    ]
                ],
                "#ff3409",
                [
                    "==",
                    "heavy",
                    [
                        "get",
                        "congestion"
                    ]
                ],
                "#fc930a",
                [
                    "==",
                    "severe",
                    [
                        "get",
                        "congestion"
                    ]
                ],
                "#e73e3a",
                "#4caf50"
        ]
    }
};

const stateLayer: LayerProps = {
    id: 'fill',
    type: 'fill',
    source: 'missouri',
    paint: {
        'fill-color': '#3880ff',
        'fill-opacity': 0.2
    }
};

const Map: React.FC<MapData> = (props: MapData) => {
    const [pins, setPins] = useState<JSX.Element[]>([]);
    const [latitude, setLatitude] = useState<number>(38.631);
    const [longitude, setLongitude] = useState<number>(-90.345);
    const [weather, setWeather] = useState<WeatherEvent[]>([]);
    const [transcoreIncidents, setTranscoreIncidents] = useState<TranscoreIncident[]>([]);
    const [wazeIncidents, setWazeIncidents] = useState<WazeIncident[]>([]);
    // const [wazeJams, setWazeJams] = useState<WazeJam[]>([]);
    const [wazeJamGeo, setWazeJamGeo] = useState<GeoJSON.FeatureCollection>();
    const accessToken = process.env.REACT_APP_MAPBOX_API_KEY;
    const mapState: InteractiveMapProps = {
        latitude: latitude,
        longitude: longitude,
        zoom: 8,
        minZoom: 5,
        transitionDuration: 0,
        transitionInterpolator: null
    };

    const [viewport, setViewport] = React.useState(mapState);

    const navControlStyle = {
        right: 10,
        top: 10
    };

    const recenter = () => {
        mapState.transitionDuration = 1000;
        mapState.transitionInterpolator = new FlyToInterpolator();
        setViewport({...mapState, latitude: latitude, longitude: longitude});
    };

    const handleViewportChange = (viewport: InteractiveMapProps) => {
        if (viewport.latitude && viewport.longitude && viewport.zoom) {
            if (viewport.longitude > -88.5608) {
                viewport.longitude = -88.5608;
            }
            if (viewport.longitude < -96.2512) {
                viewport.longitude = -96.2512;
            }
            if (viewport.latitude > 40.8969) {
                viewport.latitude = 40.8969;
            }
            if (viewport.latitude < 35.5143) {
                viewport.latitude = 35.5143;
            }
            if (viewport.zoom < 5) {
                viewport.zoom = 5;
            }
        }
        setViewport(viewport);
    };

    const cameraMarkers = React.useMemo(() => props.cameras?.map(
        camera => (
            <Marker latitude={camera.latitude} longitude={camera.longitude} key={camera.id}>
                <IonIcon className="map-icon" color='primary' md={videocam} onClick={() => props.setId ? props.setId(camera.id) : {}} />
            </Marker>
        ),
      ), [props]);

    const weatherMarkers = React.useMemo(() => weather.map(weatherItem => {
        let iconType: string;
        let weatherType: string;
        switch(weatherItem.weatherCode) {
            case 0:
                iconType = help;
                weatherType = 'invalid';
                break;
            case 1000:
                iconType = clearDay;
                weatherType = 'Clear Day';
                break;
            case 1001:
                iconType = cloudy;
                weatherType = 'Cloudy';
                break;
            case 1100:
                iconType = mostlyClearDay;
                weatherType = "Mostly Clear Day";
                break;
            case 1101:
                iconType = partlyCloudyDay;
                weatherType = "Partly Cloudy Day";
                break;
            case 1102:
                iconType = mostlyCloudy;
                weatherType = "Mostly Cloudy";
                break;
            case 2000:
                iconType = fog;
                weatherType = "Foggy";
                break;
            case 2100:
                iconType = fogLight;
                weatherType = "Light Fog";
                break;
            case 3000:
                iconType = lightWind;
                weatherType = "Light Wind";
                break;
            case 3001:
                iconType = wind;
                weatherType = "Windy";
                break;
            case 3002:
                iconType = heavyWind;
                weatherType = "Heavy Wind";
                break;
            case 4000:
                iconType = drizzle;
                weatherType = "Drizzle";
                break;
            case 4001:
                iconType = rain;
                weatherType = "Rainy";
                break;
            case 4200:
                iconType = rainLight;
                weatherType = "Light Rain";
                break;
            case 4201:
                iconType = rainHeavy;
                weatherType = "Heavy Rain";
                break;
            case 5000:
                iconType = snow;
                weatherType = "Snowy";
                break;
            case 5001:
                iconType = flurries;
                weatherType = "Snow Flurries";
                break;
            case 5100:
                iconType = snowLight;
                weatherType = "Light Snow";
                break;
            case 5101:
                iconType = snowHeavy;
                weatherType = "Heavy Snow";
                break;
            case 6000:
                iconType = freezingDrizzle;
                weatherType = "Freezing Drizzle";
                break;
            case 6001:
                iconType = freezingRain;
                weatherType = "Freezing Rain";
                break;
            case 6200:
                iconType = freezingRainLight;
                weatherType = "Light Freezing Rain";
                break;
            case 6201:
                iconType = freezingRainHeavy;
                weatherType = "Heavy Freezing Rain";
                break;
            case 7000:
                iconType = icePellets;
                weatherType = "Hail";
                break;
            case 7101:
                iconType = icePelletsHeavy;
                weatherType = "Heavy Hail";
                break;
            case 7102:
                iconType = icePelletsLight;
                weatherType = "Light Hail";
                break;
            case 8000:
                iconType = tstorm;
                weatherType = "Thunderstorm";
                break;
            default:
                iconType = help;
                break;
        }
            return (
                <Marker latitude={weatherItem.latitude} longitude={weatherItem.longitude} key={weatherItem.id}>
                    <IonIcon onClick={() => {
                        if (props.markerSelection) {
                            props.markerSelection(new MarkerData(
                                'Weather Event',
                                weatherItem.county,
                                weatherItem.latitude,
                                weatherItem.longitude,
                                new Date(weatherItem.timestamp),
                                weatherType,
                                '',
                                weatherItem.temperature,
                                weatherItem.windGust,
                                weatherItem.precipitationIntensity,
                                weatherItem.snowIntensity,
                                weatherItem.freezingRangeIntensity,
                                weatherItem.sleetIntensity
                                ));
                        }
                    }
                    } className="marker-icon" src={iconType} />
                </Marker>
            );
        },
    ), [weather, props]);

    const transcoreMarkers = React.useMemo(() => transcoreIncidents.map(incident => {
        let iconType;
        let iconColor;

        switch (incident.event_class) {
            case "ROADWORK":
                iconType = roadwork;
                iconColor = "yellow";
                break;
            case "OTHER":
                iconType = other;
                iconColor = "black";
                break;
            case "EXIT CLOSED":
                iconType = exitClosed;
                iconColor = "danger";
                break;
            case "STALLED VEHICLE":
                iconType = stalled;
                iconColor = "warning";
                break;
            default:
                iconType = pin;
                break;
        }
        return <Marker
            key={incident.uuid}
            longitude={incident.longitude}
            latitude={incident.latitude}>
            <IonIcon onClick={() => {
                if (props.markerSelection) {
                    props.markerSelection(new MarkerData(
                        incident.event_class,
                        incident.county,
                        incident.latitude,
                        incident.longitude,
                        new Date(incident.pub_millis),
                        incident.event_description,
                        incident.on_street_name
                    ));
                }
            }
            } className="marker-icon" color={iconColor} src={iconType} />
        </Marker>
    }), [transcoreIncidents, props]);

    const wazeIncidentMarkers = React.useMemo(() => wazeIncidents.map(incident => {
        let iconType;
        let iconColor;
        if (incident.event_class === '') {
            incident.event_class = incident.type;
        }
        switch (incident.event_class) {
            case "ROAD_CLOSED":
                iconType = closed;
                iconColor = "red";
                break;
            case "ROADWORK":
                iconType = roadwork;
                iconColor = "yellow";
                break;
            case "":
                iconType = pin;
                break;
            case "STALLED VEHICLE_ON_ROAD":
                iconType = stalled;
                iconColor = "warning";
                break;
            case "STALLED VEHICLE_SHOULDER":
                iconType = stalled;
                iconColor = "warning";
                break;
            case "JAM_HEAVY_TRAFFIC":
                iconType = jam;
                iconColor = "red";
                break;
            case "POT_HOLE":
                iconType = pothole;
                iconColor = "orange";
                break;
            case "ROAD_OBJECT":
                iconType = block;
                iconColor = "danger";
                break;
            case "JAM_MODERATE_TRAFFIC":
                iconType = jam;
                iconColor = "red";
                break;
            case "ACCIDENT_MINOR":
                iconType = accident;
                iconColor = "warning";
                break;
            case "ROAD_KILL_ROAD":
                iconType = animal;
                iconColor = "purple";
                break;
            case "JAM_STAND_STILL_TRAFFIC":
                iconType = jam;
                iconColor = "red";
                break;
            case "ACCIDENT_MAJOR":
                iconType = accident;
                iconColor = "danger";
                break;
            case "ACCIDENT":
                iconType = accident;
                iconColor = "orange";
                break;
            case "HAZARD_ON_SHOULDER":
                iconType = hazard;
                iconColor = "danger";
                break;
            case "SHOULDER_MISSING_SIGN":
                iconType = noShoulder;
                iconColor = "warning";
                break;
            default:
                iconType = pin;
                break;
        }
        return <Marker
            key={incident.uuid}
            longitude={incident.longitude}
            latitude={incident.latitude}>
            <IonIcon onClick={() => {
                if (props.markerSelection) {
                    props.markerSelection(new MarkerData(
                        incident.event_class,
                        incident.county,
                        incident.latitude,
                        incident.longitude,
                        new Date(incident.pub_millis),
                        incident.report_description,
                        incident.street
                    ));
                }
            }
            } className="marker-icon" color={iconColor} src={iconType}/>
        </Marker>
    }), [wazeIncidents, props]);

    function updateLocation() {
        Device.getInfo().then(device => {
            if (device.operatingSystem === 'ios' || device.operatingSystem === 'android') {
                Geolocation.checkPermissions().then(permissions => {
                    if (permissions.location === 'granted') {
                        Geolocation.getCurrentPosition().then(position => {
                            getUser()?.then(user => {
                                const data = user.data();
                                if (data) {
                                    firebaseService.updateUser(new User(
                                        data.uid,
                                        data.admin,
                                        data.applied,
                                        data.verified,
                                        data.deviceIDs,
                                        data.email,
                                        data.displayName,
                                        data.agency,
                                        position.coords.latitude,
                                        position.coords.longitude,
                                        true,
                                        data.subscriptions
                                    )).catch(err => {
                                        console.error('Failed to update user: ' + err);
                                    });
                                }
                            });
                        });
                    }
                });
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        getUser()?.then(user => {
                            const data = user.data();
                            if (data && data.allowTracking !== false) {
                                firebaseService.updateUser(new User(
                                    data.uid,
                                    data.admin,
                                    data.applied,
                                    data.verified,
                                    data.deviceIDs,
                                    data.email,
                                    data.displayName,
                                    data.agency,
                                    position.coords.latitude,
                                    position.coords.longitude,
                                    true,
                                    data.subscriptions
                                )).catch(err => {
                                    console.error('Failed to update user: ' + err);
                                });
                            }
                        });
                    });
                }
            }
        })
    }

    useEffect(() => {
        getUser()?.then(user => {
            const userData = user.data();
            if (userData && userData.allowTracking) {
                if (userData.latitude && userData.longitude) {
                    setPins([
                        <Marker latitude={userData.latitude} longitude={userData.longitude} key={1}>
                            <IonIcon size="large" color='pink' md={pinSharp} />
                        </Marker>
                    ]);
                    setLatitude(userData.latitude);
                    setLongitude(userData.longitude);
                    setViewport(v => ({...v, latitude: userData.latitude, longitude: userData.longitude}));
                }
                locationService.checkLocationPermissions().then((hasPermission) => {
                    if (hasPermission) {
                        if (Capacitor.isNative) {
                            locationService.turnOnLocations().then((locationAvailable) => {
                                if (locationAvailable) {
                                    updateLocation();
                                }
                            });
                        } else {
                            updateLocation();
                        }
                    }
                });
            } else {
                setPins([]);
            }
        });
        firebaseService.watchWazeIncidentsData().onSnapshot((wazeData) => {
            const data = wazeData.docs;
            const foundIncidents: WazeIncident[] = [];
            data.forEach(doc => {
                const docData = doc.data();
                if (docData != null) {
                    foundIncidents.push(docData as WazeIncident);
                }
            });
            setWazeIncidents(foundIncidents);
        });
        firebaseService.watchWazeJamsData().onSnapshot((wazeData) => {
            const data = wazeData.docs;
            // const foundJams: WazeJam[] = [];
            const wazeFeatures: GeoJSON.Feature[] = [];
            data.forEach(doc => {
                const docData = doc.data() as WazeJam;
                if (docData != null) {
                    let speedColor;
                    const speed = docData.speed;

                    if (speed < 1) {
                        speedColor = 'red';
                    } else if (speed <= 5) {
                        speedColor = 'orangered';
                    } else if (speed <= 10) {
                        speedColor = 'gold';
                    } else if (speed <= 15) {
                        speedColor = 'greenyellow';
                    } else if (speed <= 20) {
                        speedColor = 'palegreen';
                    } else if (speed <= 25) {
                        speedColor = 'mediumseagreen';
                    } else {
                        speedColor = 'forestgreen';
                    }
                    wazeFeatures.push({
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: [
                                [docData.start_long, docData.start_lat],
                                [docData.end_long, docData.end_lat]
                            ]
                        },
                        properties: {
                            color: speedColor
                        }
                    });
                }
            });
            setWazeJamGeo({
                type: 'FeatureCollection',
                features: wazeFeatures
            });
        });
        firebaseService.watchTranscoreData().onSnapshot((transcoreData) => {
            const data = transcoreData.docs;
            const foundIncidents: TranscoreIncident[] = [];
            data.forEach(doc => {
                const docData = doc.data() as TranscoreIncident;
                if (docData != null) {
                    foundIncidents.push(docData);
                }
            });
            setTranscoreIncidents(foundIncidents);
        });
        firebaseService.watchWeatherData().onSnapshot((weatherData) => {
            const data = weatherData.docs;
            const foundWeather: WeatherEvent[] = [];
            data.forEach((doc) => {
                const docData = doc.data();
                if (docData != null) {
                    foundWeather.push(new WeatherEvent(
                        doc.id,
                        docData.county,
                        docData.pub_millis,
                        docData.weatherCode,
                        docData.latitude,
                        docData.longitude,
                        docData.temperature,
                        docData.rainIntensity,
                        docData.windGust,
                        docData.snowIntensity,
                        docData.freezingRainIntensity,
                        docData.sleetIntensity,
                
                        ));
                }
            });
            setWeather(foundWeather);
        });
    }, []);

    return(
        <div>
            <ReactMapGl
                {...viewport}
                onViewportChange={handleViewportChange}
                mapStyle="mapbox://styles/mapbox/dark-v10"
                mapboxApiAccessToken={accessToken}
                width="100%"
                height={props.height}
                onLoad={() => props.setShowLoading ? props.setShowLoading(false) : {}}
            >
                {props.cctv && cameraMarkers}
                {props.location && pins}
                {props.weather && weatherMarkers}
                {props.transcore && transcoreMarkers}
                {props.wazeIncidents && wazeIncidentMarkers}
                {props.traffic &&
                    <Source id='traffic' type='vector' url='mapbox://mapbox.mapbox-traffic-v1' minzoom={5}>
                        <Layer {...trafficLayer} />
                    </Source>}
                {props.stateOutline &&
                    <Source id='missouri' type='geojson' data={geojson}>
                        <Layer {...stateLayer} />
                    </Source>}
                {props.wazeJams &&
                    <Source id='wazeJam' type='geojson' data={wazeJamGeo}>
                        <Layer
                            id="wazeJamLayer"
                            type="line"
                            source="wazeJam"
                            paint={
                                {
                                    'line-color': [
                                        'match',
                                        ['get', 'color'],
                                        'red',
                                        'red',
                                        'orangered',
                                        'orangered',
                                        'gold',
                                        'gold',
                                        'greenyellow',
                                        'greenyellow',
                                        'palegreen',
                                        'palegreen',
                                        'mediumseagreen',
                                        'mediumseagreen',
                                        'forestgreen',
                                        'forestgreen',
                                        'red'
                                    ]
                                }
                            }
                        />
                    </Source>}
                <NavigationControl style={navControlStyle} />
                <div className="recenter-container">
                    <IonButton className="recenter" color="light" onClick={recenter}>Recenter</IonButton>
                    {props.weather && <IonImg className="tomorrow-logo" src={poweredByTomorrow} />}
                </div>
            </ReactMapGl>
        </div>
    );
};

export default Map;
