import * as React from 'react';
import './AboutSegments.css';

import {
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonImg,
    IonItemDivider,
    IonLabel,
    IonRow,
    IonText
} from "@ionic/react";

import { AgencyData } from "../../interfaces/AboutData";
import { agencyItems } from "../../assets/agency-logos/agencyItems";

const AgenciesSegment: React.FC = () => {
    const agencies: Array<AgencyData> = agencyItems;

    return (
        <IonCard className="about-segment-card">
            <IonItemDivider className="about-header-card-title" color="light">
                <IonLabel>Agencies and Affiliations</IonLabel>
            </IonItemDivider>
            <IonCardContent className="ion-text-left">
                <div className="about-card-subtitle-container">
                    <IonLabel className="about-card-subtitle">Organizations Involved With the RIDSI Project: </IonLabel>
                </div>
                <IonGrid>
                    { agencies.map((agency, index) => {
                        return(
                            <IonRow key={index}>
                                <IonCol className="agency-logos agency-column" size-lg="2" size-xs="10">
                                    <IonImg src={agency.pictureFile} alt="Logo Image"/>
                                </IonCol>
                                <IonCol className="agency-column">
                                    <IonText>{agency.description} <a href={agency.agencyLink}>More info.</a></IonText>
                                </IonCol>
                            </IonRow>
                        );
                    }) }
                </IonGrid>
            </IonCardContent>
        </IonCard>
    );
};

export default AgenciesSegment;
