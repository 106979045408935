import * as React from 'react';
import DataTable from 'react-data-table-component';
import {useEffect, useState} from 'react';
import firebaseService from '../../services/firebaseService';
import {User} from '../../interfaces/User';
import {RouteProps} from 'react-router';

interface FormattedUser extends User {
    privilege: string;
    locationStatus: string;
    verificationStatus: string;
}

interface TableProps extends RouteProps {
    userSelect: (selected: FormattedUser) => void;
}

const AdminTable: React.FC<TableProps> = (props: TableProps) => {
    const [data, setData] = useState<FormattedUser[]>([]);
    const columns = [
        {
            name: 'Name',
            selector: (row: FormattedUser) => row.displayName,
            sortable: true
        },
        {
            name: 'Email',
            selector: (row: FormattedUser) => row.email,
            sortable: true
        },
        {
            name: 'Agency',
            selector: (row: FormattedUser) => row.agency,
            sortable: true
        },
        {
            name: 'Privilege',
            selector: (row: FormattedUser) => row.privilege,
            sortable: true
        },
        {
            name: 'Location Enabled',
            selector: (row: FormattedUser) => row.locationStatus,
            sortable: false
        },
        {
            name: 'Verified',
            selector: (row: FormattedUser) => row.verificationStatus,
            sortable: true
        }
    ];

    useEffect(() => {
        firebaseService.getUsers().get().then(collection => {
            const formattedUsers: FormattedUser[] = [];
            collection.docs.forEach(doc => {
                const data: User = doc.data() as User;
                const formattedUser: FormattedUser = {
                    uid: data.uid,
                    admin: data.admin,
                    applied: data.applied,
                    verified: data.verified,
                    deviceIDs: data.deviceIDs,
                    verificationStatus: String(data.verified),
                    displayName: data.displayName,
                    email: data.email,
                    agency: data.agency,
                    privilege: 'General User',
                    locationStatus: String(data.allowTracking)
                };
                if (data.admin) {
                    formattedUser.privilege = 'Admin';
                }
                formattedUsers.push(formattedUser);
            });
            setData(formattedUsers);
        })
    }, []);

    return (
        <DataTable
            title="RIDSI Users"
            columns={columns}
            data={data}
            pagination={true}
            selectableRows={true}
            selectableRowsSingle={true}
            onSelectedRowsChange={(selected) => props.userSelect(selected.selectedRows[0])}
        />
    );
};

export default AdminTable;
