import * as React from 'react';
import {IonApp, IonRouterOutlet} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import Menu from './components/Menus/Menu';
import LoginMenu from './components/Menus/LoginMenu';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import Registration from './pages/Registration/Registration';
import RouteGuard from './components/Guards/RouteGuard';
import Loading from './components/Loading/Loading';
import Data from './pages/Data/Data';
import Profile from './pages/Profile/Profile';
import Live from './pages/Live/Live';
import RidsiAbout from "./pages/RidsiAbout/RidsiAbout";
import Tutorials from './pages/Tutorials/Tutorials';
import AppCenter from './pages/AppCenter/AppCenter';
import Notifications from './pages/Notifications/Notifications';
import History from './pages/History/History';
import Visualization from './pages/Visualization/Visualization';
import EmailVerification from './pages/Registration/EmailVerification';
import ForgotPassword from './pages/Login/ForgotPassword';

/* Ionic CSS required to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './theme/variables.css';
import {useEffect, useState} from 'react';
import {watchUser} from './firebaseConfig';
import firebaseService from './services/firebaseService';
import {User} from './interfaces/User';
import {PushNotifications, PushNotificationSchema} from '@capacitor/push-notifications';
import {Device} from '@capacitor/device';
import firebase from 'firebase/app';
import 'firebase/messaging';
import Upload from './pages/Upload/Upload';
import MorePages from './pages/AppCenter/More/MorePages';
import {Link} from './interfaces/Link';
import iconService from './services/iconService';
import Support from './pages/Support/Support';

const App: React.FC = () => {
    const [auth, setAuth] = useState(-1);
    const [links, setLinks] = useState<Link[]>([]);

    let pageDefault: JSX.Element = (<div>Error Loading Auth</div>);

    const history = useHistory();
    watchUser().onAuthStateChanged((fireUser) => {
        if (fireUser !== null) {
            firebaseService.getUserByID(fireUser.uid).then(doc => {
                const user = doc.data() as User;
                if (fireUser.emailVerified && user.verified) {
                    setAuth(2);
                } else if (fireUser.providerData[0]?.providerId !== 'password' && user.verified) {
                    setAuth(2);
                }else {
                    setAuth(1);
                    if (history) {
                        history.push('/');
                    }
                }
            });
        } else {
            setAuth(0);
            if (history) {
                history.push('/');
            }
        }
    });

    if (auth === 0) {
        pageDefault = (
            <Route path="/" exact={true}>
                <Redirect to="/Login" />
            </Route>
        );
    }
    if (auth === 1) {
        pageDefault = (
            <Route path="/" exact={true}>
                <Redirect to="/EmailVerification" />
            </Route>
        );
    }

    if (auth === 2) {
        pageDefault = (
            <Route path="/" exact={true}>
                <Redirect to="/Dashboard" />
            </Route>
        );
    }

    useEffect(() => {
        Device.getInfo().then(device => {
            if (device.operatingSystem !== 'ios' && device.operatingSystem !== 'android') {
                const messaging = firebase.messaging();
                messaging.onMessage((payload) => {
                    alert(payload);
                });
            } else {
                PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
                    alert(JSON.stringify(notification));
                });
            }
            firebaseService.getLinks().then(found => {
                const foundLinks: Link[] = [];
                found.docs.forEach(doc => {
                    const data = doc.data() as Link;
                    data.name = doc.id;
                    data.icon = iconService.getIcon(data.icon, device.platform);
                    foundLinks.push(data);
                });
                setLinks(foundLinks);
            });
        });
    }, []);

    if (auth === -1) {
        return (
            <Loading />
        );
    } else {
        return (
            <IonApp>
                <IonReactRouter>

                    <Menu />
                    <LoginMenu />
                    <IonRouterOutlet id="main">
                        <Switch>
                            {pageDefault}

                            <Route path="/Login" exact={true}>
                                <Login />
                            </Route>

                            <Route path="/Registration" exact={true}>
                                <Registration />
                            </Route>
                            
                            <Route path="/ForgotPassword" exact={true}>
                                <ForgotPassword />
                            </Route>

                            <Route path="/EmailVerification">
                                <EmailVerification />
                            </Route>

                            <RouteGuard path="/Profile">
                                <Profile />
                            </RouteGuard>

                            <RouteGuard path="/Notifications">
                                <Notifications />
                            </RouteGuard>

                            <RouteGuard path="/Dashboard" exact={true}>
                                <Dashboard />
                            </RouteGuard>

                            <RouteGuard path="/Data/:form">
                                <Data />
                            </RouteGuard>

                            <RouteGuard path="/Upload">
                                <Upload />
                            </RouteGuard>

                            <RouteGuard path="/History">
                                <History />
                            </RouteGuard>

                            {links.map((link) => {
                                return(
                                    <RouteGuard path={"/AppCenter/" + link.name} key={link.name}>
                                        <AppCenter title={link.name} />
                                    </RouteGuard>
                                );
                            })}

                            <RouteGuard path="/MorePages">
                                <MorePages />
                            </RouteGuard>

                            <RouteGuard path="/Live">
                                <Live />
                            </RouteGuard>

                            <RouteGuard path="/Visualization">
                                <Visualization />
                            </RouteGuard>

                            <RouteGuard path="/Tutorials">
                                <Tutorials />
                            </RouteGuard>

                            <RouteGuard path="/About">
                                <RidsiAbout />
                            </RouteGuard>

                            <RouteGuard path="/Support">
                                <Support />
                            </RouteGuard>

                        </Switch>
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        );
    }
};

export default App;
