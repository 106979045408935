import * as React from "react";
import { IonContent, IonPage, IonToast } from "@ionic/react";
import { RouteProps } from "react-router";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./AppCenter.css";
import firebaseService from "../../services/firebaseService";
import { useState } from "react";

interface AppCenterProps extends RouteProps {
  title: string;
}

const AppCenter: React.FC<AppCenterProps> = (props: AppCenterProps) => {
  const [pageLink, setPageLink] = useState("");
  const [errorResponse, setErrorResponse] = useState(false);



  React.useEffect(() => {
    setErrorResponse(false);
    firebaseService
      .createLink(props.title)
      .then((doc) => {
        const data = doc.data();
        if (data != null) {
          setErrorResponse(false);
          document.cookie = "cookies enabled";
          if (document.cookie.indexOf("cookies enabled") < 0) {
            setErrorResponse(true);
          }
          setPageLink(data.link);
        }
      })
      .catch(() => {
        setErrorResponse(true);
      });
  }, [props.title]);

  return (
    <IonPage>
      <Header title={props.title} />
      <IonToast
        isOpen={errorResponse}
        onDidDismiss={() => setErrorResponse(false)}
        color="danger"
        message="Oops. Looks like we're having some trouble connecting to OmniSci. Please make sure third-party cookies are enabled and try again!"
        cssClass="toast-error"
        buttons={[
          {
            text: "Ok",
            side: "end",
            role: "cancel",
          },
        ]}
      />

      <IonContent>
        {!errorResponse && <iframe className="iframe" src={pageLink} />}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default AppCenter;
