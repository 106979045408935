import React,{ useRef, useEffect,useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonIcon, IonAlert, IonRouterLink,IonLabel } from '@ionic/react';

import { CountyData } from "../../interfaces/CountyData";
// import ProgressBar from "@ramonak/react-progress-bar";
import "./CrashesCard.css"
import { green } from '@material-ui/core/colors';
import { colorFill } from 'ionicons/icons';
import Chart from 'react-apexcharts'

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { Doughnut } from 'react-chartjs-2';

interface GraphDataCardprops {
  content: any;
  crashList: CountyData[];
}


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    // backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    backgroundColor:'lightgray',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#ec4561'
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));





const GraphDataCard: React.FC<GraphDataCardprops> =(props:GraphDataCardprops)=> {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    if (props.content.source != "N/A") setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const history = useHistory();

  const handleOkay = () => {
    closeModal();  // Close the modal first
    history.push('/AppCenter/TranscoreAnalytics');  // Navigate to the desired path
  };

    
   
    const percent=((Number(props.content.data)*100)/200);
    const remaining=100-percent;
    const data={
        
        datasets:[{
            label:'Poll',
            data:[percent,remaining],
            backgroundColor:['#EA7B26','antiquewhite'],
            borderColor:['#EA7B26','antiquewhite'],
            
        }]
    }
    
    const options={
        cutoutPercentage: 80,
        rotation:Math.PI,
        circumference: Math.PI,
        
    }
    
    const gaugeText={
        id:'gaugeText',
        beforeDatasetsDraw(chart:any,args:any,pluginOptions:any){
            const {ctx,chartArea:{top,bottom,left,right,width,height}}=chart;
    
            // console.log(chart.getDatasetMeta(0).data[0]._model)
            const xCenter=chart.getDatasetMeta(0).data[0]._model.x;
            const yCenter=chart.getDatasetMeta(0).data[0]._model.y;
    
            ctx.save();
            ctx.fillStyle='black';
            ctx.font='bold 60px sans-serif';
            ctx.textAlign='center';
            ctx.textBaseline='bottom'
            ctx.fillText(props.content.data,xCenter,yCenter)
        }
    
    }
    
          

    return(
  <>
    <IonCard  className='main-container'>
            <IonCardTitle className="crash-data-card-title">
            <div onClick={openModal} className="crashes-data-card-icon">
                <IonIcon className='custom-ion-icon' color="light" ios={props.content.ios} md={props.content.md}/>
                
              </div>
              <div className='crashes-header-title'>{props.content.title}</div>
             
            </IonCardTitle>
        
            <div className="gauge-container unique-class-to-change-color">
                
                 {/* <Chart type="radialBar" series={semi_series} options={semi_options} width="250" height="230" /> */}
                 {/* <ReactApexChart options={semi_options} series={semi_series} type="radialBar" /> */}
                 <Doughnut data={data} options={options} plugins={[gaugeText]} ></Doughnut>


            </div>
        
        <IonCardTitle className='crashes-title' style={{ color: 'black' }}>
                  Crash Rates By County
        </IonCardTitle>
      
        <div className=" crash-counties">
              {props.crashList.map((county, index) => {
                  return (
                    <div className='crashes-counties-with-bar' key={county.name}>   
                      <div className='crashes-counties-and-percentage-class'>               
                         <div>{county.name} : {county.crashes}</div> <div className='percentage'> {Math.floor(((county.crashes)/Number(props.content.data))*100)}% </div>
                      </div>
                      <div style={{ width: '100%' }}> 
                         {/* <ProgressBar className='bars' key={county.name} completed = {Math.floor(((county.crashes)/Number(props.content.data))*100)} bgColor = "#EA7B26" animateOnRender = {true} isLabelVisible = {false} />  */}
                         {/* <ProgressBar className='bars' key={county.name} variant="warning" now={Math.floor(((county.crashes)/Number(props.content.data))*100)} /> */}
                         <BorderLinearProgress variant="determinate" value={Math.floor(((county.crashes)/Number(props.content.data))*100)} />
                      </div>
                    </div>
                  ); 
              })} 
        </div>
        <div className='crashes-last-updated'>{props.content.updated}</div>
        


    </IonCard>
    <IonAlert
          isOpen={modalOpen}
          header={props.content.title}
          subHeader={"Source: " + props.content.source}
          message={props.content.description}
          buttons={[{text:"More Information", handler: handleOkay}]}
          onDidDismiss={closeModal}
          cssClass="bigger-alert"
        ></IonAlert>
    
    

  </>
   
    );
};
export default GraphDataCard;

