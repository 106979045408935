import * as React from 'react';
import './Settings.css';
import { counties as countiesMO } from '../../assets/counties';
import { roads as roadsMO } from '../../assets/roads';
import {User} from '../../interfaces/User';
import {
    IonButton,
    IonCard, IonCardContent, IonCardHeader, IonCheckbox,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonText,
    IonToggle, useIonToast
} from "@ionic/react";
import {SettingsProps} from '../../interfaces/SettingsProps';
import CountySelector from '../Forms/CountySelector';
import {useEffect, useState} from 'react';
import firebaseService from '../../services/firebaseService';
import Loading from '../Loading/Loading';
import {Device} from '@capacitor/device';
import {PermissionStatus, PushNotifications, Token} from '@capacitor/push-notifications';
import firebase from 'firebase/app';
import 'firebase/messaging';

const NotificationSettings: React.FC<SettingsProps> = (props:SettingsProps) => {
    const [present, dismiss] = useIonToast();
    let user: User = props.userObject;
    if (user.subscriptions == null) {
        user.subscriptions = [];
    }
    const [counties, setCounties] = useState<{name: string, value: string}[]>([]);
    const [roads, setRoads] = useState<{name: string, value: string}[]>([]);
    const [unsavedTopics, setUnsavedTopics] = useState(user.subscriptions);
    const [loading, setLoading] = useState(false);
    const [weatherSubscription, setWeatherSubscription] = useState(user.subscriptions?.includes('Weather'));
    const [pushPermission, setPushPermission] = useState(false);

    function save() {
        const county = counties[0];
        if (unsavedTopics?.indexOf(county.value) === -1) {
            firebaseService.subscribeTokenToTopic({tokens: (user.deviceIDs ? user.deviceIDs : []), topic: county.value}).catch(() => {
                present({
                    buttons: [{ text: 'Okay', handler: () => dismiss() }],
                    message: 'Failed to access subscription service.',
                    color: 'danger',
                    duration: 1500
                });
            });
        }
        let countyRoads = county.value + ":";
        roads.forEach(road => {
            if (unsavedTopics?.indexOf(road.value) === -1) {
                countyRoads += road.value + ",";
                firebaseService.subscribeTokenToTopic({tokens: (user.deviceIDs ? user.deviceIDs : []), topic: road.value}).catch(() => {
                    present({
                        buttons: [{ text: 'Okay', handler: () => dismiss() }],
                        message: 'Failed to access subscription service.',
                        color: 'danger',
                        duration: 1500
                    });
                });
            }
        });

        unsavedTopics?.push(countyRoads);
        if (weatherSubscription) {
            if (unsavedTopics?.indexOf('Weather') === -1) {
                unsavedTopics.push('Weather');
                firebaseService.subscribeTokenToTopic({tokens: (user.deviceIDs ? user.deviceIDs : []), topic: 'Weather'}).catch(() => {
                    present({
                        buttons: [{ text: 'Okay', handler: () => dismiss() }],
                        message: 'Failed to access subscription service.',
                        color: 'danger',
                        duration: 1500
                    });
                });
            }
        } else {
            if (unsavedTopics?.indexOf('Weather') !== -1) {
                unsavedTopics?.splice(unsavedTopics.indexOf('Weather'), 1);
            }
        }

        user.subscriptions?.forEach(sub => {
            if (!unsavedTopics?.includes(sub)) {
                firebaseService.unsubscribeTokenFromTopic({tokens: (user.deviceIDs ? user.deviceIDs : []), topic: sub}).catch(() => {
                    present({
                        buttons: [{ text: 'Okay', handler: () => dismiss() }],
                        message: 'Failed to access subscription service.',
                        color: 'danger',
                        duration: 1500
                    });
                });
            }
        });

        user.subscriptions = unsavedTopics?.sort();
        setLoading(true);
        setCounties([]);
        setRoads([]);
        firebaseService.updateUser(user).then(() => {
            firebaseService.getUserByID(user.uid).then(res => {
                user = res.data() as User;
                setLoading(false);
            }).catch(() => {
                setLoading(false);
                present({
                    buttons: [{ text: 'Okay', handler: () => dismiss() }],
                    message: 'Could not get User ID.',
                    color: 'danger',
                    duration: 1500
                });
            });
        }).catch(() => {
            setLoading(false);
            present({
                buttons: [{ text: 'Okay', handler: () => dismiss() }],
                message: 'Could not update User subscriptions.',
                color: 'danger',
                duration: 1500
            });
        });
    }

    useEffect(() => {
        if (user.uid != '') {
            Device.getInfo().then(device => {
                if (device.operatingSystem === 'ios' || device.operatingSystem === 'android') {
                    PushNotifications.requestPermissions().then((perm: PermissionStatus) => {
                        if (perm.receive === 'granted') {
                            setPushPermission(true);
                            PushNotifications.register();
                        } else {
                            setPushPermission(false);
                        }
                    });
                    PushNotifications.addListener('registration', (token: Token) => {
                        if (user.deviceIDs == null) {
                            user.deviceIDs = [token.value];
                        } else if (user.deviceIDs.indexOf(token.value) === -1) {
                            user.deviceIDs.push(token.value);
                        } else {
                            return;
                        }
                        user.subscriptions?.forEach(sub => {
                            firebaseService.subscribeTokenToTopic({tokens: [token.value], topic: sub}).catch(() => {
                                present({
                                    buttons: [{ text: 'Okay', handler: () => dismiss() }],
                                    message: 'Failed to access subscription service.',
                                    color: 'danger',
                                    duration: 1500
                                })
                            });
                        });
                        firebaseService.updateUser(user);
                    });
                } else {
                    Notification.requestPermission().then(perm => {
                        if (perm === 'granted') {
                            setPushPermission(true);
                            const messaging = firebase.messaging();
                            messaging.getToken({vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY}).then(token => {
                                if (user.deviceIDs == null) {
                                    user.deviceIDs = [token];
                                } else if (user.deviceIDs.indexOf(token) === -1) {
                                    user.deviceIDs.push(token);
                                } else {
                                    return;
                                }
                                user.subscriptions?.forEach(sub => {
                                    firebaseService.subscribeTokenToTopic({tokens: [token], topic: sub}).catch(() => {
                                        present({
                                            buttons: [{ text: 'Okay', handler: () => dismiss() }],
                                            message: 'Failed to access subscription service.',
                                            color: 'danger',
                                            duration: 1500
                                        });
                                    });
                                });
                                firebaseService.updateUser(user);
                            });
                        } else {
                            setPushPermission(false);
                        }
                    })
                }
            });
        }
    }, [present, dismiss, user]);

    return (
        <IonCard className="info-item space-between">
            <IonItemDivider className="div-title" color="light" sticky={true}>
                <IonLabel>Notification Settings</IonLabel>
            </IonItemDivider>
            {/*<IonItem lines="none">*/}
            {/*    <IonText>Enable RIDSI notifications to stay up to date on traffic changes</IonText>*/}
            {/*    <IonToggle color="pink" slot="end" checked />*/}
            {/*</IonItem>*/}
            <IonItem lines="none">
                <IonText>Enable push notifications to your mobile or web device (managed by browser or device settings)</IonText>
                <IonToggle color="pink" slot="end" checked={pushPermission} disabled />
            </IonItem>
            <IonItem lines="none">
                <IonText>Enable weather alerts</IonText>
                <IonToggle color="pink" slot="end" checked={weatherSubscription} onIonChange={() => setWeatherSubscription(!weatherSubscription)} />
            </IonItem>
            <IonLabel>
                Select counties and roads to subscribe to below. If you subscribe to a county and a road, you will receive notifications for that road within that county.
            </IonLabel>
            <div className="county-container">
                <CountySelector counties={counties} setCounties={setCounties} options={countiesMO} type="counties" width="county-small" />
            </div>
            <div className="county-container">
                <CountySelector counties={roads} setCounties={setRoads} options={roadsMO} type="roads" width="county-small" />
            </div>
            <IonItem>
                <IonText>Active Subscriptions</IonText>
            </IonItem>
            <div className="subscription-container">
                { user.subscriptions?.map((sub) => {
                    if (sub !== 'Weather') {
                        return (
                            <IonCard key={sub} className="subscription">
                                <IonCardHeader>Notifications for County: <br /><strong>{sub}</strong></IonCardHeader>
                                <IonCardContent>
                                    {/* Object needs to be of type any here, since the alternative is a custom made Ionic type*/}
                                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                    <IonCheckbox color="pink" className="sub-checkbox" checked={unsavedTopics?.includes(sub)} onIonChange={(event: any) => {
                                        let newTopics = unsavedTopics;
                                        if (event.target?.checked) {
                                            newTopics?.push(sub);
                                        } else {
                                            newTopics = newTopics?.filter((subscription) => subscription !== sub);
                                        }
                                        setUnsavedTopics(newTopics);
                                    }}/>
                                </IonCardContent>
                            </IonCard>
                        );
                    }
                })}
            </div><br /><br />
            <IonButton color="pink" onClick={save}>Save</IonButton>
            {loading && <Loading />}
        </IonCard>
    );
};

export default NotificationSettings;
