import {DeveloperData} from '../../interfaces/AboutData';

import bradProfile from './profile-pictures/bradProfile.jpg';
import brockProfile from './profile-pictures/brockProfile.jpeg';
import treProfile from './profile-pictures/treProfile.jpeg'
import haleyProfile from './profile-pictures/haleyProfile.jpg'
import tristenProfile from './profile-pictures/tristenProfile.jpg';

export {
    bradProfile,
    brockProfile,
    treProfile,
    haleyProfile,
    tristenProfile
}

export const developerItems: DeveloperData[] = [
    {
        name: "Brock Weekley",
        role: "Project Lead - UI Developer",
        description: "Brock Weekley is a graduate student majoring in Computer Science at the University of Missouri. " +
            "Brock's Master's research focuses on Cloud Computing and App Systems. He has several years of experience " +
            "with frontend frameworks such as Angular and React through his internships with SS&C Technologies and StreamlineAI, " +
            "as well as with microservices and REST APIs through Springboot, Firebase, and other Cloud Infrastructure.",
        link: "https://www.brockweekley.com",
        pictureFile: brockProfile
    },
    {
        name: "Brad Schinker",
        role: "Frontend Developer - UI Designer - Scrum Master",
        description: "Brad Schinker is an undergraduate student majoring in Computer Science at the University of Missouri. " +
            "Brad works with a wide variety of software development tools, and has a lot of experience in front-end " +
            "web/app design and development, as well as object-oriented programming languages such as C# and Java. " +
            "He has general knowledge in several other areas of programming, and some practical experience in the field " +
            "through research and internship opportunities.",
        link: "https://www.bradschinker.com",
        pictureFile: bradProfile
    },
    {
        name: "Robert Truesdale",
        role: "Frontend/Backend Developer - UI Designer",
        description: "Robert Truesdale is a to-be graduate studying at the University of Missouri." +
            "Robert has had multiple experiences with web/app design and development through various research" +
            " projects and internships. He has a plethora of experience with various frontend languages and tools," +
            " as well as object-oriented languages. He plans to graduate May 2021 with a Bachelor's degree in" +
            " Computer Science.",
        link: "https://www.github.com/tretrue",
        pictureFile: treProfile
    },
    {
        name: "Haley Massa",
        role: "Frontend Developer",
        description: "Haley Massa is an undergraduate student at the University of Missouri majoring in Computer Science " +
            "and Mathematics. She has worked as a frontend developer on several web development projects and is well-versed in " +
            "frameworks such as Angular, React, and Ionic. Haley also has experience as a student researcher in the field of data " +
            "structures and algorithms.",
        link: "https://www.github.com/hmassa",
        pictureFile: haleyProfile
    },
    {
        name: "Tristen Harr",
        role: "Backend Lead - Database Administrator",
        description: "Tristen Harr is an undergraduate student majoring in Computer Science at the University of Missouri. " +
            "Tristen works primarily with back-end design in a UNIX environment. " +
            "He has experience with multiple languages including C, Java, and Javascript but is most proficient in Python. " +
            "He has experience in embedded systems programming from his internship at Cardinal Health, but does his best work designing API's and working with big data. " +
            "He is currently working on an open source library for data collection.",
        link: "https://www.github.com/tristenharr",
        pictureFile: tristenProfile
    }
];
