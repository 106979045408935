import {
    IonCard,
    IonCardHeader,
    IonContent,
    IonPage,
    IonCardTitle,
    IonLabel,
    IonCardContent,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonItemDivider
} from '@ionic/react';
import * as React from 'react';
import {getUser} from '../../firebaseConfig';
import {useState} from 'react';
import Header from '../../components/Header/Header';
import Footer from "../../components/Footer/Footer";
import {User} from '../../interfaces/User';
import './Profile.css';

import AccountInfo from '../../components/Settings/AccountInfo';
import AccountSettings from '../../components/Settings/AccountSettings';
import LocationSettings from '../../components/Settings/LocationSettings';
import NotificationSettings from '../../components/Settings/NotificationSettings';
import Privacy from '../../components/Settings/Privacy';
import AdminSettings from '../../components/Settings/AdminSettings';
import Tour from "reactour";
import tourService from "../../services/tourService";
import {useHistory} from "react-router";
const Profile: React.FC = () => {
    const [user, setUser] = useState(new User());
    const [date, setDate] = useState(new Date());
    const [accountInfo, setAccountInfo] = useState(true);
    const [accountSettings, setAccountSettings] = useState(true);
    // const [appSettings, setAppSettings] = useState(true);
    const [locationPreferences, setLocationPreferences] = useState(true);
    const [notificationSettings, setNotificationSettings] = useState(true);
    const [administratorSettings, setAdministratorSettings] = useState(true);
    const [privacyPolicy, setPrivacyPolicy] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState('');
    const [mobile, setMobile] = useState(window.innerWidth < 1024);

    const hour = date.getHours();
    const greeting = `Good ${(hour < 12 && 'morning') || (hour < 17 && 'afternoon') || 'evening'}`;

    window.addEventListener('resize', resize);
    function resize(event: UIEvent) {
        const window = event.currentTarget as Window;
        setMobile(window.innerWidth < 1024);
    }

    React.useEffect(() => {
        const findUser = getUser();
        if (findUser != null) {
            setLoading(true);
            findUser.then((foundUser) => {
                const data = foundUser.data();
                if (data != null) {
                    setUser(data as User);
                    setLoading(false);
                    setLoadingError('');
                }
            }, err => {
                setLoadingError(err);
                setLoading(false);
            });
        }

        const updateDate = setInterval(() => {
            setDate(new Date());
        }, 60 * 1000);
        return () => {
            clearInterval(updateDate);
        }
    }, []);

    function setAll() {
        setAccountInfo(true);
        setAccountSettings(true);
        // setAppSettings(true);
        setLocationPreferences(true);
        setNotificationSettings(true);
        setAdministratorSettings(true);
        setPrivacyPolicy(true);
    }

    function closeAll() {
        setAccountInfo(false);
        setAccountSettings(false);
        // setAppSettings(false);
        setLocationPreferences(false);
        setNotificationSettings(false);
        setAdministratorSettings(false);
        setPrivacyPolicy(false);
    }
    const steps = tourService.getStepsFor('Profile');
    const isTour = tourService.StartTour();
    const history = useHistory();

    return(
        <IonPage>
            <Header title="User Profile" />
            <IonContent fullscreen color="light">
                { (!loading && loadingError === '') && <IonCard className="card-header-container">
                    <IonCardHeader>
                        <IonCardTitle className="card-header-title-box profile-title-coloring first-step">Profile and Settings</IonCardTitle>
                        <IonCardTitle className="secondary-header">{greeting + ', ' + user.displayName}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="profile-content">
                        <IonGrid>
                            <IonRow className="ion-wrap-reverse">
                                <IonCol size-xs="12" size-sm="12" size-md="12" size-lg="8">
                                    { accountInfo &&<AccountInfo userObject={user} editable={false} />}
                                    { accountSettings &&<AccountSettings userObject={user}/>}
                                    { locationPreferences &&<LocationSettings />}
                                    { notificationSettings &&<NotificationSettings userObject={user}/>}
                                    { administratorSettings && user.admin && <AdminSettings />}
                                    { privacyPolicy &&<Privacy/>}
                                </IonCol>
                                { !mobile && <IonCol className="quick-nav-menu" size-xs="12" size-sm="12" size-md="12" size-lg="4">
                                    <IonCard>
                                        <IonItemDivider className="quick-nav-title second-step" color="pink" sticky={true}>
                                            <IonLabel>Quick Navigation</IonLabel>
                                        </IonItemDivider>
                                        <IonItem onClick={setAll} button={true}>
                                            <IonLabel>All Settings</IonLabel>
                                        </IonItem>
                                        <IonItem className="third-step" onClick={() => {closeAll(); setAccountInfo(true);}} button={true}>
                                            <IonLabel>Account Information</IonLabel>
                                        </IonItem>
                                        <IonItem className="fourth-step" onClick={() => {closeAll(); setAccountSettings(true);}} button={true}>
                                            <IonLabel>Account Settings</IonLabel>
                                        </IonItem>
                                        {/*<IonItem onClick={() => {closeAll(); setAppSettings(true);}} button={true}>*/}
                                        {/*    <IonLabel>App Settings</IonLabel>*/}
                                        {/*</IonItem>*/}
                                        <IonItem className="fifth-step" onClick={() => {closeAll(); setLocationPreferences(true);}} button={true}>
                                            <IonLabel>Location Preferences</IonLabel>
                                        </IonItem>
                                        <IonItem className="sixth-step" onClick={() => {closeAll(); setNotificationSettings(true);}} button={true}>
                                            <IonLabel>Notification Settings</IonLabel>
                                        </IonItem>
                                        { user.admin && <IonItem className="seventh-step" onClick={() => {closeAll(); setAdministratorSettings(true);}} button={true}>
                                            <IonLabel>Administrator Settings</IonLabel>
                                        </IonItem>}
                                        <IonItem className="eighth-step" onClick={() => {closeAll(); setPrivacyPolicy(true);}} button={true}>
                                            <IonLabel>Privacy Policy</IonLabel>
                                        </IonItem>
                                    </IonCard>
                                </IonCol> }

                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>}
                <Footer/>
                {(!loading && loadingError !== '') && <IonCard>
                    {loadingError}
                </IonCard>}
            </IonContent>
            <Tour
                steps={steps}
                isOpen={isTour}
                accentColor='black'
                onRequestClose={() => {tourService.GoBack(history)}}
            />
        </IonPage>
    );
};

export default Profile;
