import * as React from 'react';
import {useState} from 'react';
import Header from '../../components/Header/Header';
import Map from '../../components/Maps/Map';
import './Visualization.css';

import {
    IonPage,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonToggle,
    IonItemDivider
} from '@ionic/react';
import tourService from '../../services/tourService';
import {useHistory} from 'react-router';
import Tour from 'reactour';
import {Marker} from '../../interfaces/Marker';

const Visualization: React.FC = () => {
    const [traffic, setTraffic] = useState<boolean>(false);
    const [wazeIncidents, setWazeIncidents] = useState<boolean>(false);
    const [wazeJams, setWazeJams] = useState<boolean>(false);
    const [transcore, setTranscore] = useState<boolean>(false);
    const [location, setLocation] = useState<boolean>(true);
    const [weather, setWeather] = useState<boolean>(false);
    const [polygon, setPolygon] = useState<boolean>(true);
    const [selectedMarker, setSelectedMarker] = useState<Marker | null>(null);
    const steps = tourService.getStepsFor('Visualization');
    const isTour = tourService.StartTour();
    const history = useHistory();

    return(
        <IonPage>
            <Header title='Visualization' />
            <IonContent fullscreen color='light'>
                <IonCard className='card-header-container card-container-padding'>
                    <IonCardHeader>
                        <IonCardTitle className='card-header-title-box viz-title-coloring first-step'>Data Visualization Map</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className='box'>
                        <div className='map-div'>
                            <Map height="70em" traffic={traffic} wazeIncidents={wazeIncidents} wazeJams={wazeJams} transcore={transcore} location={location} stateOutline={polygon} weather={weather} markerSelection={setSelectedMarker}/>
                        </div>
                        <div className="controls-box">
                            <IonCard className='controls-div second-step'>
                                <IonItemDivider className='div-title' color='light'>
                                    <IonLabel>Map Layers</IonLabel>
                                </IonItemDivider>
                                <IonList>
                                    <IonItem lines='none'>
                                        <IonLabel>Traffic</IonLabel>
                                        <IonToggle color="green" value='traffic' checked={traffic} onIonChange={e => setTraffic(e.detail.checked)}/>
                                    </IonItem>
                                    <IonItem lines='none'>
                                        <IonLabel>Waze Incident Data</IonLabel>
                                        <IonToggle color="green" value='wazeIncidents' checked={wazeIncidents} onIonChange={e => setWazeIncidents(e.detail.checked)}/>
                                    </IonItem>
                                    <IonItem lines='none'>
                                        <IonLabel>Waze Jam Data</IonLabel>
                                        <IonToggle color="green" value='wazeJams' checked={wazeJams} onIonChange={e => setWazeJams(e.detail.checked)}/>
                                    </IonItem>
                                    <IonItem lines='none'>
                                        <IonLabel>Transcore Data</IonLabel>
                                        <IonToggle color="green" value='transcore' checked={transcore} onIonChange={e => setTranscore(e.detail.checked)}/>
                                    </IonItem>
                                    <IonItem lines='none'>
                                        <IonLabel>Weather</IonLabel>
                                        <IonToggle color='green' value='weather' checked={weather} onIonChange={e => setWeather(e.detail.checked)}/>
                                    </IonItem>
                                    <IonItem lines='none'>
                                        <IonLabel>Current Location</IonLabel>
                                        <IonToggle color='green' value='location' checked={location} onIonChange={e => setLocation(e.detail.checked)}/>
                                    </IonItem>
                                    <IonItem lines='none'>
                                        <IonLabel>State Outline</IonLabel>
                                        <IonToggle color='green' value='polygon' checked={polygon} onIonChange={e => setPolygon(e.detail.checked)}/>
                                    </IonItem>
                                    <IonLabel>Jams Key (MPH):</IonLabel>
                                    <IonItem lines='none'>
                                        <div className="key">
                                            <div className="key-box red-key">0</div>
                                            <div className="key-box orangered-key">1-5</div>
                                            <div className="key-box gold-key">6-10</div>
                                            <div className="key-box greenyellow-key">11-15</div>
                                            <div className="key-box palegreen-key">16-20</div>
                                            <div className="key-box mediumseagreen-key">21-25</div>
                                            <div className="key-box forestgreen-key">25+</div>
                                        </div>
                                    </IonItem>
                                </IonList>
                            </IonCard>
                            { selectedMarker && <IonCard className='controls-div'>
                                <IonItemDivider className='div-title' color='light'>
                                    <IonLabel>Marker Information</IonLabel>
                                </IonItemDivider>
                                <IonList>
                                    <IonItem lines="none">
                                        <IonLabel>
                                            Event Type:
                                        </IonLabel>
                                        <IonLabel slot="end">
                                            { selectedMarker.eventType }
                                        </IonLabel>
                                    </IonItem>
                                    <IonItem lines="none">
                                        <IonLabel>
                                            County:
                                        </IonLabel>
                                        <IonLabel slot="end">
                                            { selectedMarker.county }
                                        </IonLabel>
                                    </IonItem>
                                    { selectedMarker.description !== '' && <IonItem lines="none">
                                        <IonLabel>
                                            Description:
                                        </IonLabel>
                                        <IonLabel slot="end">
                                            { selectedMarker.description }
                                        </IonLabel>
                                    </IonItem>}
                                    <IonItem lines="none">
                                        <IonLabel>
                                            Latitude & Longitude:
                                        </IonLabel>
                                        <IonLabel slot="end">
                                            { selectedMarker.latitude }, &nbsp; { selectedMarker.longitude }
                                        </IonLabel>
                                    </IonItem>
                                    { selectedMarker.street !== '' && <IonItem lines="none">
                                        <IonLabel>
                                            Street:
                                        </IonLabel>
                                        <IonLabel slot="end">
                                            { selectedMarker.street }
                                        </IonLabel>
                                    </IonItem>}
                                    <IonItem lines="none">
                                        <IonLabel>
                                            Recorded Time:
                                        </IonLabel>
                                        <IonLabel slot="end">
                                            { selectedMarker.recordedTime.toLocaleString() }
                                        </IonLabel>
                                    </IonItem>
                                    { selectedMarker.eventType === 'Weather Event' && <div className="weather-div">
                                        <IonItem lines="none">
                                            <IonLabel>
                                                Temperature:
                                            </IonLabel>
                                            <IonLabel slot="end">
                                                { selectedMarker.temperature }°F
                                            </IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel>
                                                Wind Gust:
                                            </IonLabel>
                                            <IonLabel slot="end">
                                                { selectedMarker.windGust } MPH
                                            </IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel>
                                                Precipitation:
                                            </IonLabel>
                                            <IonLabel slot="end">
                                                { selectedMarker.precipitationIntensity }
                                            </IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel>
                                                Snow Intensity:
                                            </IonLabel>
                                            <IonLabel slot="end">
                                                { selectedMarker.snowIntensity }
                                            </IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel>
                                            Freezing Range Intensity:
                                            </IonLabel>
                                            <IonLabel slot="end">
                                                { selectedMarker.freezingRangeIntensity }
                                            </IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel>
                                            Sleet Intensity:
                                            </IonLabel>
                                            <IonLabel slot="end">
                                                { selectedMarker.sleetIntensity }
                                            </IonLabel>
                                        </IonItem>
                                    </div>}
                                </IonList>
                            </IonCard>}
                            { !selectedMarker && <div className="controls-div" />}
                        </div>
                    </IonCardContent>
                </IonCard>
            </IonContent>
            <Tour
                steps={steps}
                isOpen={isTour}
                accentColor='black'
                onRequestClose={() => {tourService.GoBack(history)}}/>
        </IonPage>
    );
};

export default Visualization;
