import * as React from 'react';
import './AboutSegments.css';

import {
    IonCard,
    IonCardContent, IonCol,
    IonGrid, IonImg,
    IonItemDivider,
    IonLabel,
    IonRow,
    IonText
} from "@ionic/react";

import { developerItems } from "../../assets/developer-data/developerItems";
import { DeveloperData, AgencyData } from "../../interfaces/AboutData";
import { devAgencyItems } from "../../assets/agency-logos/agencyItems";
import DeveloperCard from "../Cards/DeveloperCard/DeveloperCard";

const AppTeamSegment: React.FC = () => {
    const agencies: Array<AgencyData> = devAgencyItems;
    const dataItems: Array<DeveloperData> = developerItems;
    return (
        <IonGrid>
            <IonRow>
                <IonCard className="about-segment-card">
                    <IonItemDivider className="about-header-card-title" color="light">
                        <IonLabel>Development Team</IonLabel>
                    </IonItemDivider>
                    <IonCardContent className="ion-text-left">
                        <div className="about-card-subtitle-container">
                            <IonLabel className="about-card-subtitle">Organizations Involved in the Development Process: </IonLabel>
                        </div>
                        <IonGrid>
                            <IonRow className="ion-justify-content-between ion-align-items-center">
                                { agencies.map((agency, index) => {
                                    return(
                                        <IonCol key={index} className="agency-logos" size-lg="2" size-xs="10">
                                            <IonImg src={agency.pictureFile} alt="Logo Image"/>
                                        </IonCol>
                                    );
                                }) }
                            </IonRow>
                        </IonGrid>
                        <br />
                        <IonLabel className="about-card-subtitle">CCU Developers: </IonLabel>
                        <IonText>
                            <br/>
                            The Cloud Compute and User Interface Development team is a group of Mizzou Software Developers
                            with extensive experience in Web Development and Cloud Infrastructure. Working together
                            on high profile projects in the past has earned us the opportunity to build RIDSI as
                            our Senior Capstone project.
                            <br/><br/>
                            Included below are the software developers and project managers that contributed to this project:
                        </IonText>
                    </IonCardContent>
                </IonCard>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                {dataItems.map((developer,index) =>{
                    return(
                        <DeveloperCard key={index} developer={developer} />
                    );
                })}
            </IonRow>
        </IonGrid>
    );
};

export default AppTeamSegment;
