import {AgencyData} from '../../interfaces/AboutData';

import SCCG from '../../assets/agency-logos/SCCGov.png';
import EWG from '../../assets/agency-logos/EWG.png';
import MODOT from '../../assets/agency-logos/MoDOT.png';
import TSquared from "../../assets/agency-logos/TSquared.jpg";
import CBB from "../../assets/agency-logos/CBB.jpg";
import DataDash from "../../assets/agency-logos/DataDash.jpg";
import Mizzou from "../../assets/agency-logos/Mizzou.jpg";

export {
    SCCG,
    EWG,
    Mizzou,
    MODOT,
    TSquared,
    CBB,
    DataDash
}

export const agencyItems: AgencyData[] = [
    {
        agencyLink: "https://www.sccmo.org/",
        pictureFile: SCCG,
        description: 'St. Charles County, Missouri, founded in 1812, is one of the fastest ' +
            'growing counties in Missouri. With a population of 407,056, is it the state\'s ' +
            'third largest county. It is home to 17 scenic county parks encompassing nearly ' +
            '4,000 acres with additional land under development and more in reserve for ' +
            'future development. \n' +
            '\n' +
            'With one of the largest economies in the state, St. Charles County is home ' +
            'to multinational companies including General Motors, Citi, MasterCard ' +
            'Worldwide and Boeing. It is ranked among the top 25 counties in the nation ' +
            'in high-tech job growth by the Progressive Policy Institute and consistently ' +
            'has the lowest unemployment rate in the Metropolitan St. Louis area. \n' +
            '\n' +
            'St. Charles County Government has more than 1,170 employees in more than ' +
            '30 departments and offices who work to make the county an excellent place ' +
            'to live, work and shop.'
    },
    {
        agencyLink: "https://www.ewgateway.org/",
        pictureFile: EWG,
        description: 'East-West Gateway Council of Governments (EWG) was incorporated ' +
            'in 1965 to provide a forum for cooperative problem-solving and the ' +
            'coordinated development of regional policy.  EWG’s founders were the chief ' +
            'elected officials of the city of St. Louis, Franklin, Jefferson, ' +
            'St. Charles, and St. Louis counties in Missouri and Madison, Monroe, ' +
            'and St. Clair counties in Illinois.\n' +
            '\n' +
            'Originally charged with coordinating transportation funding for St. Louis ' +
            'regional projects, EWG’s purview has expanded through the years to bring ' +
            'together governmental officials, planning professionals, citizens, and ' +
            'private sector partners to develop regional and local plans for ' +
            'transportation, environmental quality, housing, emergency preparedness, ' +
            'and access to employment.'
    },
    {
        agencyLink: "https://www.modot.org/",
        pictureFile: MODOT,
        description: 'Since 2005, traffic fatalities on Missouri roadways have ' +
            'fallen to the lowest totals since the late 1940s. Through ' +
            'research and analysis of traffic crash data, MoDOT\'s ' +
            'Traffic and Highway Safety Division develops programs ' +
            'that address high-risk driver behaviors such as impaired ' +
            'driving, failure to use safety belts and child safety seats, ' +
            'speeding and distracted driving.\n' +
            'MoDOT works with safety advocates across the state ' +
            'to implement Missouri’s strategic highway safety plan: ' +
            '“Show-Me Zero.”\n' +
            'As part of the Missouri Coalition for Roadway Safety, ' +
            'MoDOT assists in strategic implementation of safety ' +
            'measures in the areas of education, enforcement, ' +
            'engineering and emergency medical services to reduce ' +
            'the number of fatal and disabling injury accidents on ' +
            'Missouri roadways.'
    }
];

export const devAgencyItems: AgencyData[] = [
    {
        agencyLink: "https://missouri.edu/",
        pictureFile: Mizzou,
        description: ''
    },
    {
        agencyLink: "https://datadash.com/",
        pictureFile: DataDash,
        description: ''
    },
    {
        agencyLink: "https://www.modot.org/sites/default/files/documents/Tsquared%20Traffic%20and%20Transportation%20LLC.pdf/",
        pictureFile: TSquared,
        description: ''
    },
    {
        agencyLink: "http://www.cbbtraffic.com/",
        pictureFile: CBB,
        description: ''
    },
];
