import * as React from 'react';
import {useEffect, useState} from 'react';

import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent, IonFooter,
    IonHeader, IonIcon, IonImg, IonInput,
    IonItem, IonLabel, IonMenuButton,
    IonPage, IonRouterLink,
    IonToolbar
} from '@ionic/react';
import { fingerPrintOutline,
    fingerPrintSharp,
    personAddOutline,
    personAddSharp,
    mailOutline,
    mailSharp,
    lockClosedOutline,
    lockClosedSharp,
    logoFacebook,
    logoGoogle,
    logoTwitter } from 'ionicons/icons';

import './Login.css';
import {createUser, getRedirectResult, googleAuthentication, facebookAuthentication, twitterAuthentication, loginUser, watchUser} from '../../firebaseConfig';
import {useHistory} from 'react-router';
import RIDSIR from '../../assets/icon/favicon.png';
import firebaseService from '../../services/firebaseService';
import {User} from '../../interfaces/User';
import Loading from '../../components/Loading/Loading';

const Login: React.FC = () => {
    /* Set status of mobile based on window size */
    const [mobile, setMobile] = useState(window.innerWidth < 768);
    const [showLoading, setShowLoading] = useState(true);
    window.addEventListener('resize', resize);
    function resize(event: UIEvent) {
        const window = event.currentTarget as Window;
        setMobile(window.innerWidth < 768);
    }

    watchUser().onAuthStateChanged(() => {
        getRedirectResult().then(res => {
            if (res.credential && res.user) {
                if (res.user.email) {
                    firebaseService.getUserByID(res.user.uid).then(user => {
                        const foundUser: User = user.data() as User;
                        if (!foundUser && res.user) {
                            const newUser = new User(
                                res.user.uid,
                                false,
                                false,
                                false,
                                [],
                                res.user.email,
                                res.user.displayName,
                                "",
                                null,
                                null,
                                true,
                                []
                            );
                            createUser(newUser).then(() => {
                                (res.user?.emailVerified || res.user?.providerId !== 'password') ? history.push('/Dashboard') : history.push('/EmailVerification');
                                setShowLoading(false);
                            }).catch(err => {
                                setErrorMessage(err.message);
                                setShowLoading(false);
                            })
                        } else {
                            (res.user?.emailVerified || res.user?.providerId !== 'password') ? history.push('/Dashboard') : history.push('/EmailVerification');
                            setShowLoading(false);
                        }
                    }).catch(err => {
                        setErrorMessage(err.message);
                        setShowLoading(false);
                    });
                } else {
                    setShowLoading(false);
                }
            } else {
                setShowLoading(false);
            }
        }).catch((err) => {
            setErrorMessage(err.message);
            setShowLoading(false);
        });
    });


    /* Login implementation change */

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    /* Registration stuff */

    /* Set up routing */
    const history = useHistory();

    function login() {
        setShowLoading(true);
        setErrorMessage('');
        loginUser(username, password).then((res) => {
            if (res.user != null) {
                // TODO: watchUser used to work here, but it doesn't anymore. So timeout until we figure out why
                setTimeout(() => {
                    history.push('/Dashboard');
                    setShowLoading(false);
                }, 1000);
            }
        }).catch((err) => {
            setErrorMessage(err.message);
            setShowLoading(false);
        });
    }

    function googleLogin() {
        setShowLoading(true);
        googleAuthentication().catch((err) => {
            setErrorMessage(err.message);
            setShowLoading(false);
        })
    }

    function twitterLogin() {
        setShowLoading(true);
        twitterAuthentication().catch((err) => {
            setErrorMessage(err.message);
            setShowLoading(false);
        })
    }

    function facebookLogin() {
        setShowLoading(true);
        facebookAuthentication().catch((err) => {
            setErrorMessage(err.message);
            setShowLoading(false);
        })
    }
    function handleKeyPress(event: { key: string; }) {
        if(event.key === 'Enter'){
            login();
        }
    }


    useEffect(() => {
        return () => {
            setUsername('');
            setPassword('');
            setErrorMessage('');
        };
    }, []);

    return (
        <IonPage>
            <IonContent color="dark">

                <IonHeader className="ion-no-border">
                    <IonToolbar color="dark" className="header">
                        <IonItem color="dark" slot="start" lines="none"><IonImg className="ridsi-icon" src={RIDSIR} />
                            Login Page
                        </IonItem>
                        {!mobile && <IonItem button={true} color="dark" slot="end" lines="none" routerLink="/Login">
                            <IonIcon ios={fingerPrintOutline} md={fingerPrintSharp} />
                            &nbsp;
                            Login
                        </IonItem>}
                        {!mobile && <IonItem button={true} color="dark" slot="end" lines="none" routerLink="/Registration">
                            <IonIcon ios={personAddOutline} md={personAddSharp} />
                            &nbsp;
                            Register
                        </IonItem>}
                        {mobile && <IonItem color="dark" slot="end" lines="none">
                            <IonMenuButton color="light" menu="login" />
                        </IonItem>}
                    </IonToolbar>
                </IonHeader>

                <IonToolbar className="login-container" color="dark">


                    {/* Login Page */}
                    <div className="login-card-container">
                        <IonCard className="inner-card" color="pink">
                            <IonCardContent>
                                <IonItem className="ion-text-center" color="pink" lines="none">
                                    <IonLabel className="login-label">Log in</IonLabel>
                                </IonItem>
                                <IonItem className="ion-text-center" color="pink" lines="none">
                                    <IonLabel className="logo-container">
                                        <IonIcon size="large" className="sign-in-logo" onClick={facebookLogin} ios={logoFacebook} md={logoFacebook} />
                                        <IonIcon size="large" className="sign-in-logo" onClick={twitterLogin} ios={logoTwitter} md={logoTwitter} />
                                        <IonIcon size="large" className="sign-in-logo" onClick={googleLogin} ios={logoGoogle} md={logoGoogle} />
                                    </IonLabel>
                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                        <IonCard className="card">
                            <IonCardContent>
                                <div className="form">
                                    <IonItem className="formItem">
                                        <IonLabel position="floating">Email</IonLabel>
                                        {/* Object needs to be of type any here, since the alternative is a custom made Ionic type*/}
                                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                        <IonInput value={username} onIonChange={(e: any) => setUsername(e.target.value)} onKeyPress={handleKeyPress}/>
                                        <IonIcon ios={mailOutline} md={mailSharp} slot="end" />
                                    </IonItem>
                                    <IonItem className="formItem">
                                        <IonLabel position="floating">Password</IonLabel>
                                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                        <IonInput value={password} type="password" onIonChange={(e: any) => setPassword(e.target.value)} onKeyPress={handleKeyPress}/>
                                        <IonIcon ios={lockClosedOutline} md={lockClosedSharp} slot="end" />
                                    </IonItem>
                                    <br />
                                    <div className="links">
                                        <IonRouterLink className="link" routerLink="/ForgotPassword"><IonLabel>Forgot your password?</IonLabel><br /></IonRouterLink>
                                        <IonRouterLink className="link" routerLink="/Registration"><IonLabel>Create an account</IonLabel></IonRouterLink>
                                    </div>
                                    <div className="error-message">
                                        { errorMessage }
                                    </div>
                                    <IonItem button={false} className="ion-text-center" lines="none">
                                        <IonLabel>
                                            <IonButton color="pink" onClick={(e) => {
                                                e.preventDefault();
                                                login()
                                            }}>Let&#39;s Go</IonButton>
                                        </IonLabel>
                                    </IonItem>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </div>


                </IonToolbar>
            </IonContent>



            <IonFooter className="ion-no-border">
                <IonToolbar color="dark">
                    <IonLabel slot="end">@TITAN 2021 All rights reserved.</IonLabel>
                </IonToolbar>
            </IonFooter>

            {showLoading && <Loading />}

        </IonPage>
    );
};

export default Login;
