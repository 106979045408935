import * as React from 'react';
import {IonCard, IonCardContent, IonCardHeader, IonContent, IonIcon, IonPage} from '@ionic/react';
import Header from '../../../components/Header/Header';
import {useEffect, useState} from 'react';
import {Link} from '../../../interfaces/Link';
import firebaseService from '../../../services/firebaseService';
import {Device} from '@capacitor/device';
import iconService from '../../../services/iconService';
import './MorePages.css';



const MorePages: React.FC = () => {
    const [links, setLinks] = useState<Link[]>();

    useEffect(() => {
        const foundLinks: Link[] = [];
        firebaseService.getLinks().then(res => {
            Device.getInfo().then(device => {
                res.docs.forEach(doc => {
                    const foundLink = doc.data() as Link;
                    foundLink.name = doc.id;
                    foundLink.icon = iconService.getIcon(foundLink.icon, device.platform);
                    foundLinks.push(foundLink);
                });
                foundLinks.sort((a, b) => {
                    return a.order - b.order;
                });
                setLinks(foundLinks);
            });
        })
    }, []);

    return(
        <IonPage>
            <Header title="App Center" />
            <IonContent>
                <div className="link-content">
                    <IonCard color="light" className="link-container">
                        {links?.map((link, index) => {
                            return (
                                <IonCard className="link-card" routerLink={"/AppCenter/" + link.name} routerDirection="none" key={index}>
                                    <IonCardHeader className="ion-text-center">
                                        {link.name}
                                    </IonCardHeader>
                                    <IonCardContent className="link-card-content">
                                        <IonIcon color="pink" size="large" icon={link.icon} />
                                    </IonCardContent>
                                </IonCard>
                            );
                        })}
                    </IonCard>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default MorePages;
