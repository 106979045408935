import React,{ useRef, useEffect,useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonIcon, IonAlert } from '@ionic/react';


import { CountyData } from "../../interfaces/CountyData";
// import ProgressBar from "@ramonak/react-progress-bar";
import "./Congestioncard.css"
import { green } from '@material-ui/core/colors';
import { colorFill } from 'ionicons/icons';
// import { color } from '@mui/system';
// import ProgressBar from 'react-bootstrap/ProgressBar';
import { Doughnut } from 'react-chartjs-2';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CircleIcon from '@mui/icons-material/Circle';

import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import Chart from 'react-apexcharts'
interface GraphDataCardprops {
  content: any;
  crashList: CountyData[];
  newdata:any;
}




const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    // backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    backgroundColor:'lightgray',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#ec4561'
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const GraphDataCard: React.FC<GraphDataCardprops> =(props:GraphDataCardprops)=> {

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
     setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const history = useHistory();

  const handleOkay = () => {
    closeModal();  // Close the modal first
    history.push('/AppCenter/Congestion');  // Navigate to the desired path
  };

  const percent=((Number(props.newdata.congested_miles)*100)/300);
  const remaining=100-percent;
  const data={
      
      datasets:[{
          label:'Poll',
          data:[percent,remaining],
          backgroundColor:['#EA7B26','antiquewhite'],
          borderColor:['#EA7B26','antiquewhite'],
          
      }]
  }
  
  const options={
      cutoutPercentage: 80,
      rotation:Math.PI,
      circumference: Math.PI,
      
  }
  
  const gaugeText={
      id:'gaugeText',
      beforeDatasetsDraw(chart:any,args:any,pluginOptions:any){
          const {ctx,chartArea:{top,bottom,left,right,width,height}}=chart;
  
          // console.log(chart.getDatasetMeta(0).data[0]._model)
          const xCenter=chart.getDatasetMeta(0).data[0]._model.x;
          const yCenter=chart.getDatasetMeta(0).data[0]._model.y;
  
          ctx.save();
          ctx.fillStyle='black';
          ctx.font='bold 50px sans-serif';
          ctx.textAlign='center';
          ctx.textBaseline='bottom'
          ctx.fillText(props.newdata.congested_miles,xCenter,yCenter)
      }
  
  }
  



    return(
  <>
    <IonCard  className='congestion-main-container'>
            <IonCardTitle className="congestion-data-card-title">
              
              <div onClick={openModal} className="congestion-data-card-icon">
                <IonIcon  color="light" ios={props.content.ios} md={props.content.md}/>
              </div>
              <div className='congestion-header-title'>Congestion Miles</div>
            </IonCardTitle>
        
        <div className="congestion-circle-pti">
           <div className='congestion-semi-circle'>
              <Doughnut data={data} options={options} plugins={[gaugeText]} ></Doughnut>
           </div>
            <div className='pti-tti'>
              <div className='pti'>
                  <div className="pti-circle"><CircleIcon style={{fill:'red',fontSize:'12'}}></CircleIcon></div> <div style={{fontSize:12,color:'black'}}>PTI : {props.newdata.pti}</div>
              </div>
              <div className='tti'>
                  <div className="tti-circle"><CircleIcon style={{fill:'red',fontSize:'12'}}></CircleIcon></div> <div style={{fontSize:12,color:'black'}}>TTI : {props.newdata.tti}</div>
              </div>
            </div>
        </div>
        
        {/* <div className="gauge-container">
        <Gauge width={100} height={100} value={60} />
        </div> */}
        
        <IonCardTitle className='crashes-title' style={{ color: 'black' }}>
                  Congestion By County
        </IonCardTitle>
        <div className=" crash-counties">
              {props.newdata.countyCongestion.map((county:any, index:number) => {
                  return (
                    <div className='crashes-counties-with-bar' key={county.name}>   
                      <div className='crashes-counties-and-percentage'>               
                         <div>{county.name} : {Math.floor(county.miles)}</div> <div className='percentage'> {Math.floor(((county.miles)/Number(props.newdata.congested_miles))*100)}% </div>
                      </div>
                      <div style={{ width: '100%' }}> 
                         {/* <ProgressBar className='bars' key={county.name} completed = {Math.floor(((county.crashes)/Number(props.content.data))*100)} bgColor = "#EA7B26" animateOnRender = {true} isLabelVisible = {false} />  */}
                         {/* <ProgressBar className='bars' key={county.name} variant="warning" now={Math.floor(((county.crashes)/Number(props.content.data))*100)} /> */}
                         <BorderLinearProgress variant="determinate" value={Math.floor(((county.miles)/Number(props.newdata.congested_miles))*100)} />
                      </div>
                    </div>
                  ); 
              })} 
        </div>
        <div className='crash-last-updated'>{props.content.updated}</div>
    </IonCard>
    <IonAlert
          isOpen={modalOpen}
          header={props.content.title}
          subHeader={"Congestion Information"}
          message={"Click below for more information on congestion"}
          buttons={[{text:"More Information", handler: handleOkay}]}
          onDidDismiss={closeModal}
          cssClass="bigger-alert"
        ></IonAlert>
    

  </>
   
    );
};
export default GraphDataCard;

