import Graph from './Graph';

import { GraphData } from '../../interfaces/GraphData';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import * as React from 'react';
import {RouteProps} from 'react-router';
import WorkZoneDataCard from '../Cards/Workzonescard';

interface GraphContainerData extends RouteProps {
    dailyCrashCount: number[];
    dailyCrashDay: string[];
    incidentTypeCount: number[];
    incidentTypeName: string[];
    trafficCount: number[];
    lastUpdated: string;
    content: any;
    crashList: any;
    newdata:any;
}
const GraphContainer: React.FC<GraphContainerData> = (props: GraphContainerData) => {
    const graphItems: GraphData[] = [
        {
            labels: props.dailyCrashDay,
            series: props.dailyCrashCount,
            graphType: "Line",
            title: "Daily Crashes",
            subtitle: "Past Week",
            content: "Updated " + props.lastUpdated,
            color: "blue"
        },
        {
            labels: props.incidentTypeName,
            series: props.incidentTypeCount,
            graphType: "Bar",
            title: "Incident Types",
            subtitle: "Last incident report",
            content: "Updated " + props.lastUpdated,
            color: "orange"

        },
        {
            labels: [
                "12am",
                "1am",
                "2am",
                "3am",
                "4am",
                "5am",
                "6am",
                "7am",
                "8am",
                "9am",
                "10am",
                "11am",
                "12pm",
                "1pm",
                "2pm",
                "3pm",
                "4pm",
                "5pm",
                "6pm",
                "7pm",
                "8pm",
                "9pm",
                "10pm",
                "11pm"],
            series: props.trafficCount,
            graphType: "Line",
            title: "Traffic Counts",
            subtitle: "Past 24 hours",
            content: "Updated " + props.lastUpdated,
            color: "red"
        }
    ];

  const dataItems: Array<GraphData> = graphItems;

  return (
    <IonGrid>
      <IonRow className="fourth-step" style={{ display: 'flex', alignItems: 'stretch' }}>
        {dataItems.map((value: GraphData, index: number) => {
          if (index === 0 || index === 1) {
          
          return (
            <IonCol key={index} size-lg="3.5" size-md="12" size-sm="12" size="12">
              <Graph labels={value.labels} series={value.series} title={value.title} subtitle={value.subtitle} graphType={value.graphType} content={value.content} color={value.color} />
            </IonCol>
          )
        }
          if(index===2)
            {
              return (
                <IonCol key={index} size-lg="5" size-md="12" size-sm="12" size="12">
                 <WorkZoneDataCard content={props.content} crashList={props.crashList} newdata={props.newdata}/>
                </IonCol>
              )


              
              
            }
        
        })}
        
      </IonRow>  
    </IonGrid>

  );
};

export default GraphContainer;
