import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    IonFooter,
    IonCard,
    IonCardContent, IonCardHeader,
    IonContent,
    IonLabel,
    IonPage,
    IonToolbar,
    IonButton,
    IonIcon
} from '@ionic/react';
import { getFireUser } from '../../firebaseConfig';
import './Registration.css';
import {
    arrowBackOutline,
    arrowBackSharp,
    arrowForwardOutline, arrowForwardSharp,
    checkmarkCircleOutline,
    checkmarkCircleSharp
} from 'ionicons/icons';
import {useHistory} from 'react-router';
import firebaseService from '../../services/firebaseService';
import {User} from '../../interfaces/User';

const EmailVerification: React.FC = () => {
    const user = getFireUser();
    const redirect = window.location.href.replace('/EmailVerification', '/Dashboard');
    const [error, setError] = useState<string>('');
    const [resent, setResent] = useState<boolean>(false);
    const [approvalStatus, setApprovalStatus] = useState('Awaiting Approval');
    const [approvalColor, setApprovalColor] = useState('danger');

    const resend = () => {
        user?.sendEmailVerification({
            url: redirect,
        }).then(() => {
            setResent(true);
        }).catch((error) => {
            setError(error.message);
            setResent(false);
        });
    };

    const history = useHistory();

    const back = () => {
        history.push('/Login');
    };

    const toDashboard = () => {
        history.push('/Dashboard');
    };

    useEffect(() => {
        if (user != null) {
            firebaseService.watchUser(user.uid).onSnapshot(doc => {
                const userReference = doc.data() as User;
                if (userReference.verified) {
                    setApprovalStatus('Verified');
                    setApprovalColor('success');
                } else {
                    setApprovalStatus('Awaiting Approval');
                    setApprovalColor('danger');
                    if (userReference.email != null &&
                        (/@sccmo.org\s*$/.test(userReference.email) ||
                            /@modot.mo.gov\s*$/.test(userReference.email) ||
                            /@ewgateway.org\s*$/.test(userReference.email) ||
                            /@metrostlouis.org\s*$/.test(userReference.email))) {
                        setApprovalStatus('Verified');
                        setApprovalColor('success');
                        userReference.verified = true;
                        firebaseService.updateUser(userReference);
                    }
                }
            });
        }
    }, [user]);

    return (
        <IonPage>
            <IonContent color="dark" className="content">
                <IonToolbar className="login-container" color="dark">
                    <div className="card-container-registration">
                        <IonCard className="card-registration">
                            <IonCardHeader className="verification-container">Email Verification <IonIcon color="success" md={checkmarkCircleSharp} ios={checkmarkCircleOutline} /></IonCardHeader>
                            <IonCardContent className="ion-text-center">
                                { user?.emailVerified && approvalStatus !== 'Verified' &&<IonLabel>An email has been sent to {user?.email}. <br /> Please follow the link to verify your email address. Once verified, you must wait for an administrator to approve your access to the application.</IonLabel>}
                                <br /><br />
                                <IonLabel color={approvalColor}>Approval Status: {approvalStatus}</IonLabel>
                                {!resent && <div className="button-container">
                                    { (!user?.emailVerified || approvalStatus !== 'Verified') && <div>
                                        { !user?.emailVerified && <IonLabel>Can&apos;t find the email?</IonLabel>}
                                    </div>}
                                    { user?.emailVerified && approvalStatus !== 'Verified' && <IonLabel>Thank you for verifying your email. Please allow 24 - 48 hours for administrator approval.</IonLabel>}
                                    { user?.emailVerified && approvalStatus === 'Verified' && <IonLabel>You&apos;ve been approved, click to go to the dashboard!</IonLabel>}
                                    { !user?.emailVerified && <IonButton color="pink" onClick={resend}>Resend</IonButton>}<br /><br />
                                    <div className="route-buttons">
                                        <IonButton color="pink" className="route-button" onClick={back}><IonIcon md={arrowBackSharp} ios={arrowBackOutline} />&nbsp;Back to Login</IonButton>
                                        {(user?.emailVerified && approvalStatus === 'Verified') && <IonButton color="pink" className="route-button" onClick={toDashboard}>
                                            Go to the Dashboard&nbsp;
                                            <IonIcon md={arrowForwardSharp} ios={arrowForwardOutline} />
                                        </IonButton>}
                                    </div>
                                </div>}
                                {resent && <div className="button-container">
                                    <IonLabel>The email has been resent.</IonLabel>
                                    <div className="error-message">
                                        { error }
                                    </div>
                                </div>}
                            </IonCardContent>
                        </IonCard>
                    </div>
                </IonToolbar>
            </IonContent>

            <IonFooter className="ion-no-border">
                <IonToolbar color="dark">
                    <IonLabel slot="end">@TITAN 2021 All rights reserved.</IonLabel>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    );
};

export default EmailVerification;
