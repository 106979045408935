import * as React from 'react';
import {IonButton, IonCard, IonItem, IonItemDivider, IonLabel, IonList} from '@ionic/react';
import {useState} from 'react';
import './AdminSettings.css';
import Loading from '../Loading/Loading';
import firebaseService from '../../services/firebaseService';
import AccountInfo from './AccountInfo';
import {User} from '../../interfaces/User';
import AdminTable from './AdminTable';

interface FormattedUser extends User {
    privilege: string;
    locationStatus: string;
}

const AdminSettings: React.FC = () => {
    const [user, setUser] = useState(new User());
    const [loading, setLoading] = useState(false);
    const [appliedUsers, setAppliedUsers] = useState([] as User[]);
    const [unverifiedUsers, setUnverifiedUsers] = useState([] as User[]);

    function search(query: FormattedUser) {
        if (query != null && query.email != null) {
            setLoading(true);
            const findUser = firebaseService.getUserByEmail(query.email);
            if (findUser != null) {
                findUser.then(docs => {
                    if (docs.docs.length > 0) {
                        const data = docs.docs[0].data();
                        if (data != null) {
                            setUser(data as User);
                        }
                    }
                    setLoading(false);
                }, () => {
                    setLoading(false);
                });
            }
        } else {
            setUser(new User());
        }
    }

    function lookup(user: User) {
        if (user && user.email) {
            setUser(user);
        }
    }

    React.useEffect(() => {
        firebaseService.getUnverifiedUsers().onSnapshot(docs => {
            const foundUsers: User[] = [];
            docs.forEach(doc => {
                const data = doc.data();
                if (data != null) {
                    foundUsers.push(data as User);
                }
            });
            setUnverifiedUsers(foundUsers);
        });
        firebaseService.getAppliedUsers().onSnapshot(docs => {
            const foundUsers: User[] = [];
            docs.forEach(doc => {
                const data = doc.data();
                if (data != null) {
                    foundUsers.push(data as User);
                }
            });
            setAppliedUsers(foundUsers);
        });
    }, []);
    return(
        <IonCard className="info-item  space-between">
            <IonItemDivider className="div-title" color="light" sticky={true}>
                <IonLabel>Administrator Settings</IonLabel>
            </IonItemDivider>
            {/*<div className="search">*/}
            {/*    <IonItem>*/}
            {/*        /!* Object needs to be of type any here, since the alternative is a custom made Ionic type*!/*/}
            {/*        /!* eslint-disable-next-line @typescript-eslint/no-explicit-any *!/*/}
            {/*        <IonInput placeholder="Lookup User by Email" value={username} onIonChange={(e: any) => setUsername(e.target.value)} />*/}
            {/*    </IonItem>*/}
            {/*    <IonButton color="pink" onClick={search}>Search</IonButton>*/}
            {/*</div>*/}
            <div>
                { user.uid !== '' && <AccountInfo userObject={user} editable={true} />}
            </div>
            <div className="table">
                <AdminTable userSelect={(user) => search(user)} />
            </div>
            <div>
                {unverifiedUsers.length > 0 && <IonList>
                    <IonItem lines="none" className="div-subtitle space-between">
                        <IonLabel>Users Awaiting Verification</IonLabel>
                    </IonItem>
                    {unverifiedUsers.map((user, index) => {
                        return(
                            <IonItem key={index}>
                                <div className="applied-user">
                                    <div>Name: {user.displayName}</div>
                                    <div>Email: {user.email}</div>
                                    <div>Organization: {user.agency}</div>
                                    <IonButton color="pink" onClick={() => {lookup(user)}}>Look Up</IonButton>
                                </div>
                            </IonItem>
                        );
                    })}
                </IonList>}
            </div>
            <div>
                {appliedUsers.length > 0 && <IonList>
                    <IonItem lines="none" className="div-subtitle space-between">
                        <IonLabel>Applications for Administrator</IonLabel>
                    </IonItem>
                    {appliedUsers.map((user, index) => {
                        return(
                            <IonItem key={index}>
                                <div className="applied-user">
                                    <div>Name: {user.displayName}</div>
                                    <div>Email: {user.email}</div>
                                    <div>Organization: {user.agency}</div>
                                    <IonButton color="pink" onClick={() => {lookup(user)}}>Look Up</IonButton>
                                </div>
                            </IonItem>
                        );
                    })}
                </IonList>}
            </div>
            { loading && <Loading />}
        </IonCard>
    );
};

export default AdminSettings;
