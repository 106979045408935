import * as React from 'react';
import {useState} from 'react';

import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent, IonFooter,
    IonHeader, IonIcon, IonImg, IonInput,
    IonItem, IonLabel, IonMenuButton,
    IonPage,
    IonToolbar, useIonViewWillLeave
} from '@ionic/react';
import { fingerPrintOutline,
    fingerPrintSharp,
    personAddOutline,
    personAddSharp,
    mailOutline,
    mailSharp,
} from 'ionicons/icons';

import './Login.css';
import {resetPassword} from '../../firebaseConfig';
import RIDSIR from '../../assets/icon/favicon.png';


const ForgotPassword: React.FC = () => {
    const [username, setUsername] = useState('');
    const [sent, setSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [mobile, setMobile] = useState(window.innerWidth < 1030);
    window.addEventListener('resize', resize);
    function resize(event: UIEvent) {
        const window = event.currentTarget as Window;
        setMobile(window.innerWidth < 1030);
    }

    function send() {
        setErrorMessage('');
        resetPassword(username).then(() => {
            setSent(true);
        }).catch((err) => {
            setErrorMessage(err.message);
        });
    }

    useIonViewWillLeave(() => {
        setUsername('');
        setErrorMessage('');
    });

    return (
        <IonPage>
            <IonContent color="dark">
                <IonHeader className="ion-no-border">
                        <IonToolbar color="dark" className="header">
                            <IonItem color="dark" slot="start" lines="none"><IonImg className="ridsi-icon" src={RIDSIR} /> &nbsp; Login Page</IonItem>
                            {!mobile && <IonItem button={true} color="dark" slot="end" lines="none" routerLink="/Login">
                                <IonIcon ios={fingerPrintOutline} md={fingerPrintSharp} />
                                &nbsp;
                                Login
                            </IonItem>}
                            {!mobile && <IonItem button={true} color="dark" slot="end" lines="none" routerLink="/Registration">
                                <IonIcon ios={personAddOutline} md={personAddSharp} />
                                &nbsp;
                                Register
                            </IonItem>}
                            {mobile && <IonItem color="dark" slot="end" lines="none">
                                <IonMenuButton color="light" menu="login" />
                            </IonItem>}
                        </IonToolbar>
                    </IonHeader>

                <IonToolbar className="login-container" color="dark">
                
                    <div className="login-card-container">
                        <IonCard className="reset-inner-card" color="pink">
                            <IonCardContent>
                                <IonItem className="ion-text-center" color="pink" lines="none">
                                    <IonLabel className="login-label">Reset Password</IonLabel>
                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                        <IonCard className="reset-card">
                            <IonCardContent>
                                {!sent && <div className="form center">
                                    <IonLabel>Enter the email address associated with your account</IonLabel>
                                    <IonItem className="formItem">
                                        <IonLabel position="floating">Email</IonLabel>
                                        {/* Object needs to be of type any here, since the alternative is a custom made Ionic type*/}
                                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                        <IonInput value={username} onIonChange={(e: any) => setUsername(e.target.value)} />
                                        <IonIcon ios={mailOutline} md={mailSharp} slot="end" />
                                    </IonItem>
                                    <div className="error-message">
                                        { errorMessage }
                                    </div>
                                    <IonItem button={false} className="ion-text-center" lines="none">
                                        <IonLabel>
                                            <IonButton color="pink" onClick={send}>Reset Password</IonButton>
                                        </IonLabel>
                                    </IonItem>
                                    <IonItem button={false} className="ion-text-center" lines="none">
                                        <IonLabel>
                                            <IonButton color="pink" href="/login">Back to Login</IonButton>
                                        </IonLabel>
                                    </IonItem>
                                </div>}
                                {sent && <div className="center">
                                    <IonLabel>A link to reset your password has been sent to {username}</IonLabel>
                                    <IonItem button={false} className="ion-text-center" lines="none">
                                        <IonLabel>
                                            <IonButton color="pink" href="/login">Back to Login</IonButton>
                                        </IonLabel>
                                    </IonItem>
                                </div>}
                            </IonCardContent>
                        </IonCard>
                    </div>
                </IonToolbar>
            </IonContent>
            
            <IonFooter className="ion-no-border">
                <IonToolbar color="dark">
                    <IonLabel slot="end">@TITAN 2021 All rights reserved.</IonLabel>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    );                    
};

export default ForgotPassword;
