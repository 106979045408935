import {IonContent, IonPage, IonSpinner} from '@ionic/react';
import * as React from 'react';
import './Loading.css';

const Loading: React.FC = () => {
    return (
        <IonPage>
            <IonContent color="dark">
                <div className="loading-center">
                    <IonSpinner color="light" className="ion-spinner" name="crescent" />
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Loading;
