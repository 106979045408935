class FileDoc {
    name: string;
    description: string;
    tags: {name: string, value: string}[] ;
    location: string;
    data?: File;

    constructor(name: string,
                description: string,
                tags: {name: string, value: string}[],
                location: string,
                data?: File) {
        this.name = name;
        this.description = description;
        this.tags = tags;
        this.location = location;
        this.data = data;
    }
}

export default FileDoc;
