import * as React from 'react';
import './Settings.css';

import {
    IonCard,
    IonItem,
    IonItemDivider,
    IonLabel, IonText, IonToast,
    IonToggle
} from "@ionic/react";
import {useEffect, useState} from 'react';
import {Geolocation} from '@capacitor/geolocation';
import {Device} from '@capacitor/device';

const LocationSettings: React.FC = () => {
    const [checked, setChecked] = useState(false);
    const [locationResponse, setLocationResponse] = useState(false);
    const [locationError, setLocationError] = useState(false);

    useEffect(() => {
        Device.getInfo().then(device => {
            if (device.operatingSystem === 'ios' || device.operatingSystem === 'android') {
                Geolocation.checkPermissions().then(perm => {
                    if (perm.location !== 'granted') {
                        Geolocation.requestPermissions().then(res => {
                            if (res.location === 'granted') {
                                setChecked(true);
                            } else {
                                setChecked(false);
                            }
                        })
                    } else {
                        setChecked(true);
                    }
                });
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(() => {
                        setChecked(true);
                    });
                }
            }
        });
    }, []);

    return (
        <IonCard className="info-item space-between">
            <IonItemDivider className="div-title" color="light" sticky={true}>
                <IonLabel>Location Preferences</IonLabel>
            </IonItemDivider>
            <IonItem lines="none">
                <IonText>Allow the RIDSI Application to store and use your location data for site information (managed by browser or device settings).</IonText>
                <IonToggle color="pink" slot="end" checked={checked} disabled />
            </IonItem>
            <IonToast
                isOpen={locationResponse}
                onDidDismiss={() => setLocationResponse(false)}
                color="light"
                message="User privilege has been updated."
                duration={10000}
                buttons={[{
                    text: 'Ok',
                    side: 'end',
                    role: 'cancel'
                }]}
            />
            <IonToast
                isOpen={locationError}
                onDidDismiss={() => setLocationError(false)}
                color="danger"
                message="Unable to update user privilege."
                cssClass="toast-error"
                duration={10000}
            />
        </IonCard>
    );
};

export default LocationSettings;
