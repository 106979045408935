import * as React from 'react';
import './NotificationBadge.css';
import {Notification} from '../../interfaces/Notification';

export interface NotificationProps extends RouteProps {
    notification: Notification;
}

import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCol,
    IonGrid,
    IonIcon,
    IonLabel,
    IonRow,
    IonText,
    IonToast,
    IonToolbar
} from '@ionic/react';

import {
    timeOutline,
    closeOutline
} from "ionicons/icons";
import {RouteProps} from "react-router";

import firebaseService from "../../services/firebaseService";
import {useState} from "react";

const NotificationBadge: React.FC<NotificationProps> = (props:NotificationProps) => {
    const notification: Notification = props.notification;

    const [successfulDelete, setSuccessfulDelete] = useState(false);
    const [deleteError, setDeleteError] = useState(false);

    function deleteNotification(notificationId: string){
        {/*This has to be a collection reference in order to grab a doc, and the more general function has to be a query to use the where/orderBy.
            Feel free to try anything but that's what I've found.*/}
        firebaseService.getAllNotificationsCollection().doc(notificationId).delete().then(() => {
            setSuccessfulDelete(true);
        }).catch(() => {
            setDeleteError(true);
        });
    }

    return(
        <IonCard className="notification-card">
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <div className="outer-header">
                            <div className={notification.topic + ' notification-icon'}>
                                <IonIcon color="primary-contrast" size="large" md={notification.logo}/>
                            </div>
                        </div>
                        <IonToolbar>
                            <IonLabel className="notification-header">{notification.title}</IonLabel>
                            <IonButtons slot="end">
                                <IonButton size="large" fill="clear" onClick={() => deleteNotification(notification.notificationId ? notification.notificationId : '')}>
                                    <IonIcon md={closeOutline} />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonCol>
                </IonRow>
                <IonRow className="top-border">
                    <IonCol>
                        <IonCardContent>
                            {notification.location && <IonCardSubtitle> Location: {notification.location}</IonCardSubtitle>}
                            <IonText>
                                {notification.description}
                            </IonText>
                        </IonCardContent>
                    </IonCol>
                </IonRow>
                <IonRow className="top-border">
                    <IonCol>
                        <IonLabel className="card-footer">
                            <IonIcon className="icon" md={timeOutline}/>
                            {notification.date}
                        </IonLabel>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonToast
                isOpen={successfulDelete}
                onDidDismiss={() => setSuccessfulDelete(false)}
                color="light"
                message="Notification Successfully Deleted."
                duration={5000}
            />
            <IonToast
                isOpen={deleteError}
                onDidDismiss={() => setDeleteError(false)}
                color="danger"
                message="Unable to Delete Notification."
                duration={5000}
            />
        </IonCard>
    );
};

export default NotificationBadge;
