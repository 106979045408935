
export function getPages(): { name: string, route: string}[] {
    return [
        {name: 'Analytics', route: '/Live/Analytics'},
        {name: 'Congestion', route: '/AppCenter/Congestion'},
        {name: 'Dashboard', route: '/Dashboard'},
        {name: 'Detector', route: '/Data/Detector'},
        {name: 'History', route: '/History'},
        {name: 'Incidents', route: '/Data/Incidents'},
        {name: 'Notifications', route: '/Notifications'},
        {name: 'Probe', route: '/Data/Probe'},
        {name: 'Profile', route: '/Profile'},
        {name: 'RIDSI', route: '/Tutorials'},
        {name: 'Safety', route: '/AppCenter/Safety'},
        {name: 'Tutorials', route: '/Tutorials'},
        {name: 'Visualization', route: '/Live/Visualization'},
        {name: 'Waze', route: '/Data/Waze'}
    ]
}
