import firebase from "firebase";

export class Notification {
    title: string;
    logo: string;
    topic: string;
    description: string;
    location?: string | null;
    date: firebase.firestore.Timestamp;
    notificationId?: string | null;

    constructor(title: string,
                logo: string,
                topic: string,
                description: string,
                date: firebase.firestore.Timestamp,
                location?: string | null,
                notificationId?: string | null) {
        this.title = title;
        this.logo = logo;
        this.topic = topic;
        this.description = description;
        this.date = date;
        this.location = location ? location : null;
        this.notificationId = notificationId ? notificationId : null;
    }
}
