import * as React from 'react';
import './Notifications.css';
import {Notification} from "../../interfaces/Notification";
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonLabel,
    IonPage,
    IonRouterLink,
    IonText
} from '@ionic/react';

import Header from '../../components/Header/Header';

import Loading from '../../components/Loading/Loading';
import NotificationBadge from "../../components/NotificationBadge/NotificationsBadge";
import {useEffect, useState} from "react";
import firebaseService from "../../services/firebaseService";
import tourService from '../../services/tourService';
import {useHistory} from 'react-router';
import Tour from 'reactour';
import iconService from '../../services/iconService';

const Notifications: React.FC = () => {
    const [notificationData, setNotificationData] = useState<Notification[]>([]);
    const [showLoading, setShowLoading] = useState(true);
    const steps = tourService.getStepsFor('Notifications');
    const isTour = tourService.StartTour();
    const history = useHistory();

    useEffect(() => {
        setShowLoading(true);
        firebaseService.getNotifications().onSnapshot(snapshot => {
            const notificationDocs: Notification[] = [];
            snapshot.docs.forEach(doc => {
                const data = doc.data();
                const notificationId = doc.id;
                data.logo = iconService.getIcon(data.logo);
                notificationDocs.push(new Notification(data.title, data.logo, data.topic, data.description, data.date, data.location, notificationId));
            });
            setNotificationData(notificationDocs);
            setShowLoading(false);
        });
        setShowLoading(false);
    },[]);

    return (
        <IonPage>
            <Header title="Notifications Center"/>
            <IonContent fullscreen color="light">
                <div className="notification-page-card">
                    <IonCard className="card-header-container">
                        <IonCardHeader>
                            <IonCardTitle className="card-header-title-box notification-title-coloring first-step">Notification Center</IonCardTitle>
                        </IonCardHeader>
                    </IonCard>

                    {(notificationData.length === 0) && <div>
                        <div className="no-notification-text">
                            <IonLabel className="no-notification-label">No New Notifications</IonLabel>
                            <IonText>Configure Notifications in <IonRouterLink routerLink="/Profile" routerDirection="none">Settings</IonRouterLink></IonText>
                        </div>
                    </div>}

                    {(notificationData.length > 0) && <div>
                        {notificationData.map((notification, index) => {
                            return(
                                <NotificationBadge key={index} notification={notification}/>
                            );
                        })}
                    </div>}
                </div>
                {showLoading && <Loading/>}
            </IonContent>
            <Tour
                steps={steps}
                isOpen={isTour}
                accentColor='black'
                onRequestClose={() => {tourService.GoBack(history)}}/>
        </IonPage>
    );
};

export default Notifications;
