import {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonCardHeader,
    IonCardTitle,
    IonCard,
    IonLabel,
    IonItemDivider,
    IonButton,
    useIonViewWillEnter,
    IonCardContent,
    IonToast,
    IonSearchbar
} from '@ionic/react';

import * as React from 'react';
import Header from '../../components/Header/Header';
import Loading from "../../components/Loading/Loading";
import Footer from "../../components/Footer/Footer";

import {useEffect, useState} from 'react';
import firebaseService from '../../services/firebaseService';
import {Camera} from '../../interfaces/Camera';
import tourService from "../../services/tourService";
import Tour from "reactour";
import { Device } from '@capacitor/device';
import './Live.css';
import Map from '../../components/Maps/Map'

import CctvPlayer from '../../components/CctvPlayer/CctvPlayer';
import {useHistory} from "react-router";
import {getCurrentUser} from '../../firebaseConfig';

const Live: React.FC = () => {
    const [displayedCCTVs, setDisplayedCCTVs] = useState<Camera[]>([]);
    const [activeCCTV, setActiveCCTV] = useState<Camera>(new Camera());
    const [isIOS, setIsIOS] = useState(false);
    const [cameras, setCameras] = useState<Camera[]>([]);
    const [allCameras, setAllCameras] = useState<Camera[]>(cameras);
    const [showLoading, setShowLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        firebaseService.getCams().get().then((docs) => {
            const foundCameras: Camera[] = [];
            docs.forEach(doc => {
                const cam = doc.data() as Camera
                if( cam.latitude != 0 && cam.longitude != 0 && Number.isFinite(cam.latitude)&& Number.isFinite(cam.longitude))
                    foundCameras.push(cam);
            });
            setCameras(foundCameras);
            setAllCameras(foundCameras);
        });

        Device.getInfo().then((info) => {
            setIsIOS(info.operatingSystem === 'ios');
        });
    }, []);

    const setNewCamera = (id: number) => {
        const data = cameras.find(camera => camera.id === id);
        const duplicateCamera = displayedCCTVs.find(camera => camera.id === id);
        if (data && !duplicateCamera) {
            setDisplayedCCTVs(camera => [...camera, data as Camera]);
            setActiveCCTV(data as Camera)
        }
    };

    const closeCurrentCamera = (id: number) => {
        setDisplayedCCTVs(displayedCCTVs.filter(camera => camera.id !== id));
    };

    const cameraError = (error: string, id: number) => {
        setErrorResponse(true);
        const newDisplayedCameras = displayedCCTVs.filter(camera => camera.id !== id);
        setDisplayedCCTVs(newDisplayedCameras);
        newDisplayedCameras.length > 0 ? setActiveCCTV(newDisplayedCameras[newDisplayedCameras.length - 1]) : setActiveCCTV(new Camera());
    };

    function search(e: CustomEvent) {
        const searchText = e.detail?.value;
        setSearchValue(searchText);
        let filteredCameras = cameras;
        if (searchText !== '') {
            filteredCameras = filteredCameras.filter((camera) => {
                return camera.description.toLowerCase().includes(searchText.toLowerCase());
            });
            setCameras(filteredCameras);
        } else {
            setCameras(allCameras);
        }
    }

    function clearAllSelectedCameras(){
        setDisplayedCCTVs([]);
    }

    useIonViewWillEnter( () => {
        setShowLoading(true);
    });
    const steps = tourService.getStepsFor('Live');
    const isTour = tourService.StartTour();
    const history = useHistory();

    return (
        <IonPage>
            <Header title={"CCTV"} />
            <IonContent fullscreen color="light">
                <IonCard className="card-header-container card-container-padding">
                    <IonCardHeader>
                        <IonCardTitle className="card-header-title-box live-title-coloring">Live CCTV Footage</IonCardTitle>
                    </IonCardHeader>
                    <IonGrid>
                        {getCurrentUser()?.admin && <IonRow>
                            <IonCol>
                                <div className="submission-container">
                                <IonCard className="submission-card">
                                    <IonCardHeader>Upload a New List of Cameras</IonCardHeader>
                                    <IonCardContent>
                                        <div className="submit-cameras">
                                            <input className="submission-input" type="file" accept=".csv" />
                                            <IonButton color="pink">Submit</IonButton>
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                                </div>
                            </IonCol>
                        </IonRow>}
                        <IonRow>
                            <IonCol>
                                <div className="map-container live-recenter-alignment first-step">
                                    <IonSearchbar value={searchValue} onIonChange={search} />
                                    {showLoading && <IonCard className="loading">
                                        <IonCardContent>
                                            Please Wait as the Map Loads - This May Take a Moment.
                                        </IonCardContent>
                                    </IonCard>}
                                    <IonCard>
                                        <IonItemDivider className="cctv-div-title" color="light">
                                            <IonLabel>CCTV Live Map</IonLabel>
                                        </IonItemDivider>
                                        <Map height="50em" traffic={true} location={true} cctv={true} cameras={cameras} stateOutline={true} setId={setNewCamera} setShowLoading={setShowLoading}/>
                                        {showLoading && <Loading/>}
                                    </IonCard>
                                </div>
                                { (displayedCCTVs.length == 0) && <IonLabel>Click on a map marker to view live CCTV footage</IonLabel> }
                            </IonCol>
                        </IonRow>
                        { (displayedCCTVs.length > 0) && <IonCard>
                            <IonCardHeader color="light" className="selector-div second-step">
                                <IonLabel className="cctv-div-title">Selected Live CCTVs</IonLabel>
                                <IonButton onClick={clearAllSelectedCameras} color="pink">Clear All</IonButton>
                            </IonCardHeader>
                            <IonCardContent>
                                <div className="camera-container">
                                    <div className="camera-index">
                                        { displayedCCTVs.map((currentCCTV, index) => {
                                            return(
                                                <CctvPlayer key={index} onClick={() => {setActiveCCTV(currentCCTV)}} cctv={currentCCTV} isIOS={isIOS} error={cameraError} closeCCTV={closeCurrentCamera}/>
                                            );
                                        }) }
                                    </div>
                                    <div className="main-camera">
                                        {activeCCTV.id !== -1 && <CctvPlayer cctv={activeCCTV} isIOS={isIOS} error={cameraError} closeCCTV={null} />}
                                    </div>
                                </div>
                            </IonCardContent>
                        </IonCard>}

                    </IonGrid>
                </IonCard>
                <Footer/>
            </IonContent>
            <IonToast
                isOpen={errorResponse}
                onDidDismiss={() => setErrorResponse(false)}
                color="danger"
                message="Could not connect to camera."
                cssClass="toast-error"
                duration={10000}
            />
            <Tour
                steps={steps}
                isOpen={isTour}
                accentColor='black'
                onRequestClose={() => {tourService.GoBack(history)}}/>
        </IonPage>
    )
};

export default Live;
