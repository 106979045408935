export const roads = [
    {name: "routex", value: "routex"}, 
    {name: "I-70", value: "I-70"}, 
    {name: "US-61", value: "US-61"}, 
    {name: "I-44", value: "I-44"}, {name: "MO-100", value: "MO-100"}, {
    name: "US-50",
    value: "US-50"
}, {name: "I-270", value: "I-270"}, {name: "I-55", value: "I-55"}, {
    name: "US-67",
    value: "US-67"
}, {name: "US-63", value: "US-63"}, {name: "RT D", value: "RT D"}, {
    name: "MO-30",
    value: "MO-30"
}, {name: "I-64", value: "I-64"}, {name: "MO-340", value: "MO-340"}, {
    name: "MO-47",
    value: "MO-47"
}, {name: "US-65", value: "US-65"}, {name: "US-160", value: "US-160"}, {
    name: "CST KINGSHIGHWAY BLVD",
    value: "CST KINGSHIGHWAY BLVD"
}, {name: "US-60", value: "US-60"}, {name: "MO-7", value: "MO-7"}, {
    name: "MO-21",
    value: "MO-21"
}, {name: "MO-13", value: "MO-13"}, {name: "US-54", value: "US-54"}, {
    name: "MO-5",
    value: "MO-5"
}, {name: "I-29", value: "I-29"}, {name: "CST GRAND BLVD", value: "CST GRAND BLVD"}, {
    name: "I-435",
    value: "I-435"
}, {name: "US-169", value: "US-169"}, {name: "MO-180", value: "MO-180"}, {
    name: "RT K",
    value: "RT K"
}, {name: "CSTEST FLORISSANT AVE", value: "CSTEST FLORISSANT AVE"}, {
    name: "MO-32",
    value: "MO-32"
}, {name: "MO-94", value: "MO-94"}, {name: "MO-141", value: "MO-141"}, {
    name: "CST MAINT",
    value: "CST MAINT"
}, {name: "I-35", value: "I-35"}, {name: "US-24", value: "US-24"}, {
    name: "MO-291",
    value: "MO-291"
}, {name: "LP 49", value: "LP 49"}, {name: "RT H", value: "RT H"}, {
    name: "I-49",
    value: "I-49"
}, {name: "BU 60", value: "BU 60"}, {name: "LP 44", value: "LP 44"}, {
    name: "BU 65",
    value: "BU 65"
}, {name: "MO-366", value: "MO-366"}, {name: "MO-76", value: "MO-76"}, {
    name: "RT B",
    value: "RT B"
}, {name: "MO-58", value: "MO-58"}, {name: "RT AC", value: "RT AC"}, {
    name: "MO-231",
    value: "MO-231"
}, {name: "MO-115", value: "MO-115"}, {name: "I-170", value: "I-170"}, {
    name: "US-40",
    value: "US-40"
}, {name: "RT", value: "RT"}, {name: "US-71", value: "US-71"}, {
    name: "MO-14",
    value: "MO-14"
}, {name: "CST JEFFERSON AVE", value: "CST JEFFERSON AVE"}, {
    name: "S OR 70",
    value: "S OR 70"
}, {name: "CST CLAYTON RD", value: "CST CLAYTON RD"}, {
    name: "CST BROADWAY",
    value: "CST BROADWAY"
}, {name: "RT A", value: "RT A"}, {name: "CST BLUE RIDGE BLVD", value: "CST BLUE RIDGE BLVD"}, {
    name: "MO-6",
    value: "MO-6"
}, {name: "CSTATIONAL AVE", value: "CSTATIONAL AVE"}, {name: "MO-19", value: "MO-19"}, {
    name: "US-69",
    value: "US-69"
}, {name: "MO-43", value: "MO-43"}, {
    name: "CST CAMPBELL AVE",
    value: "CST CAMPBELL AVE"
}, {name: "CST BATTLEFIELD RD", value: "CST BATTLEFIELD RD"}, {name: "RT M", value: "RT M"}, {
    name: "I-470",
    value: "I-470"
}, {name: "CST HAMPTON AVE", value: "CST HAMPTON AVE"}, {
    name: "CST TRUMAN RD",
    value: "CST TRUMAN RD"
}, {name: "CST OAK TRFY", value: "CST OAK TRFY"}, {name: "MO-66", value: "MO-66"}, {
    name: "MO-17",
    value: "MO-17"
}, {name: "RT U", value: "RT U"}, {name: "RT C", value: "RT C"}, {
    name: "BU 50",
    value: "BU 50"
}, {name: "MO-78", value: "MO-78"}, {
    name: "CRD BIG BEND RD",
    value: "CRD BIG BEND RD"
}, {name: "CSTORNALL RD", value: "CSTORNALL RD"}, {
    name: "CST GOODFELLOW BLVD",
    value: "CST GOODFELLOW BLVD"
}, {name: "CST MEXICO RD", value: "CST MEXICO RD"}, {
    name: "CST TROOST AVE",
    value: "CST TROOST AVE"
}, {name: "US-36", value: "US-36"}, {name: "MO-8", value: "MO-8"}, {
    name: "CST INDEPENDENCE AVE",
    value: "CST INDEPENDENCE AVE"
}, {name: "CST DELMAR BLVD", value: "CST DELMAR BLVD"}, {
    name: "MO-744",
    value: "MO-744"
}, {name: "CST PROSPECT AVE", value: "CST PROSPECT AVE"}, {name: "MO-25", value: "MO-25"}, {
    name: "BU 63",
    value: "BU 63"
}, {name: "RT FF", value: "RT FF"}, {name: "MO-52", value: "MO-52"}, {
    name: "MO-109",
    value: "MO-109"
}, {name: "CST HANLEY RD", value: "CST HANLEY RD"}, {
    name: "MO-92",
    value: "MO-92"
}, {name: "CST BIG BEND BLVD", value: "CST BIG BEND BLVD"}, {name: "RT Z", value: "RT Z"}, {
    name: "RT F",
    value: "RT F"
}, {name: "CST GREGORY BLVD", value: "CST GREGORY BLVD"}, {name: "MO-72", value: "MO-72"}, {
    name: "RT Y",
    value: "RT Y"
}, {name: "CST MID RIVERS MALL DR", value: "CST MID RIVERS MALL DR"}, {
    name: "BU 13",
    value: "BU 13"
}, {name: "N OR 270", value: "N OR 270"}, {
    name: "CST UNION BLVD",
    value: "CST UNION BLVD"
}, {name: "CST 39THT", value: "CST 39THT"}, {name: "MO-86", value: "MO-86"}, {
    name: "MO-1",
    value: "MO-1"
}, {name: "MO-267", value: "MO-267"}, {name: "MO-45", value: "MO-45"}, {
    name: "MO-152",
    value: "MO-152"
}, {name: "CSTUNSHINET", value: "CSTUNSHINET"}, {name: "MO-51", value: "MO-51"}, {
    name: "US-59",
    value: "US-59"
}, {name: "CSTOLAND RD", value: "CSTOLAND RD"}, {name: "RT T", value: "RT T"}, {
    name: "RT J",
    value: "RT J"
}, {name: "US-136", value: "US-136"}, {name: "MO-367", value: "MO-367"}, {
    name: "CST HOLMES RD",
    value: "CST HOLMES RD"
}, {name: "RT BB", value: "RT BB"}, {name: "MO-39", value: "MO-39"}, {
    name: "CST VANDEVENTER AVE",
    value: "CST VANDEVENTER AVE"
}, {name: "CST JENNINGSTATION RD", value: "CST JENNINGSTATION RD"}, {
    name: "CST CHAMBERS RD",
    value: "CST CHAMBERS RD"
}, {name: "RT P", value: "RT P"}, {name: "N OR 44", value: "N OR 44"}, {
    name: "CST 31STT",
    value: "CST 31STT"
}, {name: "MO-79", value: "MO-79"}, {name: "RT CC", value: "RT CC"}, {
    name: "CST 76 COUNTRY BLVD",
    value: "CST 76 COUNTRY BLVD"
}, {name: "MO-350", value: "MO-350"}, {name: "BU 54", value: "BU 54"}, {
    name: "CST BRENTWOOD BLVD",
    value: "CST BRENTWOOD BLVD"
}, {name: "CSTARD PKWY", value: "CSTARD PKWY"}, {name: "N OR 70", value: "N OR 70"}, {
    name: "MO-9",
    value: "MO-9"
}, {name: "US-62", value: "US-62"}, {name: "LP 29", value: "LP 29"}, {
    name: "CST RAYTOWN RD",
    value: "CST RAYTOWN RD"
}, {name: "CSTASHINGTON AVE", value: "CSTASHINGTON AVE"}, {
    name: "RT O",
    value: "RT O"
}, {name: "CSTKINKER BLVD", value: "CSTKINKER BLVD"}, {name: "MO-740", value: "MO-740"}, {
    name: "CST 63RDT",
    value: "CST 63RDT"
}, {name: "MO-763", value: "MO-763"}, {name: "CSTTERLING AVE", value: "CSTTERLING AVE"}, {
    name: "W OR 55",
    value: "W OR 55"
}, {name: "CST BROADWAYT", value: "CST BROADWAYT"}
];
