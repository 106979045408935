
export const supporttype = [
    {name: 'PROFILE', value: 'PROFILE'},
    {name: 'DASHBOARD', value: 'DASHBOARD'},
    {name: 'DATA DOWNLOAD - PROBE', value: 'DATA DOWNLOAD - PROBE'},
    {name: 'DATA DOWNLOAD - INCIDENTS', value: 'DATA DOWNLOAD - INCIDENTS'},
    {name: 'DATA DOWNLOAD - DETECTOR', value: 'DATA DOWNLOAD - DETECTOR'},
    {name: 'DATA DOWNLOAD - WAZE INCIDENT', value: 'DATA DOWNLOAD - WAZE INCIDENT'},
    {name: 'DATA DOWNLOAD - WAZE JAM', value: 'DATA DOWNLOAD - WAZE JAM'},
    {name: 'DATA DOWNLOAD - COUNTS', value: 'DATA DOWNLOAD - COUNTS'},
    {name: 'DATA DOWNLOAD - HISTORY', value: 'DATA DOWNLOAD - HISTORY'},
    {name: 'DATA UPLOAD', value: 'DATA UPLOAD'},
    {name: 'APP CENTER - SAFETY', value: 'APP CENTER - SAFETY'},
    {name: 'APP CENTER - PROBE', value: 'APP CENTER - PROBE'},
    {name: 'APP CENTER - SCC COUNTS', value: 'APP CENTER - SCC COUNTS'},
    {name: 'APP CENTER - TRANSCORE ANALYTICS', value: 'APP CENTER - TRANSCORE ANALYTICS'},
    {name: 'APP CENTER - TRANSCORE ANALYTICS', value: 'APP CENTER - TRANSCORE ANALYTICS'},
    {name: 'APP CENTER - TRAFFIC JAMS', value: 'APP CENTER - TRAFFIC JAMS'},
    {name: 'APP CENTER - TRAFFIC COUNTS', value: 'APP CENTER - TRAFFIC COUNTS'},
    {name: 'APP CENTER - WAZE ANALYTICS', value: 'APP CENTER - WAZE ANALYTICS'},
    {name: 'APP CENTER - CRASHES', value: 'APP CENTER - CRASHES'},
    {name: 'APP CENTER - CLEARANCE TIME', value: 'APP CENTER - CLEARANCE TIME'},
    {name: 'APP CENTER - CONGESTION', value: 'APP CENTER - CONGESTION'},
    {name: 'APP CENTER - TRAVEL TIME', value: 'APP CENTER - TRAVEL TIME'},
    {name: 'APP CENTER - INTEGRATED', value: 'APP CENTER - INTEGRATED'},
    {name: 'APP CENTER - INTEGRATED', value: 'APP CENTER - INTEGRATED'},
    {name: 'LIVE - VISUALIZATION', value: 'LIVE - VISUALIZATION'},
    {name: 'LIVE - ANALYTICS', value: 'LIVE - ANALYTICS'},
    {name: 'LIVE - CCTV', value: 'LIVE - CCTV'},
    {name: 'TUTORIALS', value: 'TUTORIALS'},
    {name: 'SUPPORT', value: 'SUPPORT'},
    {name: 'ABOUT RIDSI', value: 'ABOUT RIDSI'},
];

