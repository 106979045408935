import { KeyValueData } from "../interfaces/KeyValueData";

export const organization:KeyValueData[] = [
    {name: 'SCC', value: 'SCC'},
    {name: 'MO DOT', value: 'MO DOT'},
    {name: 'EWG', value: 'EWG'},
    {name: 'OTHERS', value: 'OTHERS'},
    
];

