import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader, IonChip,
    IonContent, IonIcon,
    IonInput, IonItem, IonLabel,
    IonPage, IonProgressBar, IonTextarea,
    IonTitle, useIonToast
} from '@ionic/react';
import Header from '../../components/Header/Header';
import './Upload.css';
import Dropzone from 'react-dropzone';
import {closeOutline, closeSharp, documentOutline, documentSharp} from 'ionicons/icons';
import firebaseService from '../../services/firebaseService';
import firebase from 'firebase';
import Loading from '../../components/Loading/Loading';
import FileDoc from '../../interfaces/FileDoc';
import Select from 'react-dropdown-select';
import {tags} from '../../assets/tags';

const Upload: React.FC = () => {
    const [present, dismiss] = useIonToast();
    const [loading, setLoading] = useState(false);
    const [fileDocs, setFileDocs] = useState<FileDoc[]>([]);
    const [existingFiles, setExistingFiles] = useState<FileDoc[]>([]);
    const [selectedFile, setSelectedFile] = useState<FileDoc>(new FileDoc("", "", [{name: '', value: ''}], ""));
    const [progress, setProgress] = useState(0);

    function receiveFiles(acceptedFiles: File[]) {
        const processedFiles: FileDoc[] = [];
        acceptedFiles.forEach(file => {
            if (fileDocs.find(oldFile => oldFile.name === file.name)) {
                acceptedFiles = acceptedFiles.filter(newFile => newFile.name !== file.name);
            } else {
                processedFiles.push(new FileDoc(file.name, "", [], "", file));
            }
        });
        setFileDocs(fileDocs.concat(processedFiles));
    }

    function uploadFiles() {
        setLoading(true);
        let foundFiles: firebase.storage.Reference[] = [];
        firebaseService.getUploadedFiles().then(res => {
            setProgress(.2);
            foundFiles = res.items;
            fileDocs.forEach(file => {
                setProgress(.4);
                if (file.data) {
                    let i = 1;
                    while(foundFiles.find(upload => upload.name === file.name)) {
                        file.name = file.name + '('+ i + ')';
                        i++;
                    }
                    const newFile = new File([file.data], file.name, {type: file.data.type});
                    firebaseService.uploadFile(newFile).then(res => {
                        setProgress(.6);
                        if (res.state === 'success') {
                            res.ref.getDownloadURL().then(url => {
                                setProgress(.8);
                                file.location = url;
                                firebaseService.uploadFileDoc(file).then(() => {
                                    setProgress(1);
                                    present({
                                        buttons: [{text: 'Okay', handler: () => dismiss()}],
                                        message: 'File(s) successfully uploaded.',
                                        color: 'primary'
                                    });
                                });
                                setFileDocs([]);
                                setProgress(0);
                                setSelectedFile(new FileDoc("", "", [] , ""))
                            });
                        } else {
                            present({
                                buttons: [{text: 'Okay', handler: () => dismiss()}],
                                message: 'Failed to upload file: ' + file.name + '.',
                                color: 'danger'
                            });
                        }
                        setLoading(false);
                    }).catch(() => {
                        present({
                            buttons: [{text: 'Okay', handler: () => dismiss()}],
                            message: 'Failed to upload file: ' + file.name + '.',
                            color: 'danger'
                        });
                        setLoading(false);
                    });
                }
            });
        });
    }

    function removeFile(file: FileDoc) {
        setFileDocs(fileDocs.filter(doc => doc !== file));
        setTimeout(() => {
            setSelectedFile(new FileDoc("", "", [], ""));
        }, 100);
    }

    function addTitle(title: string | number) {
        title = title.toString();
        if (title != "") {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            selectedFile.name = title;
            setSelectedFile(selectedFile);
        }
    }

    function addDescription(description: string) {
        if (description != "") {
            selectedFile.description = description;
            setSelectedFile(selectedFile);
        }
    }
    function addTag(tags: {name: string, value: string}[]) {
        selectedFile.tags = tags;
        const holdValue = Object.assign({}, selectedFile);
        setSelectedFile(holdValue);
    }

    useEffect(() => {
        firebaseService.getUploadedReferences().onSnapshot(snap => {
            const foundFiles: FileDoc[] = [];
            snap.docs.forEach(doc => {
                foundFiles.push(doc.data() as FileDoc);
            });
            setExistingFiles(foundFiles);
        });
    }, []);

    return (
        <IonPage>
            <Header title="Data Upload" />
            <IonContent>
                <IonCard>
                    <IonCardHeader className="upload-head">
                        <IonTitle className="upload-title-mobile">Data Upload</IonTitle>
                        <div className="upload-title">Data to Upload</div>
                        <div className="upload-title">Files Uploaded</div>
                    </IonCardHeader>
                    <IonCardContent className={existingFiles.length > 0 ? "file-container" : "card-container"}>
                        {existingFiles.length > 0 && <IonCard className="uploaded-file-space overflow">
                            <IonCardContent>
                                {existingFiles.map(file => {
                                    return (
                                        <div className="uploaded-file" key={file.name}>
                                            <IonLabel><IonIcon md={documentSharp} ios={documentOutline} />&nbsp; {file.name}</IonLabel>
                                            <IonLabel>{file.description}</IonLabel>
                                            {file.tags?.map(tag => {
                                                return(<span key={tag.value}>#{tag.name}</span>)
                                            })}
                                        </div>
                                    );
                                })}
                            </IonCardContent>
                        </IonCard>}
                        {fileDocs.length > 0 && <IonCard className="file-space overflow">
                            <IonCardContent>
                                {fileDocs.map(file => {
                                    return (
                                        <div className="file" key={file.name} onClick={() => {setSelectedFile(file)}}>
                                            <IonIcon color="danger" className="file-remove" md={closeSharp} ios={closeOutline} onClick={() => {removeFile(file)}} />
                                            <span><IonIcon md={documentSharp} ios={documentOutline} />&nbsp; {file.name}</span>
                                            <span>{file.description}</span>
                                        </div>
                                    );
                                })}
                            </IonCardContent>
                        </IonCard>}
                        <IonCard className="file-card">
                            <IonCardContent>
                                <Dropzone onDrop={acceptedFiles => receiveFiles(acceptedFiles)}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div className="dropzone" {...getRootProps()}>
                                                <input {...getInputProps()} className="file-input" type="file" accept=".xls, .csv, .kml, .shp" />
                                                <p>Drag and drop files here, or click to select files</p>
                                                <p>Accepted file types are: .xls, .csv, .kml, or .shp</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                <div className="upload-container">
                                    {fileDocs.length > 0 &&<IonButton color="pink" onClick={uploadFiles}>
                                        Upload Files to RIDSI
                                    </IonButton>}
                                </div>
                                {loading && <Loading />}
                            </IonCardContent>
                            {loading && <IonItem color='danger'>Uploading file... This may take several minutes.</IonItem>}
                            {loading && <IonProgressBar value={progress} />}
                        </IonCard>
                    </IonCardContent>
                </IonCard>
                {selectedFile.name != "" && <IonCard>
                    <IonCardContent className="card-container">
                        <IonCard className="details-card">
                            <IonCardHeader>File {selectedFile.name} Details</IonCardHeader>
                            <IonCardContent>
                                <IonItem lines="none">
                                    <IonLabel position="stacked">Title: </IonLabel>
                                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                                    <IonInput value={selectedFile.name} onBlur={(e) => {addTitle(e.target.value!)}} />
                                </IonItem>
                                <IonItem lines="none">
                                    <IonLabel position="stacked">Description: </IonLabel>
                                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                                    <IonTextarea value={selectedFile.description} onBlur={(e) => {addDescription(e.target.value!)}} placeholder="Enter description here"  />
                                </IonItem>
                                <div className="dropdown-container">
                                    <IonLabel position="stacked">Add a Tag</IonLabel>
                                    <div className="dropdown-form-div">
                                        <Select
                                            className="select-width"
                                            options={tags}
                                            values={selectedFile.tags}
                                            multi
                                            labelField="name"
                                            valueField="value"
                                            onChange={(e) => {addTag(e)}}
                                        />
                                    </div>
                                </div>
                                <div className="tag-container">
                                    {selectedFile?.tags.map(tag => {
                                        return (
                                            <IonChip color="pink" key={tag.value}><IonLabel>{tag.name}</IonLabel></IonChip>
                                        );
                                    })}
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonCardContent>
                </IonCard>}
            </IonContent>
        </IonPage>
    );
};

export default Upload;
