import React,{useState} from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonIcon, IonAlert } from '@ionic/react';

import { CountyData } from "../../interfaces/CountyData";
// import ProgressBar from "@ramonak/react-progress-bar";
import "./FreewayCard.css"
import { green } from '@material-ui/core/colors';
// import { BarChart } from '@mui/x-charts/BarChart';
import { useHistory } from 'react-router-dom';
import { Doughnut, Bar } from 'react-chartjs-2';
import Chart from 'react-apexcharts'

interface GraphDataCardprops {
  content: any;
  crashList: CountyData[];
  newdata:any;
}


const option = { labels: ["Comedy",'out'] };
const series = [12,88]; //our data



  

const GraphDataCard: React.FC<GraphDataCardprops> =(props:GraphDataCardprops)=> {

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    if (props.content.source != "N/A") setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const history = useHistory();

  const handleOkay = () => {
    closeModal();  // Close the modal first
    history.push('AppCenter/TrafficCounts');  // Navigate to the desired path
  };

  const clearanceValues = props.newdata.roadAadt.map((day:any )=> Math.floor(day.lane_Volume));
  const dayNames = props.newdata.roadAadt.map((day:any) => day.name);   


  const new_data={
    labels:dayNames,
    datasets:[
        {
            label:'Freeway Counts',
            data:clearanceValues,
            backgroundColor:'tomato',

        }
    ]
  }

  const new_options={
    indexAxis: 'y' as const,
  }
  
  const bar_series = [
    {
      name: "Freeway Counts",
      data: clearanceValues,
    },
  ];
  const bar_options = {
    chart: {
      id: "simple-bar",
    },
    xaxis: {
      categories: dayNames,
    },
    plotOptions: {
        bar: {
          horizontal: true, //horizontal bar chart
        },
      },
  };

  const percent=(Number(props.newdata.Active));
    const remaining=100-percent;
    const data={
        
        datasets:[{
            label:'Poll',
            data:[percent,remaining],
            backgroundColor:['#EA7B26','antiquewhite'],
            borderColor:['#EA7B26','antiquewhite'],
            
        }]
    }
    
    const options={
        cutoutPercentage: 80,
        rotation:Math.PI,
        circumference: Math.PI,
        
    }
    
    const gaugeText={
        id:'gaugeText',
        beforeDatasetsDraw(chart:any,args:any,pluginOptions:any){
            const {ctx,chartArea:{top,bottom,left,right,width,height}}=chart;
    
            // console.log(chart.getDatasetMeta(0).data[0]._model)
            const xCenter=chart.getDatasetMeta(0).data[0]._model.x;
            const yCenter=chart.getDatasetMeta(0).data[0]._model.y;
    
            ctx.save();
            ctx.fillStyle='black';
            ctx.font='bold 60px sans-serif';
            ctx.textAlign='center';
            ctx.textBaseline='bottom'
            ctx.fillText(props.newdata.Active+'%',xCenter,yCenter)
        }
    
    }


    return(
  <>
    <IonCard className='freeway-main-container'>
            <IonCardTitle className="freeway-data-card-title">
              
              <div onClick={openModal} className="freeway-data-card-icon">
                <IonIcon  color="light" ios={props.content.ios} md={props.content.md}/>
              </div>
              <div className='freeway-header-title'>Freeway ADT</div>
            </IonCardTitle>
        
        <div className="freeway-gauge-container">
        {/* <Chart options={option} series={series} type="donut" width="250" height="230" /> */}
        <Doughnut data={data} options={options} plugins={[gaugeText]} ></Doughnut>

            
        </div>
          
        <IonCardTitle className='freeway-graph-title' style={{ color: 'black' }}>
                  Data Rate
        </IonCardTitle>
        
       <div className='freeway-bar-chart'>
            {/* <Chart options={bar_options} type="bar" series={bar_series} width="260" height="250" /> */}
            <Bar  data={new_data} height={220} ></Bar>
       </div>
        <div className='freeway-last-updated'>{props.content.updated}</div>
    </IonCard>

    <IonAlert
          isOpen={modalOpen}
          header={props.content.title}
          subHeader={"Source: " + props.content.source}
          message={props.content.description}
          buttons={[{text:"More Inforamtion", handler: handleOkay}]}
          onDidDismiss={closeModal}
          cssClass="bigger-alert"
        ></IonAlert>
    

  </>
   
    );
};
export default GraphDataCard;


