import * as React from 'react';
import GraphContainer from '../../components/Graphs/GraphContainter';
import DataCards from '../../components/Cards/DataCards';
import Map from '../../components/Maps/Map'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Chart from 'react-apexcharts'
import {
    IonContent,
    IonPage,
    IonCard,
    IonGrid,
    IonCol,
    IonRow,
    IonItem,
    IonList,
    IonLabel,
    IonIcon
} from '@ionic/react';

import {
 
    carSportOutline,
    carSportSharp,
    informationCircleOutline,
    informationCircleSharp,
    statsChartOutline,
    statsChartSharp,
    warningOutline,
    warningSharp,
  } from "ionicons/icons";
import './Dashboard.css';
import { globeOutline, options } from "ionicons/icons";
import Tour from "reactour";
import tourService from "../../services/tourService";
import {useHistory} from "react-router";
import {useEffect, useState} from 'react';
import firebaseService from '../../services/firebaseService';
import {DashboardData} from '../../interfaces/DashboardData'
import {CountyData} from '../../interfaces/CountyData';
import {setAuthToken} from '../../services/bigQueryService';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip } from 'chart.js';
import { Rotate90DegreesCcw } from '@material-ui/icons';

import GraphDataCard from '../../components/Cards/CrashesCard';
import ClearanceDataCard from '../../components/Cards/Clearancecard';
import FreewayDataCard from '../../components/Cards/FreewayCard';
import CongestionDataCard from '../../components/Cards/Congestioncard'
import WorkZoneDataCard from '../../components/Cards/Workzonescard';

const initialCounties: CountyData[] = [
    {
        name: "St. Louis (City)",
        crashes: 50,
    },
    {
        name: "St.Louis",
        crashes: 18,
    },
    {
        name: "Jefferson",
        crashes: 5,
    },
    {
        name: "St. Clair",
        crashes: 3,
    },
    {
        name: "St. Charles",
        crashes: 3,
    }
];

const Dashboard: React.FC = () => {
    const [dashboardData, setDashboardData] = useState<DashboardData>(new DashboardData());
    const [counties, setCounties] = useState<CountyData[]>(initialCounties);
    const steps = tourService.getStepsFor('Dashboard');
    const isTour = tourService.StartTour();
    const history = useHistory();
    const [lastUpdated, setLastUpdated] = useState(new Date().toLocaleString());

    const [newdashboard,setnewdashboard]=useState(null);
    const [newdatacards,setnewdatacards]=useState<any>([]);

    useEffect(() => {
        firebaseService.getAuthToken().then(doc => {
            setAuthToken(doc.data()?.token);
        });
        const unsubscribe = firebaseService.watchDashboardData().onSnapshot(doc => {
            const dashboardData: DashboardData = doc.docs[1].data() as DashboardData;
            const newdashboard: any =doc.docs[2].data();
            if (dashboardData) {
                setDashboardData(dashboardData);
                dashboardData.countyCrashes.sort((a, b) => a.crashes - b.crashes);
                setCounties(dashboardData.countyCrashes.reverse());
                setLastUpdated(new Date(dashboardData.lastUpdated.value * 1000).toLocaleString());
            }
            if(newdashboard){
                setnewdashboard(newdashboard);
                console.log(newdashboard)
                setnewdatacards([
                    {
                        title: "Crashes This Week",
                        data: dashboardData.weeklyCrashes.value.toString(),
                        updated: lastUpdated,
                        ios: warningOutline,
                        md: warningSharp,
                        color: "icon__green",
                        source: dashboardData.weeklyCrashes.notes.source.toString(),
                        description: dashboardData.weeklyCrashes.notes.Description.toString(),
                    },
                    {
                        title: "Clearance Time",
                        data: dashboardData.clearanceTime.value.toString() + " min.",
                        updated: lastUpdated,
                        ios: informationCircleOutline,
                        md: informationCircleSharp,
                        color: "icon__yellow",
                        source: dashboardData.clearanceTime.notes.source.toString(),
                        description: dashboardData.clearanceTime.notes.Description.toString(),
                    },
                    {
                        title: "Freeway Counts",
                        data: parseInt(
                          dashboardData.freewayCounts.value.toString()).toString(),
                        updated: lastUpdated,
                        ios: carSportOutline,
                        md: carSportSharp,
                        color: "icon__red",
                        source: dashboardData.freewayCounts.notes.source.toString(),
                        description: dashboardData.freewayCounts.notes.Description.toString(),
                    },
                    {
                        title: "PTIV",
                        data: "3.8",
                        updated: lastUpdated,
                        ios: statsChartOutline,
                        md: statsChartSharp,
                        color: "icon__blue",
                        source: "N/A",
                        description: "N/A",   
                    }
                ])
                
                // console.log(newdatacards)
            }
            
            



        });
        return () => {
            unsubscribe();
        }
    }, []);

    
    const data={
        
        datasets:[{
            label:'Poll',
            data:[3,6],
            backgroundColor:['black','red'],
            borderColor:['black','red'],
            
        }]
    }

    const options={
        cutoutPercentage: 80,
        rotation:Math.PI,
        circumference: Math.PI,
        
    }

    const gaugeText={
        id:'gaugeText',
        beforeDatasetsDraw(chart:any,args:any,pluginOptions:any){
            const {ctx,chartArea:{top,bottom,left,right,width,height}}=chart;

            // console.log(chart.getDatasetMeta(0).data[0]._model)
            const xCenter=chart.getDatasetMeta(0).data[0]._model.x;
            const yCenter=chart.getDatasetMeta(0).data[0]._model.y;

            ctx.save();
            ctx.fillStyle='gray';
            ctx.font='bold 30px sans-serif';
            ctx.textAlign='center';
            ctx.textBaseline='bottom'
            ctx.fillText('test',xCenter,yCenter)
        }

    }

    
      

    return (
        <IonPage>
            <Header title="Dashboard" />
            <IonContent fullscreen color="light">
      

        <div className="graph-data-card">
           <div className="graph-data-card-item">
                {newdashboard && newdatacards.length > 0 && (<GraphDataCard content={newdatacards[0]} crashList={counties} />)}
            </div>
            <div className="graph-data-card-item">
                {newdashboard && newdatacards.length > 0 && (<ClearanceDataCard content={newdatacards[1]} crashList={counties} newdata={newdashboard}/>)}
            </div>
            <div className="graph-data-card-item">
                {newdashboard && newdatacards.length > 0 && (<FreewayDataCard content={newdatacards[2]} crashList={counties} newdata={newdashboard}/>)}
            </div>
            <div className="graph-data-card-item">
                 {newdashboard && newdatacards.length > 0 && (<CongestionDataCard content={newdatacards[3]} crashList={counties} newdata={newdashboard}/>)}
            </div>
        </div>
            
                {/* <DataCards weeklyCrashes={dashboardData.weeklyCrashes}
                           clearanceTime={dashboardData.clearanceTime}
                           freewayCounts={dashboardData.freewayCounts}
                           pti={3.8}
                           lastUpdated={lastUpdated}
                /> */}
                 
                <div className='class-for-padding'>
                
               { newdashboard && newdatacards.length > 0 && <GraphContainer dailyCrashCount={dashboardData.dailyCrashCount}
                                dailyCrashDay={dashboardData.dailyCrashDay}
                                incidentTypeCount={dashboardData.incidentTypeCount}
                                incidentTypeName={dashboardData.incidentTypeName}
                                trafficCount={dashboardData.trafficCount}
                                lastUpdated={lastUpdated}
                                content={newdatacards[0]}
                                crashList={counties}
                                newdata={newdashboard}
                />
                  }
                  
                  {/* {newdashboard && newdatacards.length > 0 && (<WorkZoneDataCard content={newdatacards[0]} crashList={counties} newdata={newdashboard}/>)} */}
                  
                </div>
                <div className='padding-for-map'>
                <IonCard className="map-card third-step">
                    <IonGrid>
                        
                        <IonRow>
                            
                            <IonCol>
                                <Map height="30em" traffic={true} location={true} stateOutline={true}/>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>
                </div>
                <Footer/>
            </IonContent>
            <Tour
                steps={steps}
                isOpen={isTour}
                accentColor='black'
                onRequestClose={() => {tourService.GoBack(history)}}/>
        </IonPage>
    );
};

export default Dashboard;
