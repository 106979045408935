import * as React from 'react';
import './AboutSegments.css';

import {
    IonCard,
    IonCardContent,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonText
} from "@ionic/react";

import {
    statsChartOutline,
    statsChartSharp,
    alertOutline,
    alertSharp,
    searchOutline,
    searchSharp,
    carSportOutline,
    carSportSharp,
    videocamOutline,
    videocamSharp,
    rainyOutline,
    rainySharp
} from 'ionicons/icons';

interface DataItem {
    description: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const dataSetItems: DataItem[] = [
    {
        title: 'Probe Data',
        description: 'Traffic Speed and Travel Time at the road segment level.',
        iosIcon: statsChartOutline,
        mdIcon: statsChartSharp
    },
    {
        title: 'Incidents Data',
        description: 'Traffic Incident data managed by MoDOT and ST Charles County.',
        iosIcon: alertOutline,
        mdIcon: alertSharp
    },
    {
        title: 'Detector Data',
        description: 'Traffic Volume, Occupancy, and Speed data from the STL region.',
        iosIcon: searchOutline,
        mdIcon: searchSharp
    },
    {
        title: 'Waze Data',
        description: 'Traffic incidents such as Crashes, Jams, Stalled Vehicles, Weather Hazards, and Construction Activities recorded from various sources.',
        iosIcon: carSportOutline,
        mdIcon: carSportSharp
    },
    {
        title: 'Weather Data',
        description: 'Weather data and hazardous events following 700 different locations in Missouri.',
        iosIcon: rainyOutline,
        mdIcon: rainySharp
    },
    {
        title: 'CCTV Cameras',
        description: 'Live streams of the Missouri road and highway CCTV cameras.',
        iosIcon: videocamOutline,
        mdIcon: videocamSharp
    }
];

const DataSegment: React.FC = () => {

    return (
        <IonCard className="about-segment-card">
            <IonItemDivider className="about-header-card-title" color="light" sticky={true}>
                <IonLabel>RIDSI Data Sets</IonLabel>
            </IonItemDivider>
            <IonCardContent className="ion-text-left">
                <div className="about-card-subtitle-container">
                    <IonLabel className="about-card-subtitle">RIDSI has many collections of data that are present throughout the app from across the state of Missouri: </IonLabel>
                </div>
                <IonList>
                    { dataSetItems.map((dataSet:DataItem, index) => {
                        return(
                            <IonItem key={index}>
                                <IonIcon slot="start" md={dataSet.mdIcon} ios={dataSet.iosIcon} size="large" />
                                <IonText>
                                    {dataSet.title}: {dataSet.description}
                                </IonText>
                            </IonItem>
                        );
                    }) }
                </IonList>
            </IonCardContent>
        </IonCard>
    );
};

export default DataSegment;
