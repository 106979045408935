import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle
} from '@ionic/react';

import {useHistory, useLocation} from 'react-router-dom';
import {
  gridOutline,
  gridSharp,
  statsChartOutline,
  statsChartSharp,
  analyticsOutline,
  analyticsSharp,
  alertOutline,
  alertSharp,
  searchOutline,
  searchSharp,
  hourglassOutline,
  hourglassSharp,
  informationCircleOutline,
  informationCircleSharp,
  subwayOutline,
  subwaySharp,
  podiumOutline,
  podiumSharp,
  personCircleOutline,
  personCircleSharp,
  personRemoveOutline,
  personRemoveSharp,
  carSportOutline,
  carSportSharp,
  videocamOutline,
  videocamSharp,
  warningOutline,
  warningSharp,
  cloudUploadOutline,
  cloudUploadSharp,
  arrowForwardOutline,
  paperPlaneOutline
} from 'ionicons/icons';
import './Menu.css';
import * as React from 'react';
import {logoutUser} from '../../firebaseConfig';
import {useState} from 'react';
import {Link} from '../../interfaces/Link';
import {useEffect} from 'react';
import firebaseService from '../../services/firebaseService';
import {Device} from '@capacitor/device';
import iconService from '../../services/iconService';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  link?: string;
}

const subtitle = "sub-title";

const appPages: AppPage[] = [
  {
    title: 'Profile',
    url: '/Profile',
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp
  },
  {
    title: 'Dashboard',
    url: '/Dashboard',
    iosIcon: gridOutline,
    mdIcon: gridSharp
  }
];

const dataDownloadPages: AppPage[] = [
  {
    title: 'PROBE',
    url: '/Data/Probe',
    iosIcon: statsChartOutline,
    mdIcon: statsChartSharp
  },
  {
    title: 'INCIDENTS',
    url: '/Data/Incidents',
    iosIcon: alertOutline,
    mdIcon: alertSharp
  },
  {
    title: 'DETECTOR',
    url: '/Data/Detector',
    iosIcon: searchOutline,
    mdIcon: searchSharp
  },
  {
    title: 'WAZE INCIDENT',
    url: '/Data/WazeIncident',
    iosIcon: carSportOutline,
    mdIcon: carSportSharp
  },
  {
    title: 'WAZE JAM',
    url: '/Data/WazeJam',
    iosIcon: warningOutline,
    mdIcon: warningSharp
  },
  {
    title: 'COUNTS',
    url: '/Data/Counts',
    iosIcon: analyticsOutline,
    mdIcon: analyticsOutline
  },
  {
    title: 'History',
    url: '/History',
    iosIcon: hourglassOutline,
    mdIcon: hourglassSharp
  },
  {
    title: 'Data Upload',
    url: '/Upload',
    iosIcon: cloudUploadOutline,
    mdIcon: cloudUploadSharp
  },

 
];

const livePages: AppPage[] = [
  {
    title: 'Visualization',
    url: '/Visualization',
    iosIcon: podiumOutline,
    mdIcon: podiumSharp
  },
  {
    title: 'Analytics',
    url: '/AppCenter/Analytics',
    iosIcon: analyticsOutline,
    mdIcon: analyticsSharp
  },
  {
    title: 'Live CCTV',
    url: '/Live',
    iosIcon: videocamOutline,
    mdIcon: videocamSharp
  }
];
const miscPages: AppPage[] = [
  {
    title: 'Tutorials',
    url: '/Tutorials',
    iosIcon: informationCircleOutline,
    mdIcon: informationCircleSharp
  },
  {
    title: 'Support',
    url: '/Support',
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneOutline
  },
  {
    title: 'About RIDSI',
    url: '/About',
    iosIcon: subwayOutline,
    mdIcon: subwaySharp
  }
];

const Menu: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  function logout() {
    logoutUser().then(() => {
      history.push("/Login");
    });
  }

  const [links, setLinks] = useState<Link[]>();

  useEffect(() => {
    const foundLinks: Link[] = [];
    firebaseService.getLinks().then(res => {
      Device.getInfo().then(device => {
        res.docs.forEach(doc => {
          const foundLink = doc.data() as Link;
          foundLink.name = doc.id;
          foundLink.icon = iconService.getIcon(foundLink.icon, device.platform);
          foundLinks.push(foundLink);
        });
        foundLinks.sort((a, b) => {
          return a.order - b.order;
        });
        setLinks(foundLinks);
      });
    })
  }, []);

  return (
      <IonMenu contentId="main" type="reveal" menuId="main" swipeGesture={false}>
        <IonContent>

          <IonList id="inbox-list">
            <IonListHeader>Welcome to RIDSI</IonListHeader>
            <br />
            {appPages.map((appPage, index) => {
              return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                      <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
              );
            })}
          </IonList>

          <IonList id="inbox-list">
            <IonListHeader class={subtitle}>Data Download</IonListHeader>
            <br />
            {dataDownloadPages.map((ddPage, index) => {
              return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem className={location.pathname === ddPage.url ? 'selected' : ''} routerLink={ddPage.url} routerDirection="none" lines="none" detail={false}>
                      <IonIcon slot="start" ios={ddPage.iosIcon} md={ddPage.mdIcon} />
                      <IonLabel>{ddPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
              );
            })}
          </IonList>

          <IonList id="inbox-list">
            <IonListHeader class={subtitle}>App Center</IonListHeader>
            <br />
            {links?.map((link, index) => {
              if (index < 4) {
                return(
                    <IonMenuToggle autoHide={false} key={index}>
                      <IonItem routerLink={'/AppCenter/' + link.name} routerDirection="none" lines="none" detail={false}>
                        <IonIcon slot="start" icon={link.icon} />
                        <IonLabel>{link.name}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                );
              } else {
                return null;
              }
            })}
            <IonMenuToggle autoHide={false}>
              <IonItem routerLink='/MorePages' routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={arrowForwardOutline} md={arrowForwardOutline} />
                <IonLabel>More Pages</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>

          <IonList id="inbox-list">
            <IonListHeader class={subtitle}>Live</IonListHeader>
            <br />
            {livePages.map((livePage, index) => {
              return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem className={location.pathname === livePage.url ? 'selected' : ''} routerLink={livePage.url} routerDirection="none" lines="none" detail={false}>
                      <IonIcon slot="start" ios={livePage.iosIcon} md={livePage.mdIcon} />
                      <IonLabel>{livePage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
              );
            })}
          </IonList>

          <IonList id="inbox-list">
            <br />
            {miscPages.map((footPage, index) => {
              return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem className={location.pathname === footPage.url ? 'selected' : ''} routerLink={footPage.url} routerDirection="none" lines="none" detail={false}>
                      <IonIcon slot="start" ios={footPage.iosIcon} md={footPage.mdIcon} />
                      <IonLabel>{footPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
              );
            })}
          </IonList>

          <IonList id="inbox-list">
            <IonMenuToggle autoHide={false}>
              <IonItem onClick={logout} routerLink="/Login" routerDirection="none" detail={false}>
                <IonIcon slot="start" ios={personRemoveOutline} md={personRemoveSharp} />
                <IonLabel>Log out</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>

        </IonContent>
      </IonMenu>
  );
};

export default Menu;
