
export const tags = [
    {
        name: "Probe",
        value: "probe"
    },
    {
        name: "Incidents",
        value: "incidents"
    },
    {
        name: "Detector",
        value: "detector"
    },
    {
        name: "Waze Incident",
        value: "waze-incident"
    },
    {
        name: "Waze Jam",
        value: "waze-jam"
    },
    {
        name: "Transit",
        value: "transit"
    },
    {
        name: "Signals",
        value: "signals"
    },
    {
        name: "Pavement",
        value: "pavement"
    },
    {
        name: "Bridges",
        value: "bridges"
    },
    {
        name: "Traffic Signs",
        value: "traffic-signs"
    }
];
