import * as React from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import firebase from "firebase";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonContent,
  IonPage,
  IonSpinner,
  IonToast,
} from "@ionic/react";
import Header from "../../components/Header/Header";
import FileDownload from "../../components/FileDownload/FileDownload";
import tourService from "../../services/tourService";
import firebaseService from "../../services/firebaseService";
import bigQueryService from "../../services/bigQueryService";
import formService from "../../services/formService";
import { getFireUser } from "../../firebaseConfig";
import { File } from "../../interfaces/File";
import { FormRequest } from "../../interfaces/FormRequest";
import { useForm } from "react-hook-form";
import {
  DataAttributes,
  DateTimes,
  SelectableFields,
  FileName,
} from "../../components/Forms/FormComponents";
import "./Form.css";
import Tour from "reactour";
import CountySelectorWithAlias from "../../components/Forms/CountySelectorWithAlias";
import { countiesWithAlias as countiesMO } from '../../assets/counties';

interface Attribute {
  name: string;
  checked: boolean;
  key: number;
}

interface IonCheckbox {
  value: string;
  checked: boolean;
}

const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

const Data: React.FC = () => {
  const { form } = useParams<{ form: string }>();
  let attributeOptions: Attribute[] = [];
  attributeOptions = formService.determineFormValues(attributeOptions, form);
  const formColor = formService.getFormColor();
  const [queryResponse, setQueryResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [responseRequested, setResponseRequested] = useState(false);
  const [checks, setChecks] = useState<Attribute[]>([]);
  const [file, setFile] = useState<string>(form);
  const [counties, setCounties] = useState<{ name: string; value: string[] }[]>([]);
  const [formError, setFormError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [startSelectedDate, handleStartDateChange] = useState<Date | null>(
    oneWeekAgo
  );
  const [endSelectedDate, handleEndDateChange] = useState<Date | null>(
    new Date()
  );
  const [unit, setUnit] = useState<number>(60);
  const [interval, setInterval] = useState<number>(60);
  const { handleSubmit } = useForm<FormRequest>();
  const findUser = getFireUser();
  let uid = "";
  if (findUser != null) {
    uid = findUser.uid;
  }

  const [downloadableFiles, setDownloadableFiles] = useState<File[]>([]);

  React.useEffect(() => {
    firebaseService.getFiles().onSnapshot((docs) => {
      const downloadedFiles: File[] = [];
      docs.forEach((doc: firebase.firestore.QueryDocumentSnapshot) => {
        const data = doc.data();
        if (data != null) {
          let typeToFormName = "";
          switch (data.type) {
            case "inrix_probe_query":
              typeToFormName = "Probe";
              break;
            case "waze_incident_query":
              typeToFormName = "WazeIncident";
              break;
            case "waze_jam_query":
              typeToFormName = "WazeJam";
              break;
            case "transcore_incident_query":
              typeToFormName = "Incidents";
              break;
            case "transcore_detector_query":
              typeToFormName = "Detector";
              break;
            case "counts_query":
              typeToFormName = "Counts";
          
              break;
          }
          if (typeToFormName === form) {
            downloadedFiles.push(
              new File(
                data.uid,
                doc.id,
                data.location,
                data.type,
                data.ready,
                data.queryResponse,
                data.queryDate
              )
            );
          }
        }
      });
      setDownloadableFiles(downloadedFiles);
    });
    setQueryResponse(false);
    setResponseRequested(false);
    setChecks([]);
    setCounties(getCountyList(form).slice(0,5));
    setFile(form);
    setFormError(false);
    setDateError(false);
    setFileError(false);
    handleStartDateChange(oneWeekAgo);
    handleEndDateChange(new Date());
    setUnit(60);
    setInterval(60);
  }, [uid, form]);

  const setCheck = (e: CustomEvent<IonCheckbox>) => {
    let currentCheckArr = checks;
    if (e.detail.checked) {
      let needsInsertion = false;
      if (currentCheckArr.length > 0) {
        for (const check of currentCheckArr) {
          if (check.name === e.detail.value) {
            needsInsertion = false;
            break;
          } else {
            needsInsertion = true;
          }
        }
      } else {
        needsInsertion = true;
      }

      if (needsInsertion) {
        const newCheck: Attribute = attributeOptions.filter(
          (attribute) => attribute.name === e.detail.value
        )[0];
        currentCheckArr.push(newCheck);
      }
    } else {
      currentCheckArr = currentCheckArr.filter(
        (attribute) => attribute.name !== e.detail.value
      );
      setChecks(currentCheckArr);
    }
    setChecks(currentCheckArr);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getCountyList(form:string) {

    return countiesMO;
    // switch (form) {
    //     case "Probe": return probeCounties;
    //     case "Detector": return detectorCounties;
    //     case "WazeIncident": return wazeIncidentsCounties;
    //     case "WazeJam": return wazeJamCounties;
    //     default: return countiesMO;

    // }
}

  const onSubmit = (data: FormRequest) => {
    setResponseRequested(true);
    const convertChecks: number[] = [];
    checks.forEach((check) => {
      convertChecks.push(check.key);
    });
    data.attributes = convertChecks;
    const validatedData = formService.validateForm(
      data,
      startSelectedDate,
      endSelectedDate,
      file,
      counties,
      downloadableFiles,
      convertChecks,
      interval,
      unit,
      form,
      setFormError,
      setDateError,
      setFileError
    );

    if (formError || dateError || fileError) {
      setResponseRequested(false);
    }

    if (validatedData != null) {
      data = validatedData;
      switch (form) {
        case "Probe":
          bigQueryService
            .queryProbeData(data)
            .then((res) => res.json())
            .then(
              (res) => {
                if (res.message === "requestProcessing") {
                  setQueryResponse(true);
                  setErrorResponse(false);
                }
                setResponseRequested(false);
              },
              (err) => {
                console.log(err);
                setErrorResponse(true);
                setResponseRequested(false);
              }
            );
          break;
        case "Incidents":
          bigQueryService
            .queryIncidentsData(data)
            .then((res) => res.json())
            .then(
              (res) => {
                if (res.message === "requestProcessing") {
                  setQueryResponse(true);
                  setErrorResponse(false);
                }
                setResponseRequested(false);
              },
              (err) => {
                console.log(err);
                setErrorResponse(true);
                setResponseRequested(false);
              }
            );
          break;
        case "Detector":
          bigQueryService
            .queryDetectorData(data)
            .then((res) => res.json())
            .then(
              (res) => {
                if (res.message === "requestProcessing") {
                  setQueryResponse(true);
                  setErrorResponse(false);
                }
                setResponseRequested(false);
              },
              (err) => {
                console.log(err);
                setErrorResponse(true);
                setResponseRequested(false);
              }
            );
          break;
        case "WazeIncident":
          bigQueryService
            .queryWazeIncidentData(data)
            .then((res) => res.json())
            .then(
              (res) => {
                if (res.message === "requestProcessing") {
                  setQueryResponse(true);
                  setErrorResponse(false);
                }
                setResponseRequested(false);
              },
              (err) => {
                console.log(err);
                setErrorResponse(true);
                setResponseRequested(false);
              }
            );
          break;
        case "WazeJam":
          bigQueryService
            .queryWazeJamData(data)
            .then((res) => res.json())
            .then(
              (res) => {
                if (res.message === "requestProcessing") {
                  setQueryResponse(true);
                  setErrorResponse(false);
                }
                setResponseRequested(false);
              },
              (err) => {
                console.log(err);
                setErrorResponse(true);
                setResponseRequested(false);
              }
            );
          break;
        case "Counts":
          bigQueryService
            .queryCountData(data)
            .then((res) => res.json())
            .then(
              (res) => {
                if (res.message === "requestProcessing") {
                  setQueryResponse(true);
                  setErrorResponse(false);
                }
                setResponseRequested(false);
              },
              (err) => {
                console.log(err);
                setErrorResponse(true);
                setResponseRequested(false);
              }
            );
          break;
        default:
          setErrorResponse(true);
          setResponseRequested(false);
          break;
      }
    } else {
      setResponseRequested(false);
    }
  };
  const steps = tourService.getStepsFor("QueryData");
  const isTour = tourService.StartTour();
  const history = useHistory();
  return (
    <IonPage>
      <Header title={"Query " + form} />
      <IonContent fullscreen color="light">
        <IonCard className="form-card">
          <IonCardHeader>
            <IonCardTitle
              className={
                "card-header-title-box first-step " + "title-" + formColor
              }
            >
              {"Query " + form + " Database"}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DateTimes
                startDate={startSelectedDate}
                endDate={endSelectedDate}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                form={form}
              />
              {attributeOptions.length > 0 && (
                <DataAttributes
                  attributeOptions={attributeOptions}
                  setCheck={setCheck}
                  form={form}
                />
              )}
              {(form === "Probe" ||
                form === "Detector" ||
                form === "Counts") && (
                <SelectableFields
                  unit={unit}
                  interval={interval}
                  setUnit={setUnit}
                  setInterval={setInterval}
                  form={form}
                  hideUnitInTime={(form==="Counts")}
                />
              )}
              <FileName file={file} setFile={setFile} form={form} />
              <CountySelectorWithAlias
                counties={counties}
                setCounties={setCounties}
                options={getCountyList(form)}
                type="counties"
                width="county-select"
              />
              <IonButton
                className="fifth-step"
                color={formColor}
                type="submit"
                disabled={responseRequested}
              >
                Submit Query
              </IonButton>
              <br />
              <br />
              {responseRequested && (
                <div className="response-requested">
                  Preparing your form. You may leave this page. This could take
                  several minutes... &nbsp;
                  <IonSpinner />
                </div>
              )}
              <IonToast
                isOpen={queryResponse}
                onDidDismiss={() => setQueryResponse(false)}
                color="light"
                message="Your request has been received, your download will be available shortly!"
                buttons={[
                  {
                    text: "Ok",
                    side: "end",
                    role: "cancel",
                  },
                ]}
              />
              <IonToast
                isOpen={errorResponse}
                onDidDismiss={() => setErrorResponse(false)}
                color="danger"
                message="Oops. Looks like our data collection server is down right now. Please try again later!"
                cssClass="toast-error"
                duration={10000}
              />
              <IonToast
                isOpen={fileError}
                onDidDismiss={() => setFormError(false)}
                color="danger"
                message="Invalid file name. File names must be under 25 characters and cannot match another existing file."
                cssClass="toast-error"
                duration={10000}
              />
              <IonToast
                isOpen={formError}
                onDidDismiss={() => setFormError(false)}
                color="danger"
                message="Please fill out all form fields"
                cssClass="toast-error"
                duration={10000}
              />
              <IonToast
                isOpen={dateError}
                onDidDismiss={() => setDateError(false)}
                color="danger"
                message="The specified date range is invalid"
                cssClass="toast-error"
                duration={10000}
              />
            </form>
          </IonCardContent>
        </IonCard>
        <FileDownload downloadableFiles={downloadableFiles} />
        <Tour
          steps={steps}
          isOpen={isTour}
          accentColor="black"
          onRequestClose={() => {
            tourService.GoBack(history);
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default Data;
