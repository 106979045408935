import * as React from 'react';
import {RouteProps} from "react-router";
import './DeveloperCard.css';
import { DeveloperData } from "../../../interfaces/AboutData";

export interface DeveloperProps extends RouteProps {
    developer: DeveloperData;
}

import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonGrid, IonIcon,
    IonImg, IonLabel,
    IonRow
} from "@ionic/react";

import {globeOutline} from "ionicons/icons";

{/*
TODO: Use Yaw's Reaction to the page/any info on actual page data to see if this component should be abstracted
 to also display agencies. Could be helpful if there's a bunch of them
*/}
const DeveloperCard: React.FC<DeveloperProps> = (props:DeveloperProps) => {
    const developer: DeveloperData = props.developer;

    return(
        <IonCol size="6" size-xs="12" size-sm="12" size-md="6" size-lg="6">
            <IonCard className="outer-dev-card">
                <IonGrid>
                    <IonRow>
                        <IonCol size="5" size-xs="12" size-xl="5">
                            <div className="about-member-image">
                                <IonImg src={developer.pictureFile} alt="profile" />
                            </div>
                        </IonCol>
                        <IonCol size="7" size-xs="12" size-xl="7">
                            <IonCardHeader className="ion-text-left">
                                <IonCardSubtitle className="about-role-area">{developer.role}</IonCardSubtitle>
                                <IonCardTitle>{developer.name}</IonCardTitle>
                            </IonCardHeader>
                        </IonCol>
                    </IonRow>
                    <IonRow className="about-row-top-border">
                        <IonCardContent className="about-description-area">{developer.description}</IonCardContent>
                    </IonRow>
                    <IonRow className="about-row-top-border about-link-row">
                        <IonLabel>
                            <IonIcon className="about-link-icon" md={globeOutline}/>
                            <a href={developer.link}>{developer.link}</a>
                        </IonLabel>
                    </IonRow>
                </IonGrid>
            </IonCard>
        </IonCol>
    );
};

export default DeveloperCard;
