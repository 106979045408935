import {IonContent, IonGrid, IonPage, IonRow} from '@ionic/react';
import * as React from 'react';
import Header from '../../components/Header/Header';
import TutorialCard from "../../components/Cards/TutorialCards/TutorialCard";
import {TutorialData} from '../../interfaces/TutorialData';
import {
    appsOutline,
    appsSharp,
    gridOutline,
    gridSharp,
    notifications,
    personCircleOutline,
    personCircleSharp,
    podiumOutline,
    podiumSharp,
    statsChartOutline,
    statsChartSharp,
    subwayOutline,
    subwaySharp,
    videocamOutline,
    videocamSharp
} from 'ionicons/icons';
const Tutorials: React.FC = () => {

    const cards: TutorialData[] = [
        {
            title: 'Database Querying',
            color: 'card-orange',
            iosIcon: statsChartOutline,
            mdIcon: statsChartSharp,
            playTour: true,
            tourLink: '/Data/Probe/?tour=\'true\'',
            description: 'The Database Querying page provides you with the ability to query ' +
                'historical data of traffic incidents, jams, detectors, and probe analysis.'
        },
        {
            title: 'Live CCTV Streaming',
            color: 'card-dark',
            iosIcon: videocamOutline,
            mdIcon: videocamSharp,
            playTour: true,
            tourLink: './Live/?tour=\'true\'',
            description: 'The Live CCTV page provides you with the ability to monitor traffic ' +
                'cameras around the state of Missouri in real-time.'
        },
        {
            title: 'App Center',
            color: 'card-green',
            iosIcon: appsOutline,
            mdIcon: appsSharp,
            playTour: false,
            tourLink: './Dashboard',
            description: 'The App Center pages provide you with aggregated listings and displays ' +
                'of the data we are collecting, as well as insights into traffic across the state.'
        },
        {
            title: 'User Profile',
            color: 'card-red',
            iosIcon: personCircleOutline,
            mdIcon: personCircleSharp,
            playTour: true,
            tourLink: './Profile/?tour=\'true\'',
            description: 'The User Profile page provides you with a look into your information, ' +
                'as well as the ability to change app settings and privacy information.'
        },
        {
            title: 'Dashboard',
            color: 'card-blue',
            iosIcon: gridOutline,
            mdIcon: gridSharp,
            playTour: true,
            tourLink: './Dashboard/?tour=\'true\'',
            description: 'The Dashboard is the one-stop location for all the up-to-date collections ' +
                'of traffic data across the state of Missouri. Take a look for a live map of the state and ' +
                'current crash data charts.'
        },
        {
            title: 'Notifications',
            color: 'card-purple',
            iosIcon: notifications,
            mdIcon: notifications,
            playTour: true,
            tourLink: './Notifications/?tour=\'true\'',
            description: 'The Notifications page provides a list of all received notifications for your account. ' +
                'Here you can view all of the events you are subscribed to around Missouri and manage your list.'
        },
        {
            title: 'Visualization',
            color: 'card-yellow',
            iosIcon: podiumOutline,
            mdIcon: podiumSharp,
            playTour: true,
            tourLink: './Visualization/?tour=\'true\'',
            description: 'The Visualization page provides you a configurable view of the application map, where ' +
                'you can change the different layers, as well as see weather information from across the state.'
        },
        {
            title: 'About RIDSI',
            color: 'card-pink',
            iosIcon: subwayOutline,
            mdIcon: subwaySharp,
            playTour: true,
            tourLink: '/About',
            description: 'Head over to the About page for more information!'
        }
    ];

    return (
        <IonPage>
            <Header title={"Tutorials"} />
            <IonContent class='first-step'>
                <IonGrid className="main-grid">
                    <IonRow>
                        {cards.map((card, index) => {
                            return (
                                <TutorialCard key={index} cardData={card} />
                            );
                        })}
                        </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Tutorials;
