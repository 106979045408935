import React, { useState } from "react";
import {
  IonButton,
  IonInput,
  IonCard,
  IonContent,
  IonCardTitle,
  IonCardContent,
  IonCardHeader,
  IonLabel,
  IonPage,
  IonTextarea,
  useIonToast,
  useIonLoading,
} from "@ionic/react";
import "./Support.css";
import Header from "../../components/Header/Header";
import { organization as organizationList } from "../../assets/organization";
import { supporttype as supportTypeList } from "../../assets/supporttype";

import { User } from "../../interfaces/User";
import DDSelector from "../../components/Forms/DropDownSelector";
import firebaseService from "../../services/firebaseService";
import { useHistory } from "react-router";
import { KeyValueData } from "../../interfaces/KeyValueData";
import { UserFeedback } from "../../interfaces/UserFeedback";

const Support: React.FC = () => {
  const [showLoading, hideLoading] = useIonLoading();

  const [file, setFile] = useState<File | null>();

  const [type, setType] = useState<KeyValueData[]>([]);
  const [organization, setOrganization] = useState<KeyValueData[]>([]);
  const [requesterName, setRequesterName] = useState("");
  const [description, setDescription] = useState("");

  const [present, dismiss] = useIonToast();
  const history = useHistory();

  function validateFields(): boolean {
    if (
      organization == null ||
      organization.length < 1 ||
      type == null ||
      type.length < 1 ||
      organization == null ||
      organization.length < 1 ||
      requesterName.length < 1 ||
      description.length < 1
    )
      return false;
    return true;
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }
    setFile(event.target.files[0]);
  }

  async function onSubmit() {
    if (validateFields()) {
      const currentUser: User | null = firebaseService.getCurrentUser();
      if (currentUser == null) return;
      try {
        const userData: UserFeedback = {
          uid: currentUser.uid,
          organization: organization[0].value,
          type: type[0].value,
          requesterName: requesterName,
          requesterEmail: currentUser.email,
          requestedDate: getCurrentDateAndTime(),
          description: description,
        } as UserFeedback;
        if (!file) {
          userData.attachmentUrl = "";
          userData.attachmentFileName = "";
          uploadForm(userData);
        } else {
          showLoading({ backdropDismiss: false });
          firebaseService
            .uploadAttachment(currentUser.uid, file, Date.now().toString())
            .then(
              (data) => {
                firebaseService.getAttachementUrl(data.ref).then((url) => {
                  hideLoading();
                  userData.attachmentUrl = url;
                  userData.attachmentFileName = file.name;
                  uploadForm(userData);
                });
              },
              (error) => {
                hideLoading();
                present({
                  buttons: [{ text: "dismiss", handler: () => dismiss() }],
                  message: "Unable to upload. " + error,
                  duration: 3000,
                  color: "danger",
                });
              }
            );
        }
      }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catch (e: any) {
        present({
          buttons: [{ text: "dismiss", handler: () => dismiss() }],
          message: e,
          duration: 5000,
          color: "danger",
        });
      }
    } else {
      present({
        buttons: [{ text: "dismiss", handler: () => dismiss() }],
        message: "All fields are required.",
        duration: 5000,
        color: "danger",
      });
    }
  }

  function clear() {
    setRequesterName("");
    setDescription("");
    setType([]);
    setOrganization([]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function uploadForm(userData: any) {
    await firebaseService.submitFeedbackRequest(userData);
    clear();

    present({
      buttons: [{ text: "dismiss", handler: () => dismiss() }],
      message: "Requested successfully",
      duration: 5000,
      color: "success",
    });
    history.push("/Dashboard");
  }

  function getCurrentDateAndTime(): string {
    const date = new Date();
    const n = date.toDateString();
    const time = date.toLocaleTimeString();
    return n + " " + time;
  }

  return (
    <IonPage>
      <Header title="Support" />
      <IonContent fullscreen color="light">
        <IonCard className="form-card">
          <IonCardHeader>
            <IonCardTitle>Support Request</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <DDSelector
              title="Select Organization"
              data={organization}
              onDataChange={setOrganization}
              options={organizationList}
              width="county-select"
              allowMultipleOption={false}
            />
            <DDSelector
              title="Select Type"
              data={type}
              onDataChange={setType}
              options={supportTypeList}
              width="county-select"
              allowMultipleOption={false}
            />

            <div className="form-div county-column">
              <IonLabel>Requester Name</IonLabel>
              <div className="county-form-div">
                <IonInput
                  className="form-input"
                  value={requesterName}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onIonChange={(val: any) => {
                    setRequesterName(val.target.value);
                  }}
                />
              </div>
            </div>

            <div className="form-div county-column">
              <IonLabel>Description</IonLabel>
              <div className="county-form-div">
                <IonTextarea
                  rows={5}
                  className="form-input"
                  value={description}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onIonChange={(val: any) => {
                    setDescription(val.target.value);
                  }}
                />
              </div>
            </div>
            <input
              type="file"
              onChange={handleChange}
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            />

            <IonButton
              color="secondary"
              onClick={() => {
                onSubmit();
              }}
            >
              Submit
            </IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Support;
