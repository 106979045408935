import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {User} from './interfaces/User';

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: "ridsi-13389.firebaseapp.com",
    databaseURL: "https://ridsi-13389-default-rtdb.firebaseio.com",
    projectId: "ridsi-13389",
    storageBucket: "ridsi-13389.appspot.com",
    messagingSenderId: "1077534661299",
    appId: "1:1077534661299:web:e9748ad5a37eab324aeec6",
    measurementId: "G-3RXHW9CLZ9"
};

firebase.initializeApp(firebaseConfig);


let fireUser: firebase.User | null = null;
let user: User | null = null;

firebase.auth().onAuthStateChanged((changedUser) => {
    fireUser = changedUser;
    watchUserDoc()?.onSnapshot(doc => {
        user = doc.data() as User;
    });
});

export function getUser(): Promise<firebase.firestore.DocumentSnapshot> | null {
    if (fireUser != null) {
        return firebase.firestore().collection('Users').doc(fireUser.uid).get();
    } else {
        return null;
    }
}

export function watchUserDoc(): firebase.firestore.DocumentReference | null {
    if (fireUser != null) {
        return firebase.firestore().collection('Users').doc(fireUser.uid);
    } else {
        return null;
    }
}

export function getCurrentUser(): User | null {
    return user;
}

export function getFireUser(): firebase.User | null {
    return fireUser;
}

export function watchUser(): firebase.auth.Auth {
    return firebase.auth();
}

export function logoutUser(): Promise<void> {
    return firebase.auth().signOut();
}

export function loginUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
}

export function registerUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
}

export function googleAuthentication(): Promise<void> {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithRedirect(provider);
}

export function facebookAuthentication(): Promise<void> {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithRedirect(provider);
}

export function twitterAuthentication(): Promise<void> {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithRedirect(provider);
}

export function getRedirectResult(): Promise<firebase.auth.UserCredential> {
    return firebase.auth().getRedirectResult();
}

export function resetPassword(email: string): Promise<void> {
    const redirect = window.location.href.replace('/ForgotPassword', '/login');
    return firebase.auth().sendPasswordResetEmail(email, {url: redirect});
}

export function createUser(user: User): Promise<void> {
    return firebase.firestore().collection('Users').doc(user.uid).set(Object.assign({}, user));
}

export function updateUser(user: User): Promise<void> {
    return firebase.firestore().collection('Users').doc(user.uid).set(Object.assign({}, user), {merge: true});
}
