import { FormRequest } from '../interfaces/FormRequest';
import { getFireUser } from '../firebaseConfig';
import { File } from '../interfaces/File';

interface Attribute {
    name: string;
    checked: boolean;
    key: number;
}




let formColor = '';

const formService = {

 
    determineFormValues: (attributeOptions: Attribute[], form: string): Attribute[] => {
        switch (form) {
            case 'Probe':
                formColor = 'orange';
                attributeOptions = [{
                    name: 'Travel Time',
                    checked: false,
                    key: 0
                }, {
                    name: 'Speed',
                    checked: false,
                    key: 1
                }, {
                    name: 'Average Speed',
                    checked: false,
                    key: 2
                }, {
                    name: 'Reference Speed',
                    checked: false,
                    key: 3
                }];
                break;
            case 'Incidents':
                formColor = 'green';
                attributeOptions = [{
                    name: 'Incidents',
                    checked: false,
                    key: 0
                }, {
                    name: 'Construction',
                    checked: false,
                    key: 1
                }];
                break;
            case 'Detector':
                formColor = 'red';
                attributeOptions = [];
                break;
            case 'WazeIncident':
                formColor = 'blue';
                attributeOptions = [{
                    name: 'Major Accidents',
                    checked: false,
                    key: 0
                }, {
                    name: 'Minor Accidents',
                    checked: false,
                    key: 1
                }, {
                    name: 'Construction',
                    checked: false,
                    key: 2
                }, {
                    name: 'Car Stopped',
                    checked: false,
                    key: 3
                }, {
                    name: 'Jam',
                    checked: false,
                    key: 4
                }, {
                    name: 'Road Closed',
                    checked: false,
                    key: 5
                }];
                break;
            case 'WazeJam':
                formColor = 'pink';
                attributeOptions = [];
                break;

            case 'Counts':
                formColor = 'grey';
                attributeOptions = [{
                    name: 'Occupancies',
                    checked: false,
                    key: 0
                }, {
                    name: 'Vehicle Count',
                    checked: false,
                    key: 1
                }, {
                    name: 'Vehicle Speed',
                    checked: false,
                    key: 2
                }];
                break;
            default:
                formColor = '';
                break;
        }
        return attributeOptions
    },

    getFormColor: (): string => {
        return formColor;
    },

    validateForm: (data: FormRequest,
        startSelectedDate: Date | null,
        endSelectedDate: Date | null,
        file: string,
        counties: { name: string, value: string[] }[],
        downloadableFiles: File[],
        checks: number[],
        interval: number,
        unit: number,
        form: string,
        setFormError: ((error: boolean) => void),
        setDateError: ((error: boolean) => void),
        setFileError: ((error: boolean) => void)
    ): FormRequest | null => {
        const findUser = getFireUser();
        let uid = '';
        if (findUser != null) {
            uid = findUser.uid;
        }
        function convertDateToString(date: Date | null) {
            let startDate;
            if (date != null) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                startDate = date.toLocaleString("en-GB", { hourCycle: "h24" });
                startDate = startDate.replaceAll('/', '-');
                startDate = startDate.replace(',', '');
                const tempDate = startDate.split(' ')[0];
                const day = tempDate.split('-')[0];
                const month = tempDate.split('-')[1];
                const year = tempDate.split('-')[2];
                return year + "-" + month + "-" + day;
            } else {
                alert('Error parsing date.');
                return '2000-05-02';
            }
        }
        if (endSelectedDate != null && startSelectedDate != null) {
            let timeElapsed = endSelectedDate.getTime() - startSelectedDate.getTime();
            timeElapsed = timeElapsed / (1000 * 3600 * 24);
            if (timeElapsed > 186) {
                startSelectedDate = new Date();
                startSelectedDate.setMonth(endSelectedDate.getMonth() - 6);
            }
        }
        const startDate = convertDateToString(startSelectedDate);
        const endDate = convertDateToString(endSelectedDate);
        for (const downloadedFile of downloadableFiles) {
            if (downloadedFile.fileName === file || file.length > 25) {
                setFormError(false);
                setDateError(false);
                setFileError(true);
                return null;
            } else {
                setFileError(false);
            }
        }

        const countyValues: string[] = [];
        if (counties.length > 0) {
            counties.forEach(county => {
                countyValues.push(...county.value);
            })
        }

        if (((form === 'Probe' || form === 'Detector' || form === 'Counts') && interval == null) ||
            data.file === '' ||
            ((form === 'Probe') && unit == null)
        ) {
            setDateError(false);
            setFormError(true);
        } else if (startDate > endDate) {
            setFormError(false);
            setDateError(true);
        } else {
            setFormError(false);
            setDateError(false);
            return new FormRequest(uid, form, startDate, endDate, file, countyValues, data.attributes, interval, unit);
        }

        return null;
    }
};

export default formService;
