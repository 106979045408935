import {RouteComponentProps, useLocation} from "react-router";
import {ReactourStep} from "reactour";

const ProbeSteps = [
    {
        selector: '.first-step',
        content: 'This is the Probe Data Querying Page. ' +
            'This is one of the download pages that we offer which will allow you to select and download ' +
            'groups of historical data. \n' +
            'Follow the steps to learn how to query historical data on traffic, incidents, jams, ' +
            'and more!',
        style: {color: 'black'}
    },
    {
        selector: '.second-step',
        content: 'Here you can select the desired date range for the data you\'d like to receive.',
        style: {color: 'black'}
    },
    {
        selector: '.third-step',
        content: 'The data attributes section allows you to select the desired attributes you\'d like to find in your data.',
        style: {color: 'black'}
    },
    {
        selector: '.fourth-step',
        content: 'Here you can indicate the desired units and aggregation interval you\'d like to have formatted for your data.',
        style: {color: 'black'}
    },
    {
        selector: '.fifth-step',
        content: 'When you are ready, select Submit Query.',
        style: {color: 'black'}
    },
    {
        selector: '.sixth-step',
        content: 'Once the data has been collected, it will be available for download below or on the History page. [Click `X` or' +
            ' anywhere to return to the Tutorials page.]',
        style: {color: 'black'}
    }
];
const LiveSteps = [
    {
        selector: '.first-step',
        content: 'This is the Live CCTV Page. Here, you are able to select any ' +
            'of the camera icons on this map to see live video feed of the desired CCTV camera. When the page has finished loading, ' +
            'select one!',
        style: {color: 'black'}
    },
    {
        selector: '.second-step',
        content: 'Camera feeds will be shown in this area. You can add as many cameras as you\'d like, ' +
            'and when you are finished with specific ones, you can select the `X` button to remove it from your ' +
            'list of camera feeds or select \'Clear All\' to remove all of your current views.',
        style: {color: 'black'}
    },
    {
        selector: '.third-step',
        content: 'Click the `X` or select anywhere on the screen to return to the Tutorials page.',
        style: {color: 'black'}
    },
];

const ProfileSteps = [
    {
        selector: '.first-step',
        content: 'This is your Profile page. Here, you can view and edit your permissions, credentials, and information.',
        style: {color: 'black'}
    },
    {
        selector: '.second-step',
        content: 'Here is the quick navigation bar, which can quickly take you to your desired setting(s).',
        style: {color: 'black'}
    },
    {
        selector: '.third-step',
        content: 'This will take you to the account information card, where you can see your name, email, organization, ' +
            'and privileges.',
        style: {color: 'black'}
    },
    {
        selector: '.fourth-step',
        content: 'This will take you to the account settings card, where you can change your name, email, organization, ' +
            'password, and request additional privileges.',
        style: {color: 'black'}
    },
    {
        selector: '.fifth-step',
        content: 'This will take you to the location preferences card, where you can enable and disable location tracking.',
        style: {color: 'black'}
    },
    {
        selector: '.sixth-step',
        content: 'This will take you to the notification settings card, where you can change the types of notifications you receive, ' +
            'or get rid of them all together.',
        style: {color: 'black'}
    },
    {
        selector: '.seventh-step',
        content: 'This will take you to the administrator settings card, which only admins can view. This card will allow you to ' +
            'see what users have requested administrator access, as well as see user information for the application.',
        style: {color: 'black'}
    },
    {
        selector: '.eighth-step',
        content: 'This will take you to our app privacy policy, where you can read about the information we collect and what ' +
            'it\'s used for.',
        style: {color: 'black'}
    },
    {
        selector: '.ninth-step',
        content: 'Click the `X` or select anywhere on the screen to return to the Tutorials page.',
        style: {color: 'black'}
    },
];
const DashSteps = [
    {
        selector: '.first-step',
        content: 'Welcome to your Dashboard. Here, you can view up-to-date data analytics ' +
            'and see some insights on what events are occurring in the various counties of Missouri.',
        style: {color: 'black'}
    },
    {
        selector: '.second-step',
        content: 'These cards showcase aggregated metrics from across the state.',
        style: {color: 'black'}
    },
    {
        selector: '.third-step',
        content: 'This area shows the overall road conditions and provides coverage on crashes within ' +
            'Missouri counties. It also showcases the map, which includes up-to-date traffic data and location information.',
        style: {color: 'black'}
    },
    {
        selector: '.fourth-step',
        content: 'The charts below help display the large sums of data in easy to read metrics to showcase trends and patterns throughout ' +
            'Missouri.',
        style: {color: 'black'}
    },
    {
        selector: '.fifth-step',
        content: 'Click the `X` or select anywhere on the screen to return to the Tutorials page.',
        style: {color: 'black'}
    },
];
const VisualizationSteps = [
    {
        selector: '.first-step',
        content: 'Welcome to the Visualization page. Here you can view the application map and change the ' +
            'different layers you see.',
        style: {color: 'black'}
    },
    {
        selector: '.second-step',
        content: 'Here you can change the different layers you see on the map, including weather data across ' +
            'Missouri.',
        style: {color: 'black'}
    },
    {
        selector: '.fifth-step',
        content: 'Click the `X` or select anywhere on the screen to return to the Tutorials page.',
        style: {color: 'black'}
    },
];
const NotificationSteps = [
    {
        selector: '.first-step',
        content: 'Welcome to your Notifications. Here, you can view notifications you\'ve received about ' +
            'various events around Missouri.',
        style: {color: 'black'}
    },
    {
        selector: '.second-step',
        content: 'You can edit your notification settings and preferences on the User Profile.',
        style: {color: 'black'}
    },
    {
        selector: '.second-step',
        content: 'Click the `X` or select anywhere on the screen to return to the Tutorials page.',
        style: {color: 'black'}
    },
];

const tourService = {
    getStepsFor: (stepSet: string): ReactourStep[] => {
       let steps: ReactourStep[] = [];
       switch (stepSet) {
           case 'QueryData':
               steps = ProbeSteps;
               break;
           case 'Live':
               steps = LiveSteps;
               break;
           case 'Profile':
               steps = ProfileSteps;
               break;
           case 'Dashboard':
               steps = DashSteps;
               break;
           case 'Visualization':
               steps = VisualizationSteps;
               break;
           case 'Notifications':
               steps = NotificationSteps;
               break;
           default:
               steps = [{
                   selector: '.error',
                   content: 'Error grabbing Correct tour steps.',
                   style: {color: 'black'}
               }];
               break;
       }
       return steps;
    },

    StartTour: (): boolean => {
        const query = new URLSearchParams(useLocation().search);
        let isTour: boolean;
        query.get('tour') === '\'true\'' ? isTour = true: isTour = false;
        return isTour;
   },

    GoBack: (history: RouteComponentProps["history"]): void => {
        history.push('/Tutorials');
    }
};

export default tourService;
