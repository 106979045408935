import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/storage';


import { User } from '../interfaces/User';
import { getFireUser } from '../firebaseConfig';
import FileDoc from '../interfaces/FileDoc';
import { UserFeedback } from '../interfaces/UserFeedback';



const firebaseService = {
    getAuthToken: (): Promise<firebase.firestore.DocumentSnapshot> => {
        return firebase.firestore().collection('API-TOKEN').doc('1').get();
    },

    createLink: (page: string): Promise<firebase.firestore.DocumentSnapshot> => {
        return firebase.firestore().collection('Links').doc(page).get();
    },

    getLinks: (): Promise<firebase.firestore.QuerySnapshot> => {
        return firebase.firestore().collection('Links').get();
    },

    getFiles: (): firebase.firestore.CollectionReference => {
        return firebase.firestore().collection('Files').doc(getFireUser()?.uid)
            .collection('Files');
    },

    deleteFile: (fileID: string): Promise<void> => {
        return firebase.firestore().collection('Files').doc(getFireUser()?.uid)
            .collection('Files').doc(fileID).delete();
    },

    uploadFileDoc: (file: FileDoc): Promise<firebase.firestore.DocumentReference> => {
        delete file.data;
        const tags: string[] = [];
        file.tags.forEach(tag => {
            tags.push(tag.value);
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fileToUpload: any = Object.assign({}, file);
        fileToUpload.tags = tags;
        return firebase.firestore().collection('Files').doc(getFireUser()?.uid)
            .collection('UploadedFiles').add(fileToUpload);
    },

    uploadFile: (file: File): firebase.storage.UploadTask => {
        const storage = firebase.storage().ref();
        const fileRef = storage.child('userFiles/' + getFireUser()?.uid + '/' + file.name);
        return fileRef.put(file);
    },

    getUploadedFiles: (): Promise<firebase.storage.ListResult> => {
        const storage = firebase.storage().ref();
        const fileRef = storage.child('userFiles/' + getFireUser()?.uid);
        return fileRef.listAll();
    },

    getUploadedReferences: (): firebase.firestore.CollectionReference => {
        return firebase.firestore().collection('Files').doc(getFireUser()?.uid)
            .collection('UploadedFiles');
    },

    subscribeTokenToTopic(data: { tokens: string[], topic: string }): Promise<firebase.functions.HttpsCallableResult> {
        const subscribe = firebase.functions().httpsCallable('subscribeUser');
        return subscribe(data);
    },

    unsubscribeTokenFromTopic(data: { tokens: string[], topic: string }): Promise<firebase.functions.HttpsCallableResult> {
        const unsubscribe = firebase.functions().httpsCallable('unsubscribeUser');
        return unsubscribe(data);
    },

    getAllNotificationsCollection: (): firebase.firestore.CollectionReference => {
        return firebase.firestore().collection('Users').doc(getFireUser()?.uid)
            .collection('Notifications');
    },

    getNotifications: (): firebase.firestore.Query => {
        return firebase.firestore().collection('Users').doc(getFireUser()?.uid)
            .collection('Notifications').orderBy("date", "desc");
    },

    getCams: (): firebase.firestore.CollectionReference => {
        return firebase.firestore().collection('CCTV_UPDATE');
    },

    watchDashboardData: (): firebase.firestore.Query => {
        return firebase.firestore().collection('Dashboard');
    },

    watchWazeIncidentsData: (): firebase.firestore.Query => {
        return firebase.firestore().collection('WAZE').doc('ACTIVE').collection('INCIDENTS');
    },

    watchWazeJamsData: (): firebase.firestore.Query => {
        return firebase.firestore().collection('WAZE').doc('ACTIVE').collection('JAMS');
    },

    watchTranscoreData: (): firebase.firestore.Query => {
        return firebase.firestore().collection('TRANSCORE').doc('ACTIVE').collection('INCIDENTS');
    },

    watchWeatherData: (): firebase.firestore.Query => {
        return firebase.firestore().collection('WEATHER').doc('RT').collection('INCIDENTS');
    },

    watchUser(uid: string): firebase.firestore.DocumentReference {
        return firebase.firestore().collection('Users').doc(uid);
    },

    getUserByEmail(email: string): Promise<firebase.firestore.QuerySnapshot> {
        return firebase.firestore().collection('Users').where("email", "==", email).get();
    },

    getUserByID(uid: string): Promise<firebase.firestore.DocumentSnapshot> {
        return firebase.firestore().collection('Users').doc(uid).get();
    },

    getUsers(): firebase.firestore.CollectionReference {
        return firebase.firestore().collection('Users');
    },

    getAppliedUsers(): firebase.firestore.Query {
        return firebase.firestore().collection('Users').where("applied", "==", true);
    },

    getUnverifiedUsers(): firebase.firestore.Query {
        return firebase.firestore().collection('Users').where('verified', '==', false);
    },

    updateUser(user: User): Promise<void> {
        return firebase.firestore().collection('Users').doc(user.uid).set(Object.assign({}, user));
    },

    updateEmail(email: string): Promise<void> | undefined {
        return firebase.auth().currentUser?.updateEmail(email);
    },

    checkStorageStatus(link: string): firebase.storage.Reference {
        return firebase.storage().refFromURL(link);
    },


    getCurrentUser():User | null
    {

         const currentUser=firebase.auth().currentUser;
         if(currentUser==null)
         return null;
         return {
            uid:currentUser.uid,
            email:currentUser.email,   
         } as User;
    },


            // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submitFeedbackRequest(data: UserFeedback): Promise<any> {
        data.isEmailSent = false;
        return firebase.firestore().collection('UserFeedbacks').add(data);

    },

      uploadAttachment(uid:string,data:File,uploadDate:string):firebase.storage.UploadTask
{
       return firebase.storage().ref(uid+'/support_attachments/'+data.name+' - '+uploadDate).put(data);
},

 getAttachementUrl(ref: firebase.storage.Reference):Promise<string> {
    return  ref.getDownloadURL();
},


};






export default firebaseService;
