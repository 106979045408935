import * as React from 'react';
import './Footer.css'
import {
    IonFooter,
    IonTitle,
    IonToolbar
} from "@ionic/react";

const Footer: React.FC = () => {
    return(
        <IonFooter translucent={true}>
            <IonToolbar className="foot" color="light">
                <IonTitle size="small" color="medium" className="ion-text-end footTitle">@TITAN 2021 All rights reserved.</IonTitle>
            </IonToolbar>
        </IonFooter>
    );
};

export default Footer;
