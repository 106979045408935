import * as React from 'react';
import './Settings.css';

import {User} from '../../interfaces/User';
import {
    IonButton,
    IonCard,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonText, IonToast, IonToggle
} from "@ionic/react";
import {SettingsProps} from '../../interfaces/SettingsProps';
import firebaseService from '../../services/firebaseService';
import {useState} from 'react';
import {getFireUser} from '../../firebaseConfig';

interface SettingsInfoProps extends SettingsProps {
    editable: boolean;
}

const AccountInfo: React.FC<SettingsInfoProps> = (props:SettingsInfoProps) => {
    const user: User = props.userObject;
    const fireUser = getFireUser();
    const [updateResponse, setUpdateResponse] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [privilege, setPrivilege] = useState('General User');

    function getPrivileges(){
        let adminText = '';
        if (!user.admin)
            adminText = 'General User';
        else
            adminText = 'Administrator';
        setPrivilege(adminText);
    }

    function changeAdmin() {
        user.admin = !user.admin;
        getPrivileges();
    }

    function changeVerification() {
        user.verified = !user.verified;
    }

    function save() {
        user.applied = false;
        firebaseService.updateUser(user).then(() => {
            setUpdateResponse(true);
        }, () => {
            setUpdateError(true);
        });
    }

    function currentUser() {
        if (fireUser) {
            return fireUser.uid === user.uid;
        } else {
            return false;
        }
    }

    React.useEffect(getPrivileges, [user.admin, user.verified]);

    return (
        <IonCard className="info-item space-between">
            <IonItemDivider className="div-title" color="light" sticky={true}>
                <IonLabel>Account Information</IonLabel>
            </IonItemDivider>

            <IonItem lines="none">
                <IonLabel position="stacked">Display Name:</IonLabel>
                <IonText>{user.displayName}</IonText>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">User Email:</IonLabel>
                <IonText>{user.email}</IonText>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">Affiliation/Organization:</IonLabel>
                <IonText>{user.agency}</IonText>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">User Privileges:</IonLabel>
                <IonText>{privilege}</IonText>
                {props.editable && <IonToggle color="pink" slot="end" onIonChange={changeAdmin} disabled={currentUser()} checked={user.admin} />}
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">Verification Status:</IonLabel>
                <IonText>{user.verified ? 'Verified' : 'Not Verified'}</IonText>
                {props.editable && <IonToggle color="pink" slot="end" onIonChange={changeVerification} disabled={currentUser()} checked={user.verified} />}
            </IonItem>
            {props.editable && <IonButton color="pink" onClick={save} disabled={currentUser()}>Save</IonButton>}<br />
            {currentUser() && <IonLabel>You cannot change your own privileges.</IonLabel>}
            <IonToast
                isOpen={updateResponse}
                onDidDismiss={() => setUpdateResponse(false)}
                color="light"
                message="User privilege has been updated."
                duration={10000}
                buttons={[{
                    text: 'Ok',
                    side: 'end',
                    role: 'cancel'
                }]}
            />
            <IonToast
                isOpen={updateError}
                onDidDismiss={() => setUpdateError(false)}
                color="danger"
                message="Unable to update user privilege."
                cssClass="toast-error"
                duration={10000}
            />
        </IonCard>
    );
};

export default AccountInfo;
