import {
    IonContent,
    IonIcon,
    IonItem,
    IonList,
    IonListHeader,
    IonMenu, IonMenuToggle
} from '@ionic/react';

import {
    fingerPrintOutline,
    fingerPrintSharp,
    personAddOutline,
    personAddSharp
} from 'ionicons/icons';
import './Menu.css';
import * as React from 'react';


const LoginMenu: React.FC = () => {

    return (
        <IonMenu contentId="main" type="overlay" menuId="login" side="end">
            <IonContent>
                <IonList id="inbox-list">
                    <IonListHeader>Welcome to RIDSI</IonListHeader>
                    <br />

                    <IonMenuToggle menu="login">
                        <IonItem button={true} lines="none" routerLink="/Login">
                            <IonIcon ios={fingerPrintOutline} md={fingerPrintSharp} />
                            &nbsp;
                            Login
                        </IonItem>
                    </IonMenuToggle>


                    <IonMenuToggle menu="login" slot="end">
                        <IonItem button={true} lines="none" routerLink="/Registration">
                            <IonIcon ios={personAddOutline} md={personAddSharp} />
                            &nbsp;
                            Register
                        </IonItem>
                    </IonMenuToggle>

                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default LoginMenu;
