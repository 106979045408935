import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonToast,
    useIonAlert
} from '@ionic/react';
import './FileDownload.css';
import {File} from '../../interfaces/File';
import {RouteProps} from 'react-router';
import {trashBinOutline, trashBinSharp} from 'ionicons/icons';
import firebaseService from '../../services/firebaseService';
import {useState} from 'react';

interface FileProps extends RouteProps {
    downloadableFiles: File[]
}

const FileDownload: React.FC<FileProps> = (props: FileProps) => {
    const [confirmDelete] = useIonAlert();
    const location = useLocation().pathname;
    const [errorResponse, setErrorResponse] = useState(false);
    props.downloadableFiles.sort((a, b) => dateStringToTimestamp(b.queryDate) - dateStringToTimestamp(a.queryDate));
    let color: string;
    switch(location) {
        case '/Data/Detector':
            color = 'red';
            break;
        case '/Data/Probe':
            color = 'orange';
            break;
        case '/Data/Incidents':
            color = 'green';
            break;
        case '/Data/Waze':
            color = 'blue';
            break;
        default:
            color = 'primary';
            break;
    }

    const deleteFile = (file: File) => {
        confirmDelete({
            header: 'Are you sure?',
            message: 'This will permanently delete this file.',
            buttons: [
                'Cancel',
                { text: 'Delete', handler: () => firebaseService.deleteFile(file.fileName).catch(err => console.log(err))}
            ]
        })
    };

    // const handleFile = (file: File) => {
    //     firebaseService.checkStorageStatus(file.location).getDownloadURL().then(res => {
    //         window.open(res, "_blank");
    //     }).catch((err) => {
    //         console.log(err);
    //         if (err.code !== "storage/unauthorized") {
    //             setErrorResponse(true);
    //             firebaseService.deleteFile(file.fileName);
    //         } else {
    //             window.open(file.location, "_blank");
    //         }
    //     });
    // };

    // This function is required because the backend stores the date as a weird format string - it is specific to that format: Jun 29, 2021 12:36AM
    // TODO: Have someone change the backend to store the queryDate as a firebase timestamp so this isn't necessary
    function dateStringToTimestamp(dateString: string): number {
        const dateYear = parseInt(dateString.substring(8, 12), 10);
        const dateMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].indexOf(dateString.substring(0, 3));
        const dateDay = parseInt(dateString.substring(4, 6), 10);
        let dateHour = parseInt(dateString.substring(13, 15), 10);
        const dateMinute = parseInt(dateString.substring(16, 18), 10);
        const dateMeridiam = dateString.substring(18, 20);
        if (dateMeridiam === 'PM') {
            dateHour += 12;
            if (dateHour > 23) {
                dateHour = 12;
            }
        }
        if (dateMeridiam === 'AM' && dateHour === 12) {
            dateHour = 0;
        }
        return new Date(dateYear, dateMonth, dateDay, dateHour, dateMinute).valueOf();
    }

    return (
        <div>
            {props.downloadableFiles.length > 0 &&<IonCard className="download-card">
                <IonCardContent className="card-content">
                    <IonGrid className="grid">
                        <IonRow className="download-header">
                            <IonCol><div className="download-status">Status</div></IonCol>
                            <IonCol className="download-info">Downloads and Reports</IonCol>
                            {location === "/History" && <IonCol className="download-info">Database</IonCol>}
                            <IonCol className="download-info">Query Date</IonCol>
                            <IonCol className="download-status"/>
                        </IonRow>
                        {props.downloadableFiles.map((file, i) => {
                            return (
                                <IonRow className="download-row" key={i}>
                                    {file.ready && <IonCol><IonButton color={color} className="query-button" download="Query File" href={file.location}>Download Ready</IonButton></IonCol>}
                                    {!file.ready && <IonCol><IonButton className="query-button" disabled={true} color="danger">Download Preparing</IonButton></IonCol>}
                                    <IonCol className="query-info">{file.queryResponse}</IonCol>
                                    {location === "/History" && <IonCol className="query-info">{file.page}</IonCol>}
                                    <IonCol className="query-info">{file.queryDate}</IonCol>
                                    <IonCol><IonIcon className="trash" color="danger" ios={trashBinOutline} md={trashBinSharp} onClick={() => deleteFile(file)} /></IonCol>
                                </IonRow>
                            );
                        })}
                    </IonGrid>

                </IonCardContent>
            </IonCard>}
            <IonToast
                isOpen={errorResponse}
                onDidDismiss={() => setErrorResponse(false)}
                color="danger"
                message="This file no longer exists. It has been removed from your downloads."
                cssClass="toast-error-download"
                duration={10000}
            />
        </div>
    );
};

export default FileDownload;
