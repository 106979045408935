import {
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon, IonImg,
    IonItem,
    IonMenuButton,
    IonSearchbar,
    IonTitle,
    IonToolbar, useIonViewWillEnter
} from '@ionic/react';
import {ellipse, notifications, person} from 'ionicons/icons';
import * as React from 'react';
import {RouteProps, useHistory} from 'react-router';
import "./Header.css"
import {useEffect, useState} from 'react';
import {getPages} from './Pages';
import RIDSIR from '../../assets/icon/favicon.png';
import firebaseService from '../../services/firebaseService';

interface PrivateRouteProps extends RouteProps {
    title: string
}

const Header: React.FC<PrivateRouteProps> = (props: {title: string}) => {
    const [pages, setPages] = useState<{ name: string, route: string}[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [mobileWidth, setMobileWidth] = useState('100%');
    const [mobileMargin, setMobileMargin] = useState('0 0 0 0');
    const [autocompleteFlex, setAutocompleteFlex] = useState('center');
    const [notificationCount, setNotificationCount] = useState(0);

    window.addEventListener('resize', resize);
    function resize(event: UIEvent) {
        const window = event.currentTarget as Window;
        if (window.innerWidth <= 1030) {
            setMobileWidth('100%');
            setMobileMargin('0 7.5em 0 5.5em');
            setAutocompleteFlex('center');
        } else {
            setMobileWidth('19em');
            setMobileMargin('0 7.5em 0 0');
            setAutocompleteFlex('flex-end');
        }
    }

    function search(e: CustomEvent) {
        const searchText = e.detail?.value;
        setSearchValue(searchText);
        let filteredPages = getPages();
        if (searchText !== '') {
            filteredPages = filteredPages.filter((page) => {
                return page.name.toLowerCase().includes(searchText.toLowerCase());
            });
            setPages(filteredPages);
        } else {
            setPages([]);
        }
    }

    const history = useHistory();
    function home() {
        history.push('/Dashboard');
    }

    useIonViewWillEnter(() => {
        window.dispatchEvent(new Event('resize'));
    });

    useEffect(() => {
        firebaseService.getAllNotificationsCollection().get().then(snap => {
            setNotificationCount(snap.size);
        });
        return () => {
            setSearchValue('');
            setPages([]);
        }
    }, []);


    return (
        <IonHeader className="ion-no-border">
            <IonToolbar color="light">
                <IonButtons slot="start">
                    <IonMenuButton className="menuButton" menu="main"/>
                </IonButtons>
                <IonTitle className="header-title">
                    <div className="header-title-container" onClick={home}>
                        <IonImg className="ridsi-icon-header" src={RIDSIR} /> &nbsp; {props.title}
                    </div>
                </IonTitle>
                <IonItem className="tool-bar" color="light" lines="none" slot="end">
                    <IonSearchbar value={searchValue} onIonChange={search} />
                    <IonButtons onClick={() => {
                        history.push('/Notifications')
                    }}>
                        {notificationCount > 0 && <IonIcon color="danger" className="notification-count" md={ellipse} />}
                        {notificationCount > 0 && <span className="notification-number">{notificationCount}</span>}
                        <IonButton routerDirection="none"><IonIcon color="dark" slot="icon-only" md={notifications}/></IonButton>
                    </IonButtons>
                    <IonButtons>
                        <IonButton routerLink="/Profile" routerDirection="none"><IonIcon color="dark" slot="icon-only" md={person}/></IonButton>
                    </IonButtons>
                </IonItem>
            </IonToolbar>
            <div className="autocomplete-container" style={{justifyContent: autocompleteFlex}}>
                {pages.length > 0 && <div className="autocomplete" style={{width: mobileWidth, margin: mobileMargin}}>
                    { pages.map((page, index) => {
                        return (
                            <IonItem className="autocomplete-item" key={index} routerLink={page.route} >{page.name}</IonItem>
                        );
                    })}
                </div>}
            </div>
        </IonHeader>
    );
};

export default Header;
