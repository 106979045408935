import * as React from 'react';
import './Settings.css';

import {User} from '../../interfaces/User';
import {
    IonButton,
    IonCard,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonText,
    IonToast, useIonViewWillLeave
} from "@ionic/react";
import {useState} from "react";
import {
    bodyOutline,
    bodySharp,
    businessOutline,
    businessSharp, checkmarkCircleOutline, checkmarkCircleSharp,
    mailOutline,
    mailSharp
} from "ionicons/icons"
import {SettingsProps} from '../../interfaces/SettingsProps';
import firebaseService from '../../services/firebaseService';
import {getFireUser, resetPassword} from '../../firebaseConfig';
import { useHistory } from 'react-router';

const AccountSettings: React.FC<SettingsProps> = (props:SettingsProps) => {
    const user: User = props.userObject;
    const passwordLogin: boolean = (getFireUser()?.providerData[0]?.providerId === 'password') ? true : false;
    const redirect = window.location.href.replace('/Registration', '/Dashboard');
    const history = useHistory();

    const [name, setName] = useState(user.displayName);
    const [agency, setAgency] = useState(user.agency);
    const [email, setEmail] = useState(user.email ? user.email : '');
    const [passwordReset, setPasswordReset] = useState(false);
    const [updateResponse, setUpdateResponse] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [updateMessage, setUpdateMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function updateAccountInfo() {
        if(name === '') {
            setErrorMessage('Please enter a new name');
            return setUpdateError(true);
        }
        if(agency === '') {
            setErrorMessage('Please enter a new agency');
            return setUpdateError(true);
        }
        if(!email.match("^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))" +
            "@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$")) {
            setErrorMessage('Please enter a valid new email address');
            return setUpdateError(true);
        }
        if(name === user.displayName && agency === user.agency && email === user.email){
            setErrorMessage('At least one info field must be changed to perform an update.');
            return setUpdateError(true);
        }
        
        if (user.email != email) {
            const updateEmail = firebaseService.updateEmail(email);
            if (updateEmail != null) {
                updateEmail.then(() => {
                    user.displayName = name;
                    user.agency = agency;
                    user.email = email;
                    firebaseService.updateUser(user).then(() => {
                        const fireuser = getFireUser();
                        if (fireuser != null) {
                            fireuser.sendEmailVerification({
                                url: redirect,
                            }).then(() => {
                                history.push('/EmailVerification');
                            }).catch((err) => {
                                setErrorMessage(err.message);
                            });
                        } else {
                            setErrorMessage('Unable to update user info.');
                            setUpdateError(true);
                        }
                    }, () => {
                    setErrorMessage('Unable to update user info.');
                    setUpdateError(true);
                    });
                }).catch((error) => {
                    setErrorMessage(error);
                    setUpdateError(true);
                });
            }
        } else {
            user.displayName = name;
            user.agency = agency;
            firebaseService.updateUser(user).then(() => {
                setUpdateMessage('Account info update successful.');
                setUpdateResponse(true);
            }, () => {
                setErrorMessage('Unable to update user info.');
                setUpdateError(true);
            });
        }
    }

    useIonViewWillLeave(() => {
        setName(user.displayName);
        setAgency(user.agency);
        setEmail(user.email ? user.email : '');
    });

    function reset() {
        if (user.email) {
            resetPassword(user.email).then(() => {
                setPasswordReset(true);
            }).catch(err => {
                setUpdateError(true);
                setPasswordReset(false);
                setErrorMessage(err.message);
            });
        }
    }

    function submitApplication() {
        if (!user.applied && !user.admin) {
            user.applied = true;
            firebaseService.updateUser(user).then(() => {
                setUpdateMessage('Application submitted.');
                setUpdateResponse(true);
            }, () => {
                setErrorMessage('Unable to submit application.');
                setUpdateError(true);
                user.applied = false;
            });
        }
    }

    return (
        <IonCard className="info-item space-between">
            <IonItemDivider className="div-title" color="light" sticky={true}>
                <IonLabel>Account Settings</IonLabel>
            </IonItemDivider>
            <IonItem lines="none" className="div-subtitle">
                <IonLabel>Change Information</IonLabel>
            </IonItem>
            <IonItem className="formItem">
                <IonLabel position="stacked">First Name</IonLabel>
                {/* Object needs to be of type any here, since the alternative is a custom made Ionic type*/}
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <IonInput value={name} placeholder={user.displayName ? user.displayName : ''} onIonChange={(e: any) => setName(e.target.value)}  />
                <IonIcon ios={bodyOutline} md={bodySharp} slot="end" />
            </IonItem>
            <IonItem className="formItem">
                <IonLabel position="stacked">Agency</IonLabel>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <IonInput value={agency} placeholder={user.agency ? user.agency : ''}  onIonChange={(e: any) => setAgency(e.target.value)}  />
                <IonIcon ios={businessOutline} md={businessSharp} slot="end" />
            </IonItem>
            {passwordLogin && <IonItem className="formItem">
                <IonLabel position="stacked">Email</IonLabel>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <IonInput value={email} placeholder={user.email ? user.email : ''} onIonChange={(e: any) => setEmail(e.target.value)}  />
                <IonIcon ios={mailOutline} md={mailSharp} slot="end" />
            </IonItem> }
            <IonButton color="pink" onClick={updateAccountInfo}>Submit Info</IonButton>
            <IonItem lines="none" className="div-subtitle space-between space-between-top">
                <div className="password-break">
                    <IonLabel>Change Password:</IonLabel>
                    <IonButton color="pink" className="reset-submit" onClick={reset}>{passwordReset ? "Resend" : "Reset Password"}</IonButton><br />
                    {passwordReset && <p className="password-reset">
                        An email has been sent to {user.email} with instructions on how to reset your password.
                        <IonIcon color="success" md={checkmarkCircleSharp} ios={checkmarkCircleOutline} />
                    </p>}
                </div>
            </IonItem>
            <IonItem lines="none" className="div-subtitle">
                <IonLabel>Apply to be an Administrator:</IonLabel>
            </IonItem>
            <div className="admin">
                <IonText className="buttonText">
                    Click this button to submit a request for administrator permissions. A current administrator will review your request
                    and inform you on your status shortly.
                </IonText>
                <IonButton color="pink" className="admin-submit" onClick={submitApplication} disabled={(user.admin || user.applied)}>Submit Application</IonButton>
            </div>
            <IonToast
                isOpen={updateResponse}
                onDidDismiss={() => setUpdateResponse(false)}
                color="light"
                message={updateMessage}
                duration={10000}
                buttons={[{
                    text: 'Ok',
                    side: 'end',
                    role: 'cancel'
                }]}
            />
            <IonToast
                isOpen={updateError}
                onDidDismiss={() => setUpdateError(false)}
                color="danger"
                message={errorMessage}
                cssClass="toast-error"
                duration={10000}
            />
        </IonCard>
    );
};

export default AccountSettings;
