import {IonCard, IonCardContent, IonCardHeader, IonContent, IonPage, IonTitle} from '@ionic/react';
import * as React from 'react';
import {useState} from 'react';
import Header from '../../components/Header/Header';
import Footer from "../../components/Footer/Footer";
import {File} from '../../interfaces/File';
import FileDownload from '../../components/FileDownload/FileDownload';
import {getFireUser} from '../../firebaseConfig';
import firebaseService from '../../services/firebaseService';
import './History.css';

const History: React.FC = () => {
    const findUser = getFireUser();
    let uid = '';
    if (findUser != null) {
        uid = findUser.uid;
    }
    const [downloadableFiles, setDownloadableFiles] = useState<File[]>([]);

    React.useEffect(() => {
        firebaseService.getFiles().onSnapshot((docs) => {
            const downloadedFiles: File[] = [];
            docs.forEach(doc => {
                const data = doc.data();
                if (data != null) {
                    downloadedFiles.push(new File(data.uid, doc.id, data.location, data.type, data.ready, data.queryResponse, data.queryDate));
                }
            });
            setDownloadableFiles(downloadedFiles);
        });
    }, [uid]);


    return(
        <IonPage>
            <Header title="History" />
            <IonContent fullscreen color="light">
                <IonCard>
                    <IonCardHeader>
                        <IonTitle>Your Download History</IonTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {downloadableFiles.length > 0 && <FileDownload downloadableFiles={downloadableFiles}/>}
                        {downloadableFiles.length === 0 && <div className="empty-msg">You have not submitted any requests to download data</div>}
                    </IonCardContent>
                </IonCard>
                <Footer/>
            </IonContent>
        </IonPage>
    );
};

export default History;
