import React,{useState} from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonIcon,IonAlert } from '@ionic/react';


import { CountyData } from "../../interfaces/CountyData";
// import ProgressBar from "@ramonak/react-progress-bar";
import { useHistory } from 'react-router-dom';


import "./ClearanceCard.css"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CircleIcon from '@mui/icons-material/Circle';

import Chart from 'react-apexcharts'
import { colorFill } from 'ionicons/icons';

interface GraphDataCardprops {
    content: any;
    crashList: CountyData[];
    newdata:any;
  }

interface dayClearance{
  clearance: number;
  name: string;
}




          
 

  
  const ClearanceDataCard: React.FC<GraphDataCardprops> =(props:GraphDataCardprops)=> {


    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => {
      if (props.content.source != "N/A") setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
    };
    const history = useHistory();
    const handleOkay = () => {
      closeModal();  // Close the modal first
      history.push('/AppCenter/ClearanceTime');  // Navigate to the desired path
    };

    const clearanceValues = props.newdata.weekdayClearance.map((day:any )=> Math.floor(day.clearance));
    const dayNames = props.newdata.weekdayClearance.map((day:any) => day.name.substring(0, 3));   
    
    const   series= [{
        name: "Clearance Time",
        data: clearanceValues
    }]

    const   options={
      xaxis: {
        categories: dayNames,
      },
 
    //   stroke: {
    //     curve: "smooth",
    //   }
    };


    const semi_series= [76];
     const semi_options= {
              chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                  enabled: true
                }
              },
              plotOptions: {
                radialBar: {
                  startAngle: -90,
                  endAngle: 90,
                  track: {
                    background: "#e7e7e7",
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                      enabled: true,
                      top: 2,
                      left: 0,
                      color: '#999',
                      opacity: 1,
                      blur: 2
                    }
                  },
                  dataLabels: {
                    name: {
                      show: false
                    },
                    value: {
                      offsetY: -2,
                      fontSize: '22px'
                    }
                  }
                }
              },
              grid: {
                padding: {
                  top: -10
                }
              },
              fill: {
                type: 'gradient',
                gradient: {
                  shade: 'light',
                  shadeIntensity: 0.4,
                  inverseColors: false,
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [0, 50, 53, 91]
                },
              },
              labels: ['Average Results'],
            };

      return(
    <>
      <IonCard className='clearance-main-container'>
              <IonCardTitle className="clearance-data-card-title">
              <div onClick={openModal} className="clearance-data-card-icon">
                  <IonIcon  color="light" ios={props.content.ios} md={props.content.md}/>
                </div>
                <div className='clearance-header-title'>{props.content.title}</div>
                
              </IonCardTitle>
          
          <div className="clearance-gauge-container">
                  <Chart series={series} options={options} type="line" height={180} width={270} />
          </div>
          <IonCardTitle className='clearance-by-county-title' style={{ color: 'black' }}>
                  Clearance Time By County
          </IonCardTitle>
        
         
         <div className='counties-and-time'>
         {
           props.newdata.countyClearance.map((day:any,index:number)=>{
            return(
              <div key={index} className='county-and-arrow'>
                   <CircleIcon className='circle-dot' style={{fill:'DodgerBlue',fontSize: 12}}></CircleIcon>
                  <div key={day.name}>{day.name} : {Math.floor(day.clearance)}</div>
                  {day.clearance>props.newdata.clearance_time?
                  <ArrowUpwardIcon className='arrow' style={{ color:'red',fontSize: 20 }}></ArrowUpwardIcon>
                  :<ArrowDownwardIcon className='arrow' style={{ color:'green', fontSize:20 }}></ArrowDownwardIcon>
                  }
              </div>
            )
           })
         }
         </div>
            
          
          
          
          <div className='clearance-last-updated'>{props.content.updated}</div>
      </IonCard>

      <IonAlert
          isOpen={modalOpen}
          header={props.content.title}
          subHeader={"Source: " + props.content.source}
          message={props.content.description}
          buttons={[{text:"More Information", handler: handleOkay}]}
          onDidDismiss={closeModal}
          cssClass="bigger-alert"
        ></IonAlert>
      
  
    </>
     
      );
  };
  export default ClearanceDataCard;
  