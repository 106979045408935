import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton
} from '@ionic/react';
import * as React from 'react';
import './RidsiAbout.css';
import {useState} from "react";

import Header from '../../components/Header/Header';
import Footer from "../../components/Footer/Footer";

import RidsiSegment from "../../components/AboutSegments/RidsiSegment";
import AgenciesSegment from "../../components/AboutSegments/AgenciesSegment";
import AppTeamSegment from "../../components/AboutSegments/AppTeamSegment";
import DataSegment from "../../components/AboutSegments/DataSegment";

const RidsiAbout: React.FC = () => {

    const [selectedSegment, setSelectedSegment] = useState("ridsi");

    return (
        <IonPage>
            <Header title={"About"} />
            <IonContent fullscreen color="light">
                <IonCard className="card-header-container">
                    <IonCardHeader>
                        <IonCardTitle className="card-header-title-box about-title-coloring">About RIDSI</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {/* Object needs to be of type any here, since the alternative is a custom made Ionic type*/}
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        <IonSegment value={selectedSegment} onIonChange={(e:any) => setSelectedSegment(e.detail.value)}
                                    className="about-section-selectors"
                                    color="pink"
                                    scrollable
                                    swipeGesture={false}
                                    mode="md" >
                            <IonSegmentButton value="ridsi">
                                <IonLabel>RIDSI</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="data">
                                <IonLabel>Data</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="agencies">
                                <IonLabel>Agencies</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="dev">
                                <IonLabel>Development Team</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                        { (selectedSegment === "ridsi") && <RidsiSegment/> }
                        { (selectedSegment === "agencies") && <AgenciesSegment/> }
                        { (selectedSegment === "dev") && <AppTeamSegment/> }
                        { (selectedSegment === "data") && <DataSegment/> }
                    </IonCardContent>
                </IonCard>
                <Footer/>
            </IonContent>
        </IonPage>
    );
};

export default RidsiAbout;
