import React,{ useRef, useEffect,useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonIcon, IonAlert, IonRouterLink,IonLabel } from '@ionic/react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import Stack from '@mui/material/Stack';

import { CountyData } from "../../interfaces/CountyData";
// import ProgressBar from "@ramonak/react-progress-bar";
import "./Workzonescard.css"
import { green } from '@material-ui/core/colors';
import { colorFill } from 'ionicons/icons';
import { color } from '@mui/system';

import Chart from 'react-apexcharts'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

import CircleIcon from '@mui/icons-material/Circle';


import {  Bar } from 'react-chartjs-2';
interface GraphDataCardprops {
  content: any;
  crashList: any;
  newdata:any;
}


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    // backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    backgroundColor:'#456e97',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#ec4561'
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const WorkZoneDataCard: React.FC<GraphDataCardprops> =(props:GraphDataCardprops)=> {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    if (props.content.source != "N/A") setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const history = useHistory();

  const handleOkay = () => {
    closeModal();  // Close the modal first
    history.push('/AppCenter/WorkZones');  // Navigate to the desired path
  };
  
  const clearanceValues = props.newdata.dowWzCongestion.map((day:any )=> Math.floor(day.delay));
  const dayNames = props.newdata.dowWzCongestion.map((day:any) => day.name.substring(0, 3));   
//   console.log(clearanceValues)
//   console.log(dayNames)
   const roadNames=props.newdata.roadWzCongestion.map((day:any) => day.name);
   const roadValues=props.newdata.roadWzCongestion.map((day:any )=> Math.floor(day.delay));
  const   series= [{
    name: "Congestion",
    data: clearanceValues
}]

const   options={
  xaxis: {
    categories: dayNames,
  },

//   stroke: {
//     curve: "smooth",
//   }
};

const new_data={
    labels:roadNames,
    datasets:[
        {
            label:'Congestion By Road',
            data:roadValues,
            backgroundColor:'tomato',

        }
    ]
  }

    return(
  <>
    <IonCard  className='workzone-main-container'>
        
            <IonCardTitle className="work-data-card-title">
              
              <div onClick={openModal} className="work-data-card-icon">
                <IonIcon  color="light" ios={props.content.ios} md={props.content.md}/>
              </div>
              <div className='work-header-title'>WorkZones</div>
            </IonCardTitle>
        <div className='scroll-bar'>
            <div className='workone'>

                    <div className="workzone-line-chart">
                            {/* <LineChart
                                sx={{
                                    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel":{
                                    strokeWidth:"0.4",
                                    fill:"white"
                                    },
                                    "& .MuiChartsAxis-left .MuiChartsAxis-tick":{
                                    stroke:"white"
                                    },
                                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tick":{
                                    stroke:"white"
                                    },
                                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel":{
                                    strokeWidth:"0.5",
                                    fill:"white"
                                },
                                "& .MuiChartsAxis-bottom .MuiChartsAxis-line":{
                                    stroke:"white",
                                    strokeWidth:1.2
                                },
                                "& .MuiChartsAxis-left .MuiChartsAxis-line":{
                                    stroke:"white",
                                    strokeWidth:1.2
                                },
                                "& .MuiChartsAxis-left .MuiChartsAxis-label":{
                                 
                                  fill:'white'
                              },
                                }}
                                xAxis={[{ scaleType:"point" , data:dayNames}]}
                                yAxis={[{label: 'Congestion',fill:'white'}]}
                                series={[
                                {
                                    data: clearanceValues,
                                },
                                ]}
                                dataset={props.newdata.dowWzCongestion}
                                width={300}
                                height={210}
                            /> */}
                            <Chart series={series} options={options} type="line" height={180} width={270} />
                
                    </div>
                    <div className='work-zone-info'>
                        <div className='work-zone-info-child'>
                            <CircleIcon style={{fill:'red',fontSize:'13'}}></CircleIcon> Number of WorkZones: {props.newdata.Workzones}
                        </div>
                        <div className='work-zone-info-child'>
                            <CircleIcon style={{fill:'red',fontSize:'13'}}></CircleIcon> Avg. Queue Lengths : {props.newdata['Queue Lengths']}
                        </div>
                        <div className='work-zone-info-child'>
                            <CircleIcon style={{fill:'red',fontSize:'13'}}></CircleIcon> Congested Hours : {props.newdata['Congested Hours']}
                        </div>
                    </div>
          
            </div>
            {/* <IonCardTitle className='crashes-work-title' style={{ color: 'black' }}>
                    Congestion By Road
            </IonCardTitle>
       */}
        
            <div className='bar-chart'>
                    {/* <BarChart
                        dataset={props.newdata.roadWzCongestion}
                        yAxis={[{ scaleType: 'band', dataKey: 'name' }]}
                        // xAxis={[{label:'rainfall'}]}
                        width={200}
                        height={290}
                        series={[{ dataKey: 'delay',label:'Congestion'}]}
                        layout="horizontal"
                        sx={{
            
                        "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel":{
                            strokeWidth:1.2,
                            fill:"white",
                        },
                        "& .MuiChartsAxis-left .MuiChartsAxis-tick":{
                            stroke:"white"
                        },
                        "& .MuiChartsAxis-bottom .MuiChartsAxis-tick":{
                            stroke:"white"
                        },
                        "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel":{
                            strokeWidth:"0.5",
                            fill:"white"
                        },
                        "& .MuiChartsAxis-bottom .MuiChartsAxis-line":{
                        stroke:"white",
                        strokeWidth:1.2
                        },
                        "& .MuiChartsAxis-left .MuiChartsAxis-line":{
                        stroke:"white",
                        strokeWidth:1.2
                        },
                        "& .MuiChartsAxis-top .MuiChartsAxis-label":{
                        fill:"white",
                        }
                        }}
                    /> */}
               <Bar  data={new_data} height={220} ></Bar>

                    
             </div>
        </div>
       
        <div className='work-last-updated'>{props.content.updated}</div>
        


    </IonCard>
    <IonAlert
          isOpen={modalOpen}
          header="Work Zones"
          subHeader={"Information related to work Zones"}
          message={"For More Information on work zones click below"}
          buttons={[{text:"More Information", handler: handleOkay}]}
          onDidDismiss={closeModal}
          cssClass="bigger-alert"
        ></IonAlert>
    
    

  </>
   
    );
};
export default WorkZoneDataCard;

