import * as React from 'react';
import './AboutSegments.css';

import {
    IonCard, IonCardContent,
    IonCol,
    IonGrid,
    IonImg,
    IonItemDivider,
    IonLabel,
    IonRow,
    IonText
} from "@ionic/react";

import RIDSI from '../../assets/RIDSI.png';

const RidsiSegment: React.FC = () => {

    return (
        <IonCard className="about-segment-card">
            <IonItemDivider className="about-header-card-title" color="light">
                <IonLabel>About the RIDSI Project</IonLabel>
            </IonItemDivider>
            <IonCardContent className="ion-text-left">
                <IonGrid>
                    <IonRow className="ion-wrap-reverse">
                        <IonCol className="segment-text-column" size-xs="12" size-lg="7">
                            <IonText>
                                This is RIDSI: The Regional Intelligent Transportation System Data Sharing Initiative.
                                <br/><br/>
                                RIDSI is the central source and delivery point for historical and active event/transportation
                                information. RIDSI leverages big data analytics to host a data warehouse that collects
                                raw information from critical transportation partners, normalizes the information into a consistent
                                format, and supports archive and delivery of the information back to the contributing partners and
                                other approved agencies. From this central source, an agency, firm, or regional partner can review,
                                download, or develop their own analysis of traffic from around the state.
                                <br/><br/>
                                The project’s focus is to allow regional transportation data information to be more widely available and
                                used as a resource to support planning, decision making, operations management, and incident response.
                            </IonText>
                        </IonCol>
                        <IonCol size-xs="12" size-lg="5">
                            <IonImg src={RIDSI} alt="Traffic Image"/>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    );
};

export default RidsiSegment;
